<template>
<template v-if="selectedSprite">
  <FieldsGroup
    label="Position"
  >
    <FieldsRow class="">
      <NumberField
        label="X"
        v-model="selectedSprite.x"
        name="spriteX"
        maxlength="4"
      />
      <!--
        TODO later:
          [ ] add validation for numbers
          [ ] Think about using Veutify form instead of input
          [ ] Think about how to handle undo / redo feature compatibility
          [ ] Check if we can assign v-model to an object prop in like in the reverted commits.
            [ ] Also think about if this will produce cleaner / more extensible code
      -->
      <NumberField
        label="Y"
        v-model="selectedSprite.y"
        name="spriteY"
        maxlength="4"
      />
    </FieldsRow>
  </FieldsGroup>
  <FieldsGroup
    label="Size"
  >
    <FieldsRow>
      <NumberField
        label="W"
        v-model="selectedSprite.width"
        name="spriteWidth"
        maxlength="4"
        @change="refreshImageSpriteFitment(selectedSprite)"
      />
      <NumberField
        label="H"
        v-model="selectedSprite.height"
        name="spriteHeight"
        maxlength="4"
        @change="refreshImageSpriteFitment(selectedSprite)"
      />
    </FieldsRow>
  </FieldsGroup>
  <FieldsRow
    label="Fitment"
  >
    <!-- <OldThemeIcon name="fitment-best-fit" class="icon" />
    <OldThemeIcon name="fitment-fitment-fill" class="icon" />
    <OldThemeIcon name="fitment-original" class="icon" /> -->
    <ImageFitmentButtons />
  </FieldsRow>
  <div class="flex flex-col justify-start items-start mt-4">
    <div class="flex flex-col justify-center items-center">
      <ChangeImageDialog>
        <template #expander>
          <ButtonCustomized label="Change Image" secondary class="change-image-button" />
        </template>
      </ChangeImageDialog>
      <div
        :class='["remove-image", { "disabled": !isCurrentSpriteHasImage }]'
        v-on='{click: (isCurrentSpriteHasImage ? removeImage.bind(null, selectedSprite) : null)}'
      >
        Remove Image
      </div>
    </div>
  </div>
</template>

</template>

<script lang="ts" setup>
import { computed, reactive, ref, type ComputedRef } from 'vue';
import useSlide from '@/views/MediaEditorView/SlideEditor/composables/slide';
import ChangeImageDialog from './ChangeImageDialog/ChangeImageDialog.vue';
import ButtonCustomized from '@/components/form-fields/ButtonCustomized.vue';
import FieldsGroup from '@/components/form-fields/FieldsGroup.vue';
import FieldsRow from '@/components/form-fields/FieldsRow.vue';
import NumberField from '@/components/form-fields/NumberField.vue';
// import OldThemeIcon from '@/components/icons/OldThemeIcon.vue';
import ImageFitmentButtons from './ImageFitmentButtons/ImageFitmentButtons.vue';
import { refreshImageSpriteFitment } from '@/views/MediaEditorView/SlideEditor/Stage/stageEvents/handlers/imageFitmentHelpers';
import type { ImageSprite } from '@/helpers/types/media/slide/Sprite';

const { selectedSprite } = useSlide() as { selectedSprite: ComputedRef<ImageSprite> };

const isCurrentSpriteHasImage = computed(() => {
  return selectedSprite.value?.src !== null;
});

function removeImage(sprite: any) {
  sprite.image = null;
  sprite.src = null;
  sprite.name = ""
}
</script>

<style lang="scss" scoped> 

.change-image-button {
  padding: 0.5rem;
  height: 1.6rem;
}

.remove-image {
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &.disabled {
    color: #999;
    cursor: not-allowed;
    &:hover {
      text-decoration: none;
    }
  }
}
</style>