<!--
  TODO: think about automatic rendering for table cells "td items" if no need to "TableListItem" is passed — N2R6xW7u
    Implementation idea:
      this can by adding items fields config file contains the keys for each column (may be with the help of _get)
    Priority thoughts:
      this seems not important at all for now
-->

<template>
  <td class="name-cell">
    <RouterLink
      v-if="!selectMode && !singleSelect && areaAccess?.hasWriteAccess"
      :to="`/${apiPathsConfig.edit}/${item.id}`"
      tag="div"
      :class="['font-bold', 'title']"
      component="a"
      @click.stop
    >
      {{item.name}}
    </RouterLink>
    <div v-else>
      {{item.name}}
    </div>
    <div>
      {{ (item.assetType ? item.assetType[0].toUpperCase() + item.assetType.substring(1) : "" ) }}
    </div>
  </td>
  <td>
    {{ _get(item, 'location.groupName', 'None') }}
  </td>
  <td>
    {{ item.regCode }}
  </td>
  <td  class="min-w-[11.5rem]">
    <component :is="renderers.TimeFormat" :epochTime="item.lastConnectTime" />
  </td>
  <td>
    {{ _get(item, 'location.city', 'None') }}
  </td>
  <td>
    {{ _get(item, 'location.state', 'None') }}
  </td>
  <td>
   
    <template v-for="metadata of item.metaDatas" >
      <p v-if="!metadata.isDeleted"  >{{ metadata.name }} : {{ metadata.value }} </p>
    </template>
   
    <!--
      TODO: add tags parsing — N2REfRE1
        Need to figure out how
    -->
  </td>
  <td class="used-by-cell">
    <component :is="renderers.UsedBy" :items="item.campaigns" label="Campaigns" :count="item.campaignCount" />
      <!--
        TODO: think about setting label to "columnsConfig.find((item)=>item.fieldName=='campaigns').extra.usedByLabel" for label
          - There are issues to this approach:
            - performance.
            - value passing architecture we'll use.
      -->
  </td>
</template>

<script lang="ts" setup>
import _get from 'lodash/get';
const props = defineProps<{
  item: any;
  itemIndex: any;
  ItemActionsButtons?: any;
  ItemTableSelectBox?: any;
  columnsConfig: any;
  renderers: any;
  filteredListItems: any;
  apiPathsConfig?: any;
  listerConfig?: any;
  selectMode?: boolean;
  singleSelect?: boolean;
}>();

const {
  areaAccess
} = props.listerConfig;
</script>

<style lang="scss" scoped>
.title {
  color: #25294A;
  text-decoration: none;
  cursor: pointer;
  max-width: 200px;
  display: inline-block;
}
.title:hover {
  text-decoration: underline;
}

.name-cell {
  word-break: break-word;
  min-width: 10rem;
}

.used-by-cell {
  min-width: 3.6rem;
}
</style>