<template>
  <div class="container">
    <SelectField
      :options="pageLimits"
      v-model="limit"
      suffix-label="per page"
    />
  </div>
</template>

<script lang="ts" setup>
import { reactive, ref, inject } from 'vue';
import SelectField from '@/components/form-fields/SelectField/SelectField.vue';
const itemsListHelpers = inject('itemsListHelpers');


const {
  limit,
  isEnableAllLimitOption
} = itemsListHelpers;
// const pageLimits = reactive([
//   25,
//   50,
//   100,
// ]);
const pageLimits = reactive([
  { label :25 ,value : 25 },
   { label :50 ,value : 50},
    {label :100 ,value : 100},
]);

if(isEnableAllLimitOption) {
  pageLimits.push({ label:'All', value:0 })
}

</script>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex: 0;
  min-width: 8.5em;
}
</style>