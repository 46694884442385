<template>
  <div class="container flex items-center">
    Displaying: {{ firstItemInRangeIndex }} - {{ lastItemInRangeIndex }} of&nbsp;
    
    <content-with-loading-inline-dots :isLoading="!Boolean(totalItems)">
      {{ totalItems }}
    </content-with-loading-inline-dots>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject } from "vue";

const itemsListHelpers = inject('itemsListHelpers');
const firstItemInRangeIndex = computed(() => {
  if(limit.value == 0) return 1;
  return offset.value+1;
})

const lastItemInRangeIndex = computed(() => {
  if (limit.value == 0) return totalItems.value;
  const maxItemIndexInRange = Number(offset.value) + Number(limit.value);
  if(maxItemIndexInRange > totalItems.value) {
    return totalItems.value;
  } else {
    return maxItemIndexInRange;
  }
  
});
const {
  offset,
  limit,
  totalItems,
  currentPageNumber,
  totalPages,
  lastPageNumber,
  isLoading,
} = itemsListHelpers;
</script>

<style lang="scss" scoped>
.container {
  white-space: nowrap;
  flex: 0;
  width: 13em;
}
</style>