<!-- Initiatl version received from Ahmed -->
<template>


 <div class="tagsBox">

    <ul class="tags" @click=addTags>
      <li class="tag" v-for="(tag, i) in  tags" :key="i">
        {{ tag.name }}
        <span @click="deleteTag(i)" class="closeTag">x</span>
      </li>
    </ul>
    <input type="text" v-model="newTag"  @keyup.enter="addTags" @keyup.tab="addTags" @focusout="addTags"
      @keydown.tab.prevent="addTags"
      @keydown.delete="deleteLastTagViaKeyHandler"
          />
  </div> 

</template>

<script lang="ts" setup>
import { ref } from "vue";

export interface Props {
  width?: string,
  tags: any[]
}

const props = withDefaults(defineProps<Props>(), {
  width: ""
});

const newTag = ref("");
//add tags
function addTags() {
  if (newTag.value && tagIsNotFound()) {
  //  tags.value.push(newTag.value);
    props.tags.push({name:newTag.value,id:0})
  }
  newTag.value = "";
}
//delete tag on keypress
function deleteLastTagViaKeyHandler() {
 if(newTag.value) return; // don't delete tag if there's already text written in new tag's name
 deleteTag(props.tags.length-1)
}

//delete tag
function deleteTag(index: number) {
  props.tags.splice(index,1)
 // props.tags.value = props.tags.value.filter((el, i) => i != index);
}

//check if tag is found
function tagIsNotFound() {
  return props.tags.filter(item => item.name == newTag.value ).length < 1;
}
</script>
<style lang="scss" scoped>
input[type=text] {
    color: #666666;
    border: none;
    height: 2.2rem;
    padding: 2px 2px 1px 2px;
    outline: none;
    padding-left: 0.375rem;
}
.tagsBox {
    color: #666666;
    border: 1px solid #abadb3;
    height: fit-content;
    padding: 2px 2px 1px 2px;
   // width: v-bind('props.width');
    outline: none;
  
  .inputTag {
    color: #666666;
    border: 1px solid #abadb3;
    height: 2.2rem;
    padding: 2px 2px 1px 2px;
   // width: v-bind('props.width');
    outline: none;
  }

  .tags {
    margin: 5px 0;

    .tag {
      color: #fff;
      background: #1c69d4;
      border: 0;
      margin: 0 4px 2px 0;
      padding: 4px;
      display: inline-block;

      .closeTag {
        cursor: pointer;
        font-weight: bold;
        padding: 2px;
      }
    }
  }
}


</style>
