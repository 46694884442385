import { ref } from "vue";
import { submitCopyListItem } from "@/helpers/api/list/copy";
import useListSelection from '@/components/ListingPages/ItemsLister/ItemsList/composables/listSelection';

// TODO (cleaner code): think about removing redundancy between "deleteItemConfirmationForm" & other similar form composables (e.g. createSlideForm) [DSP-N26ECg6O]
export default function useCopyItemConfirmationFormHelpers( itemsListHelpers ) {
  const { mutateList, apiPathsConfig } = itemsListHelpers;
  const { clearItemsSelection } = useListSelection();
  
  const isLoading = ref(false);
  const isSuccess = ref(false);
  const isError = ref(false);
  const errorMessage = ref("");
  const newSlideId = ref();
  const newSlidesIds = ref();

  async function submitCopyAction(itemIdOrItemsIds: string) {
    try {
      errorMessage.value = "";
      isLoading.value = true;
      const submissionResponse = await submitCopyListItem(itemIdOrItemsIds, { copyApiPath: apiPathsConfig.copy });
      console.log({submissionResponse})
      // TODO (cleaner code / bug avoidance): think about using "res.ok" to check for response errors [DSP-N26FNA3v]
      if (typeof submissionResponse.entityIds[0] === 'number') {
        // if is successful
        isSuccess.value = true;
        mutateList();
        newSlideId.value = submissionResponse.entityIds[0];
        newSlidesIds.value = submissionResponse.entityIds.join(',');
      } else {
        // if is not successful
        errorMessage.value = `Something went wrong.`;
      }
      
      isLoading.value = false;
      isSuccess.value = true;
      mutateList();
      clearItemsSelection();
    } catch (error) {
      isError.value = true;
      isLoading.value = false;
      errorMessage.value = `Something went wrong. (${error})`;
    }
  }
  return {
    submitCopyAction,
    isError,
    isSuccess,
    isLoading,
    errorMessage,
    newSlideId,
    newSlidesIds
  };
}