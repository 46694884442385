<template>
  <div
    :class='["list-top-bar"]'
  >
    <div :class='["flex items-center", (!listerConfig.itemPickDragMode ? "justify-between" : "justify-end")]'>
      <BulkActionsSelector />
      <ListTopRightBar />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { inject } from 'vue';
import BulkActionsSelector from './BulkActionsSelector/BulkActionsSelector.vue';
import ListTopRightBar from './ListTopRightBar/ListTopRightBar.vue';
const listerConfig = inject('listerConfig', {});
</script>

<style lang="scss" scoped>
</style>