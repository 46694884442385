<template>
  <div class="container">
    <FiltersPane />
    <div class="list-section-container">
      <!-- 
        Note: Stop propagation modifier is important for not unnecessarily triggering items deselct event — N2FCUcEt
        TODO: to think if there's a cleaner way to hand unnecessarily items deselect trigger — N2FCE8BF
      -->
      <ListTopBar @click.stop="" />
      <ItemsList :singleSelect="singleSelect" />
      <ListBottomBar />
    </div>
  </div>
</template>

<script lang="ts" setup>
import ListTopBar from './ListTopBar/ListTopBar.vue';
import ItemsList from './ItemsList/ItemsList.vue';
import ListBottomBar from './ListBottomBar/ListBottomBar.vue';
import FiltersPane from './FiltersPane/FiltersPane.vue';
import { inject, provide } from 'vue';
import { useItemsListHelpers } from "./composables/itemsListHelpers/itemsListHelpers";
import { log } from '@/helpers/utils';
import useUser from '@/composables/user';
const user = useUser();

const props = defineProps<{
  filtersDisplayConfig?: any,
  resultsManipulationCallback?: any,
  apiPathsConfig?: any,
  TableViewItem?: any,
  itemPickDragMode?: any,
  itemPickDragCloneCallback?: Function,
  itemDragDropPlaceholderComponent?: any,
  availableActionsConfig?: any
  availableListViewsConfig?: any,
  singleSelect? :boolean,
  selectMode?:boolean,
  areaAccessName?: string,
  MetaDataOptionsComponent?: any,
  isEnableAllLimitOption?: boolean,
  itemsListHelpers?: any
}>()

const {
  filtersDisplayConfig,
  isEnableAllLimitOption,
  resultsManipulationCallback,
  apiPathsConfig,
  itemPickDragMode,
  itemPickDragCloneCallback,
  itemDragDropPlaceholderComponent,
  availableActionsConfig,
  TableViewItem,
  singleSelect,   
  selectMode,
  // TODO: think about renaming later to singleSelectMode
  availableListViewsConfig,
  areaAccessName,
  MetaDataOptionsComponent
} = props;

const simpleEntityListingPageConfig = inject('simpleEntityListingPageConfig', {}) as any

const listerConfig = {
  itemPickDragMode,
  itemPickDragCloneCallback,
  itemDragDropPlaceholderComponent,
  availableActionsConfig,
  singleSelect,
  selectMode,
  availableListViewsConfig,
  areaAccess: {
    name: areaAccessName,
    hasReadAccess: user.hasReadAccessToArea(areaAccessName),
    hasWriteAccess: user.hasWriteAccessToArea(areaAccessName),
    hasCreateAccess: user.hasCreateAccessToArea(areaAccessName),
    hasDeleteAccess: user.hasDeleteAccessToArea(areaAccessName),
  },
  MetaDataOptionsComponent
}

defineExpose({
  areaAccessName
})

log(itemPickDragMode, {ns: 'ItemsLister', color: 'blue'});

provide('listerConfig', listerConfig)

const { 
  entityItemsNamePlural,
  entityItemName
} = simpleEntityListingPageConfig;

const itemsListHelpers = props.itemsListHelpers ||
  useItemsListHelpers({
    apiPathsConfig,
    resultsManipulationCallback,
    filtersDisplayConfig,
    isEnableAllLimitOption,
    entityItemsNamePlural,
    entityItemName
  });

provide('itemsListHelpers', itemsListHelpers)
  // TODO: think if it's better to use "Symbol" instead of string for the "provide key" — N2QHle20
provide('TableViewItem', TableViewItem)
</script>

<style lang="scss" scoped>
.container {
  /* width: 90%; */
  /* min-width: 500px; */
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @include x-large-devices-down {
    flex-direction: column;
  }
}

.list-section-container {
  max-width: 60rem;
  width: 100%;
  padding: 0 0 2rem 2rem;
  @include x-large-devices-down {
    padding: 2rem 0;
  }
}
</style>