import { fetchPlaylistItemById } from "@/helpers/api/playlist/fetch";
import { log } from "@/helpers/utils";
import { computed, nextTick, reactive, ref, watch } from "vue";
import { onBeforeRouteLeave } from 'vue-router';
import _pick from "lodash/pick";
import { useHasUnsavedChangesHelpers } from "@/composables/utils/hasUnsavedChangesHelpers";
import { getKeyByValue } from '@/helpers/utils';
import ApprovalStatesMapping from '@/data/mappings/ApprovalStates.map';

// TODO: Re-enable this when we fix all the issues with cleaning the sent back to server data (to decrease payload size)
const data = reactive({
  id: null,
  items: [],
  name: "",
  defaultItemDuration: 10,
  campaigns: [],
  size: 0,
  approvalState: getKeyByValue(ApprovalStatesMapping, "Approved"),
  sharingLevel: 1
});

export default function usePlaylist({ id } = {}) {
  const {
    unsetHasUnsavedChanges,
    initializeHasUnsavedChangesWatcher,
    isLoading,
    hasUnsavedChanges,
    isDataInitialized
  } = useHasUnsavedChangesHelpers({
    statesToWatchForChanges: data
  });

  if (id !== null && id !== undefined) {
    data.id = id;
  }

  async function reloadPlaylist() {
    await loadPlaylist(data.id);
  }
  
  async function loadPlaylist(id) {
    data.id = id;
    isLoading.value = true;
    isDataInitialized.value = false;

    const playlistItemResponse = await fetchPlaylistItemById(id) as any[]; 
    const playlistResponseData = playlistItemResponse[0];
    
    // feed cleaned
    {
      const {
        fitment: {
          id: fitmentId,
        },
        defaultItemDuration,
        backgroundColor,
        items,
        name,
        approvalState,
        sharingLevel,
        id,
        // fields that won't be used in update request but helpful to show other playlist data
        campaigns,
        size
      } = playlistResponseData;

      const itemsCleaned = items.map(item => {
        const {
          duration,
          asset: {
            id,
            name,
            previewUri,
            sourceUri,
            assetType,
            sourceMimeType,
            versions,
          }
        } = item;

        const itemCleaned = {
          duration,
          asset: {
            id,
            name,
            previewUri,
            sourceUri,
            assetType,
            sourceMimeType,
            versions,
          },
        };

        return itemCleaned;
      });

      const playlistResponseDataCleaned = {
        id,
        name,
        items: itemsCleaned,
        defaultItemDuration,
        approvalState,
        sharingLevel,
        backgroundColor,
        fitment: {
          id: fitmentId,
        },
        campaigns,
        size
      };
      Object.assign(data, playlistResponseDataCleaned);
    }

    await nextTick();
    isDataInitialized.value = true;
    isLoading.value = false;  
  }
  
  return {
    loadPlaylist,
    reloadPlaylist,
    data,
    isLoading,
    hasUnsavedChanges,
    unsetHasUnsavedChanges,
    initializeHasUnsavedChangesWatcher
  }
}