<!--
  TODO: to generalize and use "GridViewItem" and "GridViewItemWrapper" like what happened in "ItemsTableList" with "TableViewItem" — N2R6kdEX
    (Check TODO references to this "e.g. by searching for 'N2R6kdEX'")
-->

<template>
  <!-- TODO: fix problem with showing loading skeleton on mobile on validation — DSP-N29ItF2o -->
  <content-with-loading-skeleton
      :isLoading="isLoading || (isMobile && (isLoading /* || isValidating */))"
  >
    <component is="ul" class="grid-items-container">
      <template v-for="(item, itemIndex) of filteredListItems" :key="item.id">
        <slot
          name="item"
          v-bind="{
            item,
            ItemActionsButtons,
            CheckMarkIcon,
            isItemHovered,
            isItemSelected,
            handleGridItemMouseEnter,
            handleGridItemMouseLeave
          }"
        ></slot>
        <!-- <div>COMMENT TO-DELETE & replace with a more generic</div> -->
        <Dialog size="full">
          <template #expander="{openModal}">
            <li
              :class='["grid-item", {"selected": isItemSelected(item.id)}]'
              @click.stop="openModal"
              @mouseenter="handleGridItemMouseEnter(item.id)"
              @mouseleave="handleGridItemMouseLeave"
              :key="item.id"
            >
              <ThumbnailPreview
                :previewUri="item.previewUri"
                :alerts="item.alerts"
                :date-last-modified="item.dateLastModified"
                :wrapperLink="item?.versions?.[0]?.resource?.publicUrl || item?.sourceUri"
              />
              <div class="item-name">
                {{ item.name }}
              </div>
              <div class="item-actions-buttons">
                <ItemActionsButtons v-bind="{item, apiPathsConfig}" :class='["mt-1", "item-actions", {"item-hovered": isItemHovered(item.id)}]' />
              </div>
              <CheckMarkIcon
                :class='["item-selection-checkmark", {"selected": isItemSelected(item.id), "item-hovered": isItemHovered(item.id)}]'
                @click.stop="mediaListItemSelectHandler($event, item, itemIndex)"
                v-if="
                  areaAccess?.hasWriteAccess &&
                  user.hasWriteAccessToItem(item) &&
                  user.hasWriteAccessToAssetType(item?.assetType)
                "
              />
            </li>
          </template>
          <template #dialog.title>Preview</template>
          <template #dialog.content>
            <MediaItemPreview v-bind="{filteredListItems, startIndex: itemIndex}" />
          </template>
        </Dialog>
      </template>
    </component>
    <template #loading>
      <component is="ul" class="grid-items-container">
        <ItemGridLoadingSkeleton
          v-for="(item, index) in Array.from({ length: (isMobile ? 3 : 6) })"
          :key="index"
        />
      </component>
    </template>
  </content-with-loading-skeleton>
</template>

<script lang="ts" setup>
import Dialog from '@/components/Dialog/Dialog.vue';
import ThumbnailPreview from './ThumbnailPreview.vue';
import MediaItemPreview from "../../MediaItemPreview/MediaItemPreview.vue";
import ItemActionsButtons from '../ItemActionsButtons/ItemActionsButtons.vue';
import { inject, ref } from 'vue';
import ItemGridLoadingSkeleton from './ItemGridLoadingSkeleton.vue';
import useIsMobile from '@/composables/utils/isMobile';
import CheckMarkIcon from "@/components/icons/CheckMarkIcon.vue";
import useMediaListItemSelectionHelpers from '../composables/mediaListItemSelectionHelpers/mediaListItemSelectionHelpers';
import useUser from '@/composables/user';
const user = useUser();

const itemsListHelpers = inject('itemsListHelpers');
const listerConfig = inject('listerConfig');

const {
  filteredListItems,
  isLoading,
  isValidating,
  apiPathsConfig
} = itemsListHelpers;

const {
  areaAccess
} = listerConfig;
const {
  mediaListItemSelectHandler,
  isItemSelected
} = useMediaListItemSelectionHelpers( itemsListHelpers );

const isMobile = useIsMobile();

// TODO: to think more if this is the best way to make an item's tools to be shown on hover — N28MlsBK
  // To research if there's a CSS-only way to do this (detecting if a specific item of a list is hovered and based on this show its child only "not all").
const currentHoveredItemId = ref();

function handleGridItemMouseEnter(itemId) {
  currentHoveredItemId.value = itemId;
}

function isItemHovered(itemId) {
  return currentHoveredItemId.value === itemId;
}

function handleGridItemMouseLeave() {
  currentHoveredItemId.value = "";
}
</script>

<style lang="scss" scoped>

.grid-items-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1rem;
  /* justify-content: space-between; */
  @include small-devices-down {
    justify-content: center;
  }
}
.grid-item {
  list-style: none;
  width: 10rem;
  height: 10rem;
  position: relative;
  user-select: none;
  @include small-devices-down {
    width: 90vw;
    height: 90vw;
  }
  border-radius: 0.375rem;
  /* background-color: grey; */
  border: 1px solid rgb(136 136 136 / 41%);
  &.selected {
    border: 1px solid #1c69d4;
    
  }

  @include small-devices-up {
    border: 5px solid rgb(255 255 255 / 0%);
    transition: border 0.2s ease-out;
    &:hover, &.selected {
      border: 5px solid #1c69d4;
    }
  }
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item-name {
  max-width: 90%;
  font-size: 0.8rem;
  color: #7a7a7a;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.item-actions {
  @include small-devices-up {
    opacity: 0;
    transition: opacity 0.2s ease-out;
    &.item-hovered {
      opacity: 1;
    }
  }
}

.item-selection-checkmark {
  position: absolute;
  bottom: 0.375rem;
  right: 0.375rem;
  color: #B1C4CF;
  transition: opacity, color 0.2s ease-out;
  opacity: 0.4;
  @include small-devices-up {
    opacity: 0;
  }
  &.item-hovered {
    opacity: 0.4;
  }
  &.selected, &:hover {
    color: #1c69d4;
    opacity: 1;
  }
}
</style>