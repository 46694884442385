<template>
  <TopBar />
  <PageSection class="edit-sections">
    <!-- TODO: think about using  -->
    <div class="media-details-section">
      <div class="edit-section-title">Media Details</div>
      <ResponsiveTable>
        <FieldName><LabelCustomized label="Name" required /></FieldName>
        <FieldContent><TextField v-model="data.name" width="100%" /></FieldContent>
  
        <FieldName><LabelCustomized label="Tags" /></FieldName>
        <FieldContent><TagsField :tags="imageOrVideoTags" width="100%" /></FieldContent>
  
        <FieldName><LabelCustomized label="Expires On" class="expires-on-label"/></FieldName>
        <FieldContent>
          <CheckboxField v-model="isExpirationTimeEnabled" label="Enable Expiration Time" />
          <DateField v-if="isExpirationTimeEnabled" v-model="data.expirationTime" width="100%" enableTime />
        </FieldContent>
        <FieldName><LabelCustomized label="Preview"/></FieldName>
        <FieldContent>
          <Dialog size="full">
            <template #expander="{openModal}">
              <div @click="openModal" class="thumbnail-preview">
                <ImageWithLoadingAndErrorIndicators
                  :src="data.previewUri"
                  imageClass="thumbnail-image"
                  :revalidateIfLessThanAge=60000
                  :dateLastModified="data.dateLastModified"
                  :refreshAfterIfRevalidate=30000
                />
              </div>
            </template>
            <template #dialog.title>Preview</template>
            <template #dialog.content>
              <MediaItemPreview :item="data" />
            </template>
          </Dialog>
        </FieldContent>
      </ResponsiveTable>
    </div>
    <div class="auditing-details-section">
      <div class="edit-section-title">Auditing</div>
      <ResponsiveTable>
        <FieldName><LabelCustomized label="Approval" /></FieldName>
        <FieldContent>
          <SelectField
            v-if="user.isAuditor()"
            :options="approvalStateOptions"
            v-model="data.approvalState"
            width="100%"
          />
          <template v-else>
            {{ approvalStateLabel }}
          </template>
        </FieldContent>

        <FieldName><LabelCustomized label="Last Audited By" /></FieldName>
        <FieldContent></FieldContent>

        <FieldName><LabelCustomized label="Last Audited On" /></FieldName>
        <FieldContent></FieldContent>
      </ResponsiveTable>
    </div>
  </PageSection>
  <FloatingFooterButtons>
    <div class="flex flex-row-reverse gap-2">
      <SaveImageOrVideoButton ref="saveImageOrVideoButtonRef" />
      <ExitButton
        v-bind="{hasUnsavedChanges, unsetHasUnsavedChanges}"
        @exit="onExit"
        @saveAndExit="onSaveAndExit"
      />
    </div>
  </FloatingFooterButtons>
</template>
  


<script lang="ts" setup>
import TopBar from './TopBar/TopBar.vue';
import PageSection from '@/components/layouts/PageSection.vue';
import TextField from '@/components/form-fields/TextField.vue';
import useImageOrVideo from './composables/imageOrVideo';
import TagsField from '@/components/form-fields/TagsField/TagsField.vue';
import CheckboxField from '@/components/form-fields/CheckboxField.vue';
import DateField from '@/components/form-fields/DateField.vue';
import LabelCustomized from '@/components/form-fields/LabelCustomized/LabelCustomized.vue';
import ImageWithLoadingAndErrorIndicators from '@/components/ImageWithLoadingAndErrorIndicators/ImageWithLoadingAndErrorIndicators.vue';
import Dialog from '@/components/Dialog/Dialog.vue';
import MediaItemPreview from '@/components/ListingPages/ItemsLister/MediaItemPreview/MediaItemPreview.vue';
import { ResponsiveTable } from '@/components/ResponsiveTable';
import FieldName from '@/components/ResponsiveTable/customized/FieldName.vue';
import FieldContent from '@/components/ResponsiveTable/customized/FieldContent.vue';
import SelectField from '@/components/form-fields/SelectField/SelectField.vue';
import { approvalStateOptions } from '@/data/options/approvalStateOptions';
import SaveImageOrVideoButton from './SaveImageOrVideoButton/SaveImageOrVideoButton.vue';
import FloatingFooterButtons from '@/components/layouts/FloatingFooterButtons.vue';
import ExitButton from '@/components/Dialog/ButtonsWithDialog/ExitButton/ExitButton.vue';
import router from '@/router';
import { ref, computed } from 'vue';
import useUser from '@/composables/user';
const user = useUser();
const { data, initializeHasUnsavedChangesWatcher, hasUnsavedChanges, unsetHasUnsavedChanges } = useImageOrVideo();
initializeHasUnsavedChangesWatcher();

const isExpirationTimeEnabled = computed({
  get() {
    return (data.expirationTime ? true : false);
  },
  set(newValue) {
    if(newValue) {
      const timeZoneOffsetMs = new Date().getTimezoneOffset() * 60000; // in milliseconds
      const currentTimeMs = new Date().getTime();
      const currentTimeWithTimeZoneOffset = currentTimeMs - timeZoneOffsetMs;
      data.expirationTime = new Date(currentTimeWithTimeZoneOffset).toISOString().slice(0,16);
    } else {
      data.expirationTime = null;
    }
    return newValue;
  }
});
const imageOrVideoTags = ref(data.tags);

const saveImageOrVideoButtonRef = ref();

const approvalStateLabel = computed(()=> {
  const matchedApprovalState = approvalStateOptions.find(option => option.value == data.approvalState) as (typeof approvalStateOptions)[number];
  return matchedApprovalState.label;
})

function onExit() {
  router.push({ path: '/media' })
}

async function onSaveAndExit() {
  await saveImageOrVideoButtonRef.value.saveSlide();
  router.push({ path: '/media' })
}
</script>

<style lang="scss" scoped>
.edit-section-title {
  color: #25294A;
  font-size: 1rem;
  font-weight: bold;
}

.edit-sections {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.75rem;
}

/* .media-details-table {
  border-spacing: 0 0.75rem;
  td {
    min-width: 11rem;
    vertical-align: middle;
    margin: 0.75rem;
    min-height: 2rem;
  }
}

.expires-on-label {
  min-height: 4rem;
}

.align-top-cell {
  vertical-align: top !important;
} */

.thumbnail-preview {
  cursor: pointer;
}

.media-details-section, .auditing-details-section {
  width: 75%;
  @include medium-devices-up {
    width: auto;
    max-width: 47%;
    /* flex-grow: 1; */
    justify-content: space-between;
  }
}
</style>