<template>
<div
  :class="['flex justify-center gap-1.5']"
>
  <RouterLink
    v-if="
      isEditActionEnabledForList &&
      areaAccess?.hasWriteAccess &&
      user.hasWriteAccessToItem(item) &&
        // TODO (May-2023): check if this is correct not needed to be changed to hasReadAccessToAssetType
      user.hasWriteAccessToAssetType(item?.assetType)
    "
    :to="`/${apiPathsConfig.edit}/${item.id}`"
    tag="div"
    component="a"
    title="Edit"

    @click.stop
  >
    <OldThemeIcon name='edit' />
  </RouterLink>
  <OldThemeIcon
    v-else-if="isEditActionEnabledForList"
    name='edit'
    class="disabled"
  />
  
  <CopyItemButtonWithDialog
    v-if="isCopyActionEnabledForList"
    :itemId="item.id"
    :disabled="
      item.assetType !== 'slide' ||
      !areaAccess?.hasCreateAccess ||
      !user.hasWriteAccessToItem(item) ||
      !user.hasCreateAccessToAssetType(item?.assetType)
    "
  />
  <!-- /* TODO (May-2023): confirm that the previous "hasWriteAccessToItem in CopyItemButtonWIthDialog" needs to check "write access" not "create access". It seems that it should be "write access", but confirm it using a test case. */ -->
  <DeleteItemButtonWithDialog
    v-if="isDeleteActionEnabledForList"
    :itemId="item.id"
    :disabled="
      !areaAccess?.hasDeleteAccess ||
      !user.hasDeleteAccessToItem(item) ||
      !user.hasDeleteAccessToAssetType(item?.assetType)
    "
  />
</div>
</template>

<script lang="ts" setup>
import DeleteItemButtonWithDialog from '../ItemActionsButtons/DeleteItem/DeleteItemButtonWithDialog.vue';
import CopyItemButtonWithDialog from '../ItemActionsButtons/CopyItem/CopyItemButtonWithDialog.vue';
import OldThemeIcon from '@/components/icons/OldThemeIcon.vue';
import { inject } from 'vue';
import useUser from '@/composables/user';
const user = useUser();

const listerConfig = inject('listerConfig', {});

const {
  edit: isEditActionEnabledForList = true,
  delete: isDeleteActionEnabledForList = true,
  copy: isCopyActionEnabledForList = false,
} = listerConfig?.availableActionsConfig

const {
  areaAccess
} = listerConfig;

const props = defineProps<{
  item: any;
  apiPathsConfig?: any;
}>();
</script>

<style lang="scss" scoped>
.disabled {
  cursor: default;
  opacity: 0.3;
}
</style>