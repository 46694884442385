<template>
  <Dialog ref="deleteTag">
    <template #expander="{ openModal }">
      <OldThemeIcon name='delete' @click.stop="openModal" :class="['button']" />
    </template>
    <template #dialog.title>Warning</template>
    <template #dialog.content="{ closeModal, closeModalAfter }">


      <!-- TODO (cleaner code): think about removing redundancy between "DeleteItemConfirmationForm" & other forms (e.g. CreateSlideForm) [DSP-N26Dui5A] -->

      <div class="delete-item-form">
        <div class="status-bar-container">

          <StatusBarVue :errorMessage="errorMessage" :isLoading="/* true ||  */isLoading" loadingMessage="Deleting..."
            :isSuccess="isSuccess" successMessage="tag deleted for all players!" />
        </div>
        <div class="confirmation-text">
          Are you sure you want to delete this item?
        </div>
        <div class="form-buttons">
          <ButtonCustomized label="Cancel" @click="closeModal" secondary />
          <ButtonCustomized label="Delete" @click="onSave" />
        </div>
      </div>


    </template>
  </Dialog>
</template>

<script lang="ts" setup>
import Dialog from '@/components/Dialog/Dialog.vue';
import ButtonCustomized from "@/components/form-fields/ButtonCustomized.vue";
import StatusBarVue from '@/components/form-fields/StatusBar/StatusBar.vue';
import OldThemeIcon from '@/components/icons/OldThemeIcon.vue';
import { deleteMetadataForAllPlayers } from '@/helpers/api/player/player';
import { inject, ref } from 'vue';

const emit = defineEmits(['cancel', 'save', 'exit', 'fail'])
const deleteTag = ref()
const itemsListHelpers = inject('itemsListHelpers') as any;

const props = defineProps<{
  category: string;
  value: string;
}>();

const isLoading = ref(false);
const errorMessage = ref("");
const isSuccess = ref(false);

const {
  filtersConfig,
  filters,
} = itemsListHelpers;

async function onSave() {
  try {
    emit('save');
    isLoading.value = true;
    const submissionResponse = await deleteMetadataForAllPlayers(props.category, props.value);
    if (!submissionResponse.success) {
      throw new Error('Failed to delete tag');
    }
    const categoryIndex = filtersConfig.findIndex((filterConfig: any) => filterConfig.label.toLowerCase() === props.category.toLowerCase());
    const category = filtersConfig[categoryIndex];
    const optionIndex = category.options.findIndex((option: any) => option.name === props.value);
    category.options.splice(optionIndex, 1);
    // check if category.options length is 1 and if it is, remove the category from filtersConfig
    if (category.options.length === 1) {
      filtersConfig.splice(categoryIndex, 1);
    }
    delete filters[`FILTER_METADATA_${props.category.toLowerCase()}`];
    isLoading.value = false;
    isSuccess.value = true;
    deleteTag.value.closeModal()
  }
  catch (error) {
    console.log('error', error);
    errorMessage.value = "Failed to delete tag";
    isLoading.value = false;
    isSuccess.value = false;
  }
}
</script>

<style lang="scss" scoped>
.button {
  cursor: pointer;
}

.delete-item-form {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1rem;
}
</style>