export const dimensionsOptionsConfig = [
  {
    label: "16:9",
    items: [
      {
        label: "1920 x 1080 (16:9; 1080p)",
        value: "1920 x 1080"
      },
      {
        label: "1280 x 720 (16:9; 720p)",
        value: "1280 x 720"
      },
      {
        label: "854 x 480 (16:9; 480p)",
        value: "854 x 480"
      },
      {
        label: "640 x 360 (16:9; 360p)",
        value: "640 x 360"
      },
    ]
  },
  {
    label: "4:3",
    items: [
      {
        label: "1600 x 1200",
        value: "1600 x 1200 (4:3)"
      },
      {
        label: "1200 x 900",
        value: "1200 x 900 (4:3)"
      },
      {
        label: "800 x 600",
        value: "800 x 600 (4:3)"
      },
      {
        label: "400 x 300",
        value: "400 x 300 (4:3)"
      }
    ]
  },
  {
    label: "1:1",
    items: [
      {
        label: "1000 x 1000",
        value: "1000 x 1000"
      },
      {
        label: "800 x 800",
        value: "800 x 800"
      },
      {
        label: "600 x 600",
        value: "600 x 600"
      },
      {
        label: "400 x 400",
        value: "400 x 400"
      }
    ]
  },
  {
    label: "Custom",
    items: [
      {
        label: "Custom Size",
        value: ""
      }
    ]
  }
]

export const orientationOptionsConfig = [
  {
    label: "Landscape",
    value: "landscape"
  },
  {
    label: "Portrait",
    value: "portrait"
  }
];