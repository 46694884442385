<template>
<div class="flex flex-row">
  <div
    v-for="option in alignmentOptions"
    :style="`background-image: ${backgroundsUrls[option.name]};`"
    :class='[
      "text-alignment-icon",
      {
        "active": modelValue == option.value
      }
    ]'
    @click="updateTextAlignmentValue(option.value)"
  >
  </div>
</div>
</template>

<script lang="ts" setup>
import { useImportedPathsAsCssUrls } from '@/composables/utils/importedPaths';
import textAlignLeftImgUrl from '../images/text-align-left.png';
import textAlignRightImgUrl from '../images/text-align-right.png';
import textAlignCenterImgUrl from '../images/text-align-center.png';

defineProps<{
  modelValue: string;
}>()

const emit = defineEmits(['update:modelValue']);

const backgroundsUrls = useImportedPathsAsCssUrls({
  "text-align-left": textAlignLeftImgUrl,
  "text-align-right": textAlignRightImgUrl,
  "text-align-center": textAlignCenterImgUrl
});

const alignmentOptions = [
  {
    name: "text-align-left",
    value: "left"
  },
  {
    name: "text-align-center",
    value: "center"
  },
  {
    name: "text-align-right",
    value: "right"
  }
];

function updateTextAlignmentValue(optionValue: string) {
  emit('update:modelValue', optionValue);
}
</script>

<style lang="scss" scoped>
.text-alignment-icon {
  width: 2rem;
  height: 2rem;
  border-radius: 0.375rem;
  background-position: center;
  cursor: pointer;
  &.active, &:hover {
    background-color: rgb(209 213 219);
  }
}
</style>