<template>
    <div class="edit-campaign">
      <h1 class="title separetor">
        Account EDITOR
        <img src="../../components/icons/OldThemeIconRed/images/refresh-button.png" @click="loadAccount()">
      </h1>
     <div class="form-fields mt-3">
        <div class="md:basis-1/2">
           
            <div>
                <StatusBar :errorMessage="errorMessage" />
                <div class="d-flex mb-2 items-center">
                    <label for="name" class="basis-1/2 text-sm font-bold">Name &#9733;</label>
                    <label for="name" class="basis-1/2 text-sm font-bold"> {{ form.name }}</label>
                                </div>
                <div class="d-flex mb-2 items-center">
                    <label for="name" class="basis-1/2 text-sm font-bold">Support Email Address</label>
                    <TextField v-model="values.email" class="basis-1/2" width="100%" />
                </div>
                <div class="d-flex mb-2 items-center">
                    <label for="name" class="basis-1/2 text-sm font-bold">Default Playlist	 </label>
                    <AddPlaylistDialog  v-if="!form.defaultPlaylist" :showBtn="true" @save="addPlaylist($event)"></AddPlaylistDialog>

                     <div  class="basis-1/2"  v-if="form.defaultPlaylist">
                    <label for="name" class="text-sm font-bold mr-2"> {{ form.defaultPlaylist?.name }}</label>
                    <AddPlaylistDialog    :showBtn="false" @save="addPlaylist($event)"></AddPlaylistDialog>

                    <a  class="add-link cursor-pointer ml-2"  @click="removePlaylist()">  Remove </a>

                     </div>
                   

                    <!-- <TextField v-model="form.address1" class="basis-1/2" width="100%" /> -->
                </div>
                <div class="d-flex mb-2">
                    <label for="name" class="basis-1/2 text-sm font-bold">Banner Image</label>
                    <div  class="basis-1/2" v-if="form.bannerImage">
                        <img  :src="form.bannerImage.publicUrl" >
                     <a  @click="removeBannerImage()" class="add-link cursor-pointer ml-2"  > Remove </a>
                    </div>
                   
                    <ButtonCustomized v-if="!form.bannerImage" label="Upload New" @click="openFile()" /> 

                    <input type="file" hidden ref="inputFile" @change="onFileChange($event)"> 
                    <!-- <TextField v-model="form.address2" class="basis-1/2" width="100%" /> -->
                </div>
             
                   

                </div>
               
              
            </div>

        </div>
     
     </div>
  
  
    <div class="edit-footer editor-btn content">
  
      <div class="form-buttons">
        <ButtonCustomized label="Exit" v-if="!hasChanges" @click="onExit" secondary />
        <BeforeExitDialogue v-if="hasChanges" @save="submitAndExit" @exit="onExit"> </BeforeExitDialogue>
        <ButtonCustomized label="Save" @click="onSubmit" :disabled="!hasChanges" />
        <Saving ref="savingDialog"></Saving>
  
      </div>
    </div>
  </template>
  <script lang="ts" setup>
  import { onBeforeMount, onMounted, onUnmounted, ref, watchEffect } from 'vue'
  import TextField from "@/components/form-fields/TextField.vue";
  import ButtonCustomized from "@/components/form-fields/ButtonCustomized.vue";
  import StatusBar from "@/components/form-fields/StatusBar/StatusBar.vue";
  import useNotifications from "@/components/Notifications/composables/notifications";
  import router from '@/router';
  import { onBeforeRouteLeave, useRoute } from 'vue-router'
  import useAccountEditor from './AccountEditorHelper'
import BeforeExitDialogue from '@/components/Dialog/BeforeExitDialog/BeforeExitDialogue.vue'
import Saving from '@/components/Dialog/SavingDialog/Saving.vue'
import AddPlaylistDialog from './AddPlaylist/AddPlaylistDialog.vue';
import { deleteBannerImage, submitUploadBannerImage } from '@/helpers/api/user';
 
  const  inputFile = ref ()
  const savingDialog = ref()
  const route = useRoute()
  const notification = useNotifications();
  const {  values, submitForm, isSuccess, loadAccount,form,
    hasChanges, errorMessage, removeBeforeLoad, isLoading, locationId,locationModel,accountDetails
  } = useAccountEditor();
  onBeforeMount(() => {
    loadAccount()
  })
  onUnmounted(() => {
    removeBeforeLoad()
  })
  onBeforeRouteLeave((to, from) => {
    if (hasChanges.value) {
      const answer = window.confirm(
        'Changes you made may not be saved.'
      )
      if (!answer) return false
    }
  })
  
  async function submitAndExit() {
    savingDialog.value.open()
    await submitForm();
    savingDialog.value.close()
    if (isSuccess.value === true) {
      notification.success('Account updated successfully!');
      removeBeforeLoad();
      router.push({ path: '/' })
    }
  }
  async function onSubmit() {
   savingDialog.value.open()
    await submitForm();
    savingDialog.value.close()
    if (isSuccess.value === true) {
      removeBeforeLoad();
      notification.success('Account updated successfully!');
    }
  }
  function onExit() {
    removeBeforeLoad()
    router.push({ path: '/' })
  }
  function addPlaylist (playlist :any )
  { console.log('playlist');
  console.log(playlist[0])
    form.value.defaultPlaylist = playlist[0] ;
    hasChanges.value = true 
  }
  function removePlaylist () {
    form.value.defaultPlaylist = null ;
    hasChanges.value = true 
  }
  function openFile(){
    inputFile.value.click()
  }
  function onFileChange (event: any)
  {
    submitUploadBannerImage(event.target.files[0]).then(res => {
        if (res.success==true)
        {      notification.success('Banner image updated successfully!');
            loadAccount()
        }
   
  })
    
  }
  function removeBannerImage () {
    deleteBannerImage().then(res=> {
        form.value.bannerImage = null 
    })
  }
  </script>
  <style lang="scss" scoped>
  .link-title {
    font-weight: bold;
  }
.link-title:hover {
   
    text-decoration: underline;
    color: #93A5AE;

}
.editor-btn-exit {
    position: fixed;
    bottom: 0px;
    right: 70px;
  }
  
  .editor-btn {
    position: fixed;
    bottom: 34px;
    width: fit-content;
    right: 0px;
    max-width: 1200px;
    text-align: right;
    z-index: 5;
    padding-bottom: 10px;
    padding-top: 7px;
  }
  
  .status-bar-container {
    margin-bottom: 1rem;
  }
  
  .create-slide-form {
    display: flex;
    flex-direction: column;
    max-width: 20rem;
  }
  
  .separetor {
    border-bottom: 1px solid #e6e6e6;
  }
  
  .form-fields {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
  
    @include medium-devices-down {
      flex-direction: column;
    }
  }
  
  .formGroup {
    display: flex;
    align-items: center;
  
    .select {
      width: 60%;
      margin: 0;
    }
  
    label {
      display: inline-block;
    //  width: 70px;
      color: #262626;
      font-weight: bold;
    }
  }
  
  .form-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
    margin-top: 1rem;
  }
  
  h1.title {
    font-size: 2em;
    font-weight: bold;
    text-transform: uppercase;
  
    @include theme-old {
      color: #252949;
    }
  
    @include theme-new {
      color: #252949;
    }
  
    letter-spacing: 2px;
  }
  
  .sub-title {
    font-size: 1.5em;
    font-weight: bold;
  
    @include theme-old {
      color: #252949;
    }
  
    @include theme-new {
      color: #252949;
    }
  
    letter-spacing: 2px;
  }
  
  .add-link {
    font-weight: bold;
    color: #252949;
    display: inline-block;
  }
  .add-link:hover{
    text-decoration: underline;
  }
  .add-info {
    font-family: Arial;
    color: #666666;
    font-size: 16px;
  }
  
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }
  
  th,
  td {
    text-align: left;
    padding: 8px;
  }
  
  .top-header {
    display: flex;
    align-items: center;
  //  justify-content: space-between;
  }
  
  .table-separetor {
    border-bottom: 1px solid #e6e6e6;
  
  }

  .edit-campaign {
    margin-bottom: 80px;
  }
  


  </style>
  