import { ref } from "vue";
import { useForm } from "vee-validate";
import * as yup from "yup";
import { updateCampaign } from "@/helpers/api/campaign/campaign";
import _pick from "lodash/pick";
import useCampaign from "./campaign";

export default function useSaveCampaignFormHelpers() {
  const { basicInfo, playlists, players } = useCampaign();
  // TODO: think about creating custom form handler that may be more cleaner or readable / easier to use than Vee-Validate [DSP-N23Gxg1I]
  
  const isLoading = ref(false);
  const isSuccess = ref(false);
  const isError = ref(false);
  const errorMessage = ref("");

  const validationSchema = yup.object({
    name: yup.string().label("Name").required(),
    // startDate: yup.date().label('Start Date').required(),
    // endDate: yup.date().label('End Date').required(),
    // isEnabled:  yup.boolean().label('Active')
  });

  const { errors, handleSubmit, values, setFieldValue, isSubmitting } = useForm({
    validationSchema,
  });

  // TODO (Cleaner code) (28-May-2023): refactor to make it matching the playlist editor and other editors code

  const submitForm = async () => {
    // Update "form values" before trying to submit form.
    // - This is important for validation to work on submit for fields that require validation
    Object.assign(
      values,
      _pick(basicInfo, [
        "name",
        "startDate",
        "endDate",
        "isEnabled",
        "approvalState",
        "sharingLeveL",
      ])
    );
    const submitFormWithValidate = handleSubmit(
      handleValidFormSubmit,
      handleInvalidFormSubmit
    );
    await submitFormWithValidate();
  };

  async function handleValidFormSubmit() {
    try {
      errorMessage.value = "";
      isLoading.value = true;
      const updateModel = fillSubmitModel();
      const submissionResponse = await updateCampaign(
        basicInfo.id,
        updateModel
      );
      isLoading.value = false;
      if (typeof submissionResponse[0].name === "string") {
        isSuccess.value = true;
      }
    } catch (error) {
      console.error(error);
      isError.value = true;
      isLoading.value = false;
      errorMessage.value = `Something went wrong. (${error})`;
    }
  }

  async function handleInvalidFormSubmit() {
    let errorsAugmentedInSingleMessage = "";
    // TODO (cleaner code): think if there's a cleaner way to write this [DSP-N26Cti4A]
    if (Object.keys(errors.value).length) {
      Object.keys(errors.value).forEach((key) => {
        errorsAugmentedInSingleMessage += errors.value[key] + ". ";
      });
    }
    errorMessage.value = errorsAugmentedInSingleMessage;
  }

  function fillSubmitModel() {
    const playlists_toSubmit = [] as any;
    playlists.forEach((item) => {
      playlists_toSubmit.push({
        startTime: item.startTime,
        endTime: item.endTime,
        startDate: item.startDate,
        endDate: item.endDate,
        weekdays: item.weekdays,
        playlist: { id: item.playlist.id },
      });
    });

    const newModel = {
      endDate: new Date(basicInfo.endDate).getTime(),
      startDate: new Date(basicInfo.startDate).getTime(),
      name: basicInfo.name,
      isEnabled: basicInfo.isEnabled,
      items: playlists_toSubmit as any,
      players: players.map((player) => {
        return { id: player.id };
      }),
      approvalState: basicInfo.approvalState,
      sharingLevel: basicInfo.sharingLevel,
    };
    return newModel;
  }
  
  return {
    submitForm,
    isError,
    isSuccess,
    errorMessage,
    isLoading,
  };
}