<!-- Linked TODO: check refactoring task / intent "N2LLlJD3" -->

<template>
  <SimpleEntityListingPage
    entityItemsNamePlural="Users"
    entityItemName="User"
    areaAccessName="Administration"
    :createItemPage = true 
     @create="createUser()"
    :ItemsLister="UsersLister"
  />
</template>

<script lang="ts" setup>
import SimpleEntityListingPage from '@/components/ListingPages/SimpleEntityListingPage/SimpleEntityListingPage.vue';
import router from '@/router';
import CreateUserForm from './CreateUserForm/CreateUserForm.vue';
import UsersLister from './UsersLister/UsersLister.vue';

function createUser () {
  router.push('users/create');
}
</script>

<style lang="scss" scoped>

</style>