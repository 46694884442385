import useApi from '../api';

export async function submitUpdateImageOrVideo(data : any ){
  const {put} = useApi();
  console.log({data});
  const itemId = data.id;
  const { responseBody } = await put('api/assets/'+itemId,[data], {isLogoutOnInvalidAuth: true});
  
  const responseData = responseBody;
  return responseData;
}