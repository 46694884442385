<template>
<component 
  :is="wrapperLink ? 'a': 'div'" 
  :href="wrapperLink"
  :class="['relative h-20 w-20', 'image-preview']"
  @click.prevent
>
  <div :class="['absolute overflow-hidden', 'image-preview-child-level-1']">
    <div
      :class="['h-20 w-20 flex items-center justify-center', 'image-preview-child-level-2']"
    >
      <ImageWithLoadingAndErrorIndicators
        :src="previewUri"
        :imageClass='["h-20 w-20 overflow-hidden"]'
        :revalidateIfLessThanAge=60000
        :dateLastModified="dateLastModified"
        :refreshAfterIfRevalidate=30000
      />
      <AlertsIndicator :alerts="alerts" iconClassName="alerts-indicator" />
      <!-- <v-tooltip
        v-if="alerts.length"
        :text="alertsAugmented"
        color="white"
        content-class="alerts-overlay-content"
      >
        <template v-slot:activator="{ props }">
          <OldThemeIconRed
            class="alerts-indicator"
            name="alert"
            v-bind="props"
          />
        </template>
      </v-tooltip> -->
    </div>
  </div>
  
</component>
</template>

<script lang="ts" setup>
import ImageWithLoadingAndErrorIndicators from '@/components/ImageWithLoadingAndErrorIndicators/ImageWithLoadingAndErrorIndicators.vue';
// import OldThemeIconRed from '@/components/icons/OldThemeIconRed/OldThemeIconRed.vue';
import { computed } from 'vue';
import AlertsIndicator from '../FieldsRenderers/AlertsIndicator.vue';

const props = defineProps(['previewUri', 'alerts', 'dateLastModified','wrapperLink']);

const alertsAugmented = computed(() => {
  let messageAugmented = '';
  if (props.alerts.length) {
    props.alerts.forEach((alert, index) => {
      messageAugmented += alert.message + (props.alerts[index+1] ? ', ' : '');
    });
  }
  return messageAugmented;
});
</script>

<style lang="scss" scoped>
.image-preview {
  cursor: pointer;
}

.image-preview-child-level-1 {
}

.image-preview-child-level-2 {
  background-color: #ffffff;
  border: 1px solid #888888;
}

.image-preview :deep(img) {
  left: 0px;
  top: 17.5px;
  width: fit-content;
  height: auto;
  max-height: -webkit-fill-available;
  // width: 80px;
  // height: 45px;
}

:deep(.alerts-indicator) {
  position: absolute;
  bottom: 5px;
  right: 5px;
}
</style>

<style>

/* TODO (cleaner + more maintainable code): think about if veutify style overriding like this is a good option on the long run [DSP-N26Nns5J] */
.alerts-overlay-content {    /* Overrides default veutify styling */
  background-color: #ffffff !important;
  color: #25294A !important;
  box-shadow: 0px 3px 17px rgb(0 0 0 / 16%);
  padding: 5px;
}
</style>