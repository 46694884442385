<template>
  <div class="warning-form">
    {{ msgFirstPart+msgReasonPart }}
  </div>
  <div class="form-buttons">
    <ButtonCustomized label="Ok" @click="onOk" />
  </div>
</template>

<script lang="ts" setup>import { computed } from 'vue';
import ButtonCustomized from "@/components/form-fields/ButtonCustomized.vue";

const emit = defineEmits(['ok']);
const props = defineProps(['itemId', 'itemsIds'])

const isSingleItem = computed(() => {     // false: means multiple items
  if(props.itemId || props.itemsIds.length === 1) {
    return true;
  } else if(props.itemsIds) {
    return false;
  }
});

const msgFirstPart = computed(() => {
  if (isSingleItem.value) {
    return "You are unable to delete this item because ";
  } else {
    return `You are unable to delete all of these items (${props.itemsIds.length} selected) because `;
  }
});

const msgReasonPart = computed(() => {
  if (isSingleItem.value) {
    return "you don't have permission to delete it.";
  } else {
    return "you don't have permission to delete all of them.";
  }
});

function onOk() {
  emit('ok');
}
</script>

<style lang="scss" scoped>
.warning-form {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1rem;
}
</style>