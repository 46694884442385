<template>
<!-- TODO: this component "TopHigherHorizontalBar" seems similar to "@/views/PlaylistEditorView/TopBar/TopBar", think about combining later if needed — N3MHTJ4B -->

<div class="top-higher-horizontal-bar justify-between flex-wrap">
  <div class="flex flex-row items-center gap-2">
    <div class="component-title">
      Slide Editor
    </div>
    <ReloadButton 
      tooltipText="Reload Slide"
      :isLoading="isReloading"
      :reloadHandler="handleReloadSlide"
    />
  </div>
  <div class="flex items-center mb-2">
    <SelectField
      label="Sharing"
      :options="sharingLevelOptions"
      v-model="basicInfo.sharingLevel"
    />
  </div>
</div>
</template>

<script lang="ts" setup>
import ReloadButton from '@/components/ReloadButton/ReloadButton.vue';
import SelectField from '@/components/form-fields/SelectField/SelectField.vue';

import useMediaItem from '../../composables/mediaItem';
import useSlide from '@/views/MediaEditorView/SlideEditor/composables/slide';

import { sharingLevelOptions } from '@/data/options/sharingLevelOptions';


const { reloadMediaItem, isReloading } = useMediaItem();
const { clearSpritesSelection, basicInfo, unsetHasUnsavedChanges } = useSlide();
function handleReloadSlide() {
  clearSpritesSelection();
  reloadMediaItem();
}

</script>

<style lang="scss" scoped>
.component-title {
  color: #25294A;
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.top-higher-horizontal-bar {
  display: flex;
  align-items: center;
  gap: 1rem;
}
</style>