<template>
<div class="top-lower-horizontal-bar">
  <TextField label="Name" v-model="basicInfo.name" :required="true" />
  
  <div class="flex flex-row-reverse gap-2 items-center">
    <SaveAndExitSlideButtons />
    <FullScreenDialog>
      <template #expander>
        <a class="mr-2 uppercase font-bold hover:underline cursor-pointer">
          Full Screen
        </a>
      </template>
    </FullScreenDialog>
  </div>
</div>
<br>
<!-- {{ history.length }}
<div v-for="item of history">
  {{ item.basicInfo.name }}
  <br>
</div> -->
<!-- {{ {history, redoHistory} }} -->
</template>

<script lang="ts" setup>
import TextField from '@/components/form-fields/TextField.vue';
import FullScreenDialog from './FullScreenDialog.vue';
import useSlide from '@/views/MediaEditorView/SlideEditor/composables/slide';
import SaveAndExitSlideButtons from './SaveAndExitSlideButtons.vue';

const { basicInfo, history, redoHistory } = useSlide();
</script>

<style lang="scss" scoped>
.top-lower-horizontal-bar {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
}
</style>