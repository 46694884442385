import { stageParentContainerEl, stageNode } from '@/views/MediaEditorView/SlideEditor/Stage/baseElementsRefs';
import { stageWidth, stageHeight } from '@/views/MediaEditorView/SlideEditor/Stage/baseConfigsRefs';
import { createLogger } from '@/helpers/utils';
const logCustom = createLogger('debug:updateStageSizeBasedOnContainer');

export function updateStageSizeBasedOnContainer() {
  // Inspired by: https://github.com/konvajs/konva/issues/321#issuecomment-377459992

  const width = stageParentContainerEl.value.clientWidth;
  const height = stageParentContainerEl.value.clientHeight;

  logCustom({ width, height });
  
  stageWidth.value = width;
  stageHeight.value = height;
  
  stageNode.value.batchDraw()
}