import { log } from '@/helpers/utils';
import useApi, { getModelFromViewCode } from '../api';
import { prepareFieldsFilters, prepareSearchQuery, prepareSorts } from './helpers/listing';
import listsPathsConfig from './listsPaths.config';

interface FetchListOptions {
  initializeApiPath: string;
  searchApiPath: string;
  isInitialize: boolean;
  limit?: number;
  offset?: number;
  filters?: any;
  sortBy: any;
  searchTerm?: string;
  queryFieldNames?: string[];
}

// TODO: to think about either [1] using "listsPathsConfig" or along with "listName" [2] just depending on pathing "initializePath" & "searchPath" or [3] support both
  // [2] & [3] seems better decisions

export async function fetchList(
  {
    initializeApiPath,
    searchApiPath,
    isInitialize,
    limit,
    offset,
    filters,
    sortBy,
    searchTerm,
    queryFieldNames,
  }: FetchListOptions
) {
  const filtersPrepared = prepareFieldsFilters(filters);
  const searchQueryPrepared = prepareSearchQuery(searchTerm, queryFieldNames);
  const sortsPrepared = prepareSorts(sortBy);

  const listRequestOptions = {
    "limit": (limit || 25),
    "offset": (offset || 0), 
    "query": searchQueryPrepared,
    "filters": filtersPrepared,
    "sorts": sortsPrepared
  };
  const listAPIPath = isInitialize ? initializeApiPath : searchApiPath;
  if(limit == 0) {
    // if retrieve "all items"
    // Note: A list should have been initialized already before a user can retrieve "all items".
    const responseData = await fetchAllListItemsChunked(searchApiPath, listRequestOptions);
    return responseData;
  } else {
    // if not retrieve "all items"
    const { post } = useApi();
    const { responseBody } = await post(listAPIPath, listRequestOptions, {isLogoutOnInvalidAuth: true});
    const responseData = isInitialize ? getModelFromViewCode(responseBody) : responseBody;
    return responseData;
  }
}


export async function fetchAllListItemsChunked(
  searchApiPath: string,
  listRequestOptions: any
) {
  // Fetches all list items in multiple requests (chunks) to overcome jsonSerialization limit of backend.
  const { post } = useApi();
  const listAPIPath = searchApiPath;
  const itemsSerializationLimit = 500;
  const responseData = {} as any;
  for(
    let chunkOffset = 0;
    chunkOffset <= (responseData.totalResults || 0);
    chunkOffset += itemsSerializationLimit
  ) {
    const chunkRequestOptions = {
      ...listRequestOptions,
      limit: itemsSerializationLimit,
      offset: chunkOffset
    };
    const { responseBody } = await post(listAPIPath, chunkRequestOptions, {isLogoutOnInvalidAuth: true});
    if(responseData.totalResults == null && chunkOffset == 0) {
      // if responseData isn't initialized yet
      Object.assign(responseData, responseBody);
    } else {
      // if responseData is initailized, push results chunk
      responseData.results.push(...responseBody.results);
    }
  }
  return responseData;
}

export async function fetchListItemById(
  listName: string,
  id: number|string
) {
  const {get} = useApi();
  try {
    const itemAPIPath = listsPathsConfig[listName].itemPath;
    const { responseBody } = await get(itemAPIPath+'/'+id, {isLogoutOnInvalidAuth: true});
    const responseData = getModelFromViewCode(responseBody);
    // log(responseData, {ns: 'fetchListItemById', color: 'brown'});
    return responseData;
  } catch(err) {
    throw err;
  }
}