import { ref } from "vue";
const currentHoveredItemId = ref();

// TODO: to think about combining similar functionalities of "CheckMarkIcon" in "ItemsGridList" also — N2HLsO83
export default function useItemTableSelectBoxHelpers() {
  function handleTableItemMouseEnter(itemId) {
    currentHoveredItemId.value = itemId;
  }
  
  function isItemHovered(itemId) {
    return currentHoveredItemId.value === itemId;
  }
  
  function handleTableItemMouseLeave() {
    currentHoveredItemId.value = "";
  }

  return {
    handleTableItemMouseEnter,
    isItemHovered,
    handleTableItemMouseLeave,
    currentHoveredItemId
  }
}
