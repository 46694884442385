<template>
  <content-with-loading-skeleton
    :isLoading="isLoadingAndNotReloading"
    loadingMessage="Loading Media Data..."
    :isWithMessage="true"
  >
    <template #loaded>
      <ImageOrVideoEditor v-if="data.assetType == 'image' || data.assetType == 'video'" />
      <SlideEditor v-else-if="data.assetType == 'slide'" />
    </template>
  </content-with-loading-skeleton>
</template>

<script lang="ts" setup>
import SlideEditor from './SlideEditor/SlideEditor.vue';
import ImageOrVideoEditor from './ImageOrVideoEditor/ImageOrVideoEditor.vue';

import useMediaItem from './composables/mediaItem';

import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const id = computed(() => route.params.id as string);

const { loadMediaItem, data, isLoading, isReloading } = useMediaItem();

onMounted(() => {
  loadMediaItem(id.value);
});

const isLoadingAndNotReloading = computed(()=> (isLoading.value && !isReloading.value))
</script>

<style lang="scss" scoped>

</style>