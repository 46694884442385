<!--
  TODO: think about automatic rendering for table cells "td items" no item is passed — N2R6xW7u
-->

<template>
  <td class="name-and-type-cell">
    <RouterLink
      v-if="!selectMode && !singleSelect && areaAccess?.hasWriteAccess"
      :to="`/${apiPathsConfig.edit}/${item.id}`"
      tag="div"
      :class="['font-bold', 'title']"
      component="a"
      @click.stop
    >
      {{item.name}}
    </RouterLink>
    <div v-else>
      {{item.name}}
    </div>
    <div>
      {{ (item.assetType ? item.assetType[0].toUpperCase() + item.assetType.substring(1) : "" ) }}
    </div>
  </td>
  <td class="min-w-[7rem]">
    <div>
      <component :is="renderers.TimeFormatDateOnly" :epochTime="item.dateLastModified" />
    </div>
  </td>
  <td class="min-w-[7rem]">
    <div>
      <component :is="renderers.TimeFormatDateOnly" :epochTime="item.dateCreated" />
    </div>
  </td>
  <td>
    {{ _get(item, 'createdBy.group.name', 'None') }}
  </td>
  <td>
    <component :is="renderers.Size" :size="item.size" />
  </td>
  <td>
    <component :is="renderers.UsedBy" :items="item.campaigns" label="Campaigns" />
      <!--
        TODO: think about setting label to "columnsConfig.find((item)=>item.fieldName=='campaigns').extra.usedByLabel" for label
          - There are issues to this approach:
            - performance.
            - value passing architecture we'll use.
      -->
  </td>
</template>

<script lang="ts" setup>
import _get from 'lodash/get';
const props = defineProps<{
  item: any;
  itemIndex: any;
  ItemActionsButtons?: any;
  ItemTableSelectBox?: any;
  columnsConfig: any;
  renderers: any;
  filteredListItems: any;
  apiPathsConfig?: any;
  listerConfig?: any;
  selectMode?: boolean;
  singleSelect?: boolean;
}>();

const {
  areaAccess
} = props.listerConfig;
</script>

<style lang="scss" scoped>
.title {
  color: #25294A;
  text-decoration: none;
  cursor: pointer;
  max-width: 200px;
  display: inline-block;
}
.title:hover {
  text-decoration: underline;
}

.name-and-type-cell {
  word-break: break-word;
  min-width: 10rem;
}
</style>