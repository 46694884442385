import { reactive, ref, type StyleValue } from "vue";

const isTextSpriteTextEditing = ref(false);
const textAreaRef = ref();
const textContent = ref();
const textAreaStyling = reactive({} as any);

export default function useTextSpriteTextEditing() {
  return {
    isTextSpriteTextEditing,
    textAreaRef,
    textContent,
    textAreaStyling
  };
}