<template>
<div  class="flex items-center justify-between list-bottom-bar">
  <div class="container">
    <SelectField
      :options="pageLimits"
      v-model="limit"
      suffix-label="per page"
    />
  </div>
  <div class="container">
    <v-tooltip text="Jump to first page">
      <template v-slot:activator="{ props }">
        <OldThemeIcon name="bullet-start nav-icon" :class="{ disabled: currentPageNumber === 1 }"
          :disabled="currentPageNumber === 1" v-bind="props" @click="setCurrentPageNumber(1)" />
      </template>
    </v-tooltip>
    <v-tooltip text="Jump to previous page">
      <template v-slot:activator="{ props }">
        <OldThemeIcon name="bullet-left nav-icon" :class="{ disabled: currentPageNumber === 1 }"
          :disabled="currentPageNumber === 1" v-bind="props" @click="setCurrentPageNumber(currentPageNumber - 1)" />
      </template>
    </v-tooltip>
    <ul class="numbers">
    <template v-if="currentPageNumber >= 6 && currentPageNumber < totalPages - 4">
      <li v-for="i in 5" :key="i" @click="setCurrentPageNumber(previousNumber(i))">
        {{ previousNumber(i) }}
      </li>
      <li class="active">{{ currentPageNumber }}</li>
      <li v-for="i in 4" :key="i" @click="setCurrentPageNumber(nextNumber(i))">
        {{ nextNumber(i) }}
      </li>
    </template>
    <template v-else-if="currentPageNumber < 6">
      <li v-for="i in totalPages > 10 ? 10 : totalPages" :key="i" :class="{ active: i == currentPageNumber }"
        @click="setCurrentPageNumber(i)">
        {{ i }}
      </li>
    </template>
    <template v-else>
      <li v-for="i in 10" :key="i" :class="{ active: totalPages - 10 + i == currentPageNumber }"
        @click="setCurrentPageNumber(totalPages - 10 + i)">
        <!-- TODO (Optional): 
            May be there's a room for using named computed state in v-for for more readability
        -->
        {{ totalPages - 10 + i }}
      </li>
    </template>
  </ul>
    <div class="on-mobile text-page-indicator">
      {{ currentPageNumber }} / &nbsp;
      <content-with-loading-inline-dots :isLoading="!Boolean(lastPageNumber)">
        {{ lastPageNumber }}
      </content-with-loading-inline-dots>
    </div>

    <v-tooltip text="Jump to next page">
      <template v-slot:activator="{ props }">
        <OldThemeIcon name="bullet-right nav-icon" :class="{ disabled: currentPageNumber === lastPageNumber }"
          :disabled="currentPageNumber === lastPageNumber" v-bind="props"
          @click="setCurrentPageNumber(currentPageNumber + 1)" />
      </template>
    </v-tooltip>
    <v-tooltip text="Jump to last page">
      <template v-slot:activator="{ props }">
        <OldThemeIcon name="bullet-end nav-icon" :class="{ disabled: currentPageNumber === lastPageNumber }"
          :disabled="currentPageNumber === lastPageNumber" v-bind="props"
          @click="setCurrentPageNumber(lastPageNumber)" />
      </template>
    </v-tooltip>
  </div>
  <div class="range flex items-center">
    Displaying: {{ (offset+1) }} - {{ lastItemInRangeIndex }} of&nbsp;
    
    <content-with-loading-inline-dots :isLoading="!Boolean(totalItems)">
      {{ totalItems }}
    </content-with-loading-inline-dots>
  </div>
</div>

</template>

<script lang="ts" setup>
import { computed, reactive, ref } from 'vue';
import OldThemeIcon from "@/components/icons/OldThemeIcon.vue";
import SelectField from '@/components/form-fields/SelectField/SelectField.vue';
const props = defineProps(['recordsLength'])
const emit = defineEmits(['changeOffset'])

const pageLimits = reactive([
  25,
  50,
  100,
]);
const limit = ref (25) 
const offset = ref (0) 
 
const totalItems = computed(() => {
      return props.recordsLength
});

const totalPages = computed(() => {
  if(totalItems.value ===  0) {
      return 1
    } else {
      return Math.ceil(totalItems.value / limit.value);
    }
});
  
const currentPageNumber = computed(() => {
    // get current page from offset and limit
    return (Math.floor((offset.value) / limit.value) + 1) || 1;
  });
  const lastPageNumber = computed(() => totalPages.value)
  
  function setCurrentPageNumber(pageNumber :number ) {
    // set offset from current page
    setOffsetFromPageNumber(pageNumber);
  }
  
  function setOffsetFromPageNumber(pageNumber :number ) {
    const newOffset = (pageNumber - 1) * limit.value;
    offset.value = newOffset;
    emit('changeOffset',{offset:offset.value , lastIndex: lastItemInRangeIndex.value})
  }
const lastItemInRangeIndex = computed(() => {
  const maxItemIndexInRange = offset.value + limit.value
  if(maxItemIndexInRange > totalItems.value) {
    return totalItems.value;
  } else {
    return maxItemIndexInRange;
  }
  
});

function previousNumber(i: number) {
  return currentPageNumber.value - 6 + i;
}
function nextNumber(i: number) {
  return currentPageNumber.value + i;
}
</script>

<style lang="scss" scoped>
.numbers {
  display: flex;
  align-items: center;
  list-style: none;
  justify-content: center;
  gap: 15px;
  margin: 0 10px;

  li {
    cursor: pointer;
  }

  .active {
    color: #ddd;
  }
}
.list-bottom-bar {
  flex-wrap: wrap;
  position: sticky;
  padding: 1em 0;
  /* box-shadow: rgb(37 41 74 / 10%) 0px 0px 2em; */
  z-index: 1;
  /* width: 60em; */
}

.container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex: 0;
  min-width: 8.5em;
}
.range {
  white-space: nowrap;
  flex: 0;
  width: 13em;
}
.container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  margin: 0 0.375rem;
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.nav-icon {
  cursor: pointer;
}


.on-desktop {
  @include medium-devices-down {
    display: none;
  }
}

.on-mobile {
  @include medium-devices-up {
    display: none;
  }
}

.text-page-indicator {
  /* min-width: 4em; */
}

.numbers-list {
  margin: 0 0.375rem;
}
</style>