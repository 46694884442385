<template>
  <Dialog ref="savingDialog">
    <template #expander>
      <ButtonCustomized
        label="Save"
        @click="savePlaylist"
        :disabled="!hasUnsavedChanges"
      />
    </template>
    <template #dialog.title>Saving</template>
    <template #dialog.content="{closeModal, closeModalAfter}">
      <div class="saving-progress-dialog-content">
        <!-- TODO: think about adding height option for StatusBar — N2LBs3CL -->
        <StatusBar
          :errorMessage="errorMessage"
          :isLoading="isLoading"
          loadingMessage="Saving..."
          :isSuccess="isSuccess"
          successMessage="Saved!"
        />
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts" setup>
import ButtonCustomized from '@/components/form-fields/ButtonCustomized.vue';
import Dialog from '@/components/Dialog/Dialog.vue';
import StatusBar from "@/components/form-fields/StatusBar/StatusBar.vue";
import useNotifications from '@/components/Notifications/composables/notifications';
import usePlaylist from '../composables/playlist';
import useSavePlaylistFormHelpers from '../composables/savePlaylistFormHelpers';
import { ref } from 'vue';

defineExpose({
  savePlaylist
})

const notification = useNotifications();
const { submitForm, isSuccess, errorMessage, isLoading } = useSavePlaylistFormHelpers();
const { hasUnsavedChanges, unsetHasUnsavedChanges } = usePlaylist();

const savingDialog = ref();

async function savePlaylist() {
  savingDialog.value.openModal();
  await submitForm();
  if(isSuccess.value === true) {
    notification.success('Playlist saved successfuly!');
    unsetHasUnsavedChanges({resetDataInitializationState: false});
    savingDialog.value.closeModalAfter(1000);
  } else {    // on failure
    notification.failure('Playlist saving failed!');
  }
}


</script>

<style lang="scss" scoped>
.saving-progress-dialog-content {
  min-height: 60px;
  min-width: 300px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}
</style>