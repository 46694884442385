import { useItemsListHelpers } from "@/components/ListingPages/ItemsLister/composables/itemsListHelpers/itemsListHelpers";
import itemsListerConfig from './config/itemsLister.config';

const {
  apiPathsConfig,
  filtersDisplayConfig,
  entityItemsNamePlural,
  entityItemName
} = itemsListerConfig;

function usePlaylistsListHelpers() {
  return useItemsListHelpers({
    apiPathsConfig,
    filtersDisplayConfig,
    entityItemsNamePlural,
    entityItemName
  })
}


export default usePlaylistsListHelpers;