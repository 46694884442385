import { prepareImportedPaths, prepareImportedPathsAsCssUrls } from '@/helpers/utils';
import { computed } from 'vue';

export function useImportedPaths(pathsContainer: any) {
  const importedPaths = computed(() => {
    const importedPaths = prepareImportedPaths(pathsContainer)
    return importedPaths;
  });
  return importedPaths;
}

export function useImportedPathsAsCssUrls(pathsContainer: any) {
  const cssUrls = computed(() => {
    const importedPaths = prepareImportedPathsAsCssUrls(pathsContainer)
    return importedPaths;
  });
  return cssUrls;
}