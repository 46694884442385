import { slideMainLayerNode } from '@/views/MediaEditorView/SlideEditor/Stage/baseElementsRefs';

export function getSlideShapesIntersectingWithShape(shape) {
  const slideShapes = getAllSlideShapes();

  var box = shape.getClientRect();
  const intersectingShapes = slideShapes.filter((slideShape) =>
    Konva.Util.haveIntersection(box, slideShape.getClientRect())
  );
  
  return intersectingShapes;
}

export function getSlideShapesIntersectingWithPoint(x:number,y:number) {
  const box = {     // the box's dimensions are arbitrary it's chosen to be 1 by 1 at the center of the passed x,y positions
    x: x-0.5,
    y: y-0.5,
    height: 1,
    width: 1
  };

  // Side Notes: there may be a better solution of getting shapes intersecting with a point (may be there's a Konva Util that does this) instead of having a small box that resembles the point.

  const slideShapes = getAllSlideShapes();
  const hoveredOverShapes = slideShapes.filter((shape) =>
    Konva.Util.haveIntersection(box, shape.getClientRect())
    // shape.intersects(x, y)
  );

  return hoveredOverShapes;
}

export function getAllSlideShapes() {
  const imageShapes = slideMainLayerNode.value.find('Image');
  const textShapes = slideMainLayerNode.value.find('Text');
  
  const slideShapes = [...imageShapes, ...textShapes];
  return slideShapes;
}