import type { Sprite } from "@/helpers/types/media/slide/Sprite";
import { computed, reactive, ref } from 'vue';
import useSlide from '@/views/MediaEditorView/SlideEditor/composables/slide';
import { createLogger } from "@/helpers/utils";

export default function useKeyboardKeysStageHandlers() {
  const arrowKeysStageHandlers = useArrowKeysStageHandlers();
  const deleteKeysStageHandlers = useDeleteKeyHandlers();

  function onKeyDown(e: any) {
    arrowKeysStageHandlers.onKeyDown(e);
    deleteKeysStageHandlers.onKeyDown(e);
  }

  function onKeyUp(e: any) {
    arrowKeysStageHandlers.onKeyUp(e);
  }

  return {
    onKeyDown,
    onKeyUp
  };
}


type MoveDirection = 'left'|'right'|'up'|'down'|'upLeft'|'upRight'|'downLeft'|'downRight'|undefined;

export function useArrowKeysStageHandlers() {     // keyboard keys handlers for the slide editor stage
  const {
    selectedSpritesStores,
      // TODO: think about if this is the more straight forward way to get the reactive stores of the "selected sprites" — N2JL9DFF
      // TODO: think about a more straight forward way (that's also more performant in case of many sprites selected) to edit move the selected sprites — N2JKrNBM
        /*
          Unfortunately, these solutions doesn't work for now:
            solution 1: translate(), setAbsolutePosition(), x(), y(), methods of a selectedShapeNode
            solution 2: translate(), setAbsolutePosition(), x(), y(), methods of a transformerNode
            May be I need to review why they weren't working.
            There's also a related question that's posted on Konva's documentation comments waiting for reply for it:
              https://konvajs.org/docs/events/Keyboard_Events.html#comment-6119198461
        */
  } = useSlide();
  const pressedArrowKeys = reactive([] as string[]);
  
  const moveSmallStep = 1;
  const moveBigStep = 10;
  const modifierPressed = ref(false);
  const step = computed(() => modifierPressed.value ? moveBigStep : moveSmallStep);
  const isUpPressed = computed(() => pressedArrowKeys.includes("ArrowUp"));
  const isDownPressed = computed(() => pressedArrowKeys.includes("ArrowDown"));
  const isLeftPressed = computed(() => pressedArrowKeys.includes("ArrowLeft"));
  const isRightPressed = computed(() => pressedArrowKeys.includes("ArrowRight"));
  const moveDirectionToApply = computed(() => {
    if( isLeftPressed.value && isUpPressed.value ) {
      return 'upLeft'
    }

    else if( isLeftPressed.value && isDownPressed.value ) {
      return 'downLeft'
    }

    else if( isLeftPressed.value ) {
      return 'left'
    }

    else if( isRightPressed.value && isUpPressed.value ) {
      return 'upRight'
    }

    else if( isRightPressed.value && isDownPressed.value ) {
      return 'downRight'
    }

    else if( isRightPressed.value ) {
      return 'right'
    }

    else if( isUpPressed.value ) {
      return 'up'
    }

    else if( isDownPressed.value ) {
      return 'down'
    }
  });

  const onKeyDown = (e: any) => {
    modifierPressed.value = e.shiftKey || e.ctrlKey || e.metaKey;
    const isPressedKeyAnArrowKey = () => ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key);
    if ( !pressedArrowKeys.includes(e.key) && isPressedKeyAnArrowKey() ) {
      pressedArrowKeys.push(e.key);
    }
    handleMoveSelectedSpritesWithPressedKeys();
  }

  const onKeyUp = (e: any) => {
    pressedArrowKeys.splice(pressedArrowKeys.indexOf(e.key), 1);
  }

  const handleMoveSelectedSpritesWithPressedKeys = () => {
    moveAllSelectedSprites(moveDirectionToApply.value, step.value);
  }

  function moveAllSelectedSprites(direction: MoveDirection, step: number) {
    selectedSpritesStores.forEach((selectedSprite: Sprite) => {
      moveSprite(selectedSprite, direction, step)
    })
  }

  function moveSprite(sprite: Sprite, direction: MoveDirection, step: number) {
    switch (direction) {
      case 'left':
        sprite.x -= step;
        break;
      case 'right':
        sprite.x += step;
        break;
      case 'up':
        sprite.y -= step;
        break;
      case 'down':
        sprite.y += step;
        break;
      case 'upLeft':
        sprite.y -= step;
        sprite.x -= step;
        break;
      case 'upRight':
        sprite.y -= step;
        sprite.x += step;
        break;
      case 'downLeft':
        sprite.y += step;
        sprite.x -= step;
        break;
      case 'downRight':
        sprite.y += step;
        sprite.x += step;
        break;
      default:
        break;
    }
  }

  return {
    onKeyDown,
    onKeyUp
  }
}

function useDeleteKeyHandlers() {
  const {
    removeSprite,
    selectedSpritesIds
  } = useSlide();

  function onKeyDown(e: any) {
    if (e.key === 'Delete') {
      selectedSpritesIds.forEach((spriteId: string) => {
        removeSprite(spriteId);
      });
    }
  }

  return {
    onKeyDown
  }
}
