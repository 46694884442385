import { nextTick, reactive, ref } from 'vue'
import { fetchMediaItemById } from '@/helpers/api/media/fetch';
import useSlide from '@/views/MediaEditorView/SlideEditor/composables/slide';
import useImageOrVideo from '@/views/MediaEditorView/ImageOrVideoEditor/composables/imageOrVideo';

import AssetTypesMapping from '@/data/mappings/AssetTypes.map';

import { log } from '@/helpers/utils';

export interface AssetEntityRetrieved {
  assetType: number,
  sprites: any[],
}

const data = reactive({
  id: "",
  assetType: "",
  tags :[],
  assetEntityRetrieved: {} as assetEntityRetrieved
});

const isLoading = ref(false);
const isReloading = ref(false);
const error = ref();

export default function useMediaItem(){
  const slide = useSlide();
  const imageOrVideo = useImageOrVideo();
  
  async function loadMediaItem(id_inputted?: string) {
    const id_toQuery = id_inputted || data.id;
    if(id_inputted) {
      data.id = id_inputted;
    }
    
    isLoading.value = true;

    const responseData = await fetchMediaItemById(id_toQuery) as any;
    log(responseData.entities[0], {ns: 'useMediaItem', color: 'brown'});
    Object.assign(data.assetEntityRetrieved,responseData.entities[0]);
    const assetTypeIndex = data.assetEntityRetrieved.assetType;
    data.assetType = AssetTypesMapping[assetTypeIndex];
    if(data.assetType == 'slide') {
      slide.initializeFonts(responseData.fontFamilies);
      await slide.initializeRetrievedData(data.assetEntityRetrieved);
    } else if(data.assetType == 'image' || data.assetType == 'video') {
      imageOrVideo.initializeRetrievedData(data.assetEntityRetrieved)
    }
    isLoading.value = false;
  }

  async function reloadMediaItem() {
    isReloading.value = true;
    await loadMediaItem();
    isReloading.value = false;
    slide.unsetHasUnsavedChanges();
  }

  return {
    data,
    loadMediaItem,
    reloadMediaItem,
    isLoading,
    isReloading,
    error
  };
}