// TODO: think about renaming file name to tableBulkSelectBoxHelpers instead of tableBulkSelectBoxHelpers_forMediaList because these helpers are generally for any list

import useMediaListSelectAllItemsHelpers from '../../composables/mediaListItemSelectionHelpers/mediaListSelectAllItemsHelpers';

export default function useTableBulkSelectBoxHelpers( itemsListHelpers ) {
  const {
    addAllItemsToSelection,
    isNoItemSelected,
    isAllItemsSelected,
    clearMediaItemsSelection
  } = useMediaListSelectAllItemsHelpers( itemsListHelpers );

  return {
    clearMediaItemsSelection,
    addAllItemsToSelection,
    isAllItemsSelected,
    isNoItemSelected
  }
}