import useApi, { getModelFromViewCode } from './api';
import useDataSourceStore from '@/stores/dataSource';
import { log } from '@/helpers/utils';

export async function submitLogin({username: usernameInput, password}: Credentials, baseURL?: string) {
  try {
    const {postUrlEncoded} = useApi();
    const loginReturnUrlEncoded = getLoginReturnUrlEncoded();
    const returnUrlString = `?ReturnUrl=${loginReturnUrlEncoded}`;
    const { responseBody } = await postUrlEncoded('Account/LogOn'+returnUrlString, {username: usernameInput, password})
    const responseData = getModelFromViewCode(responseBody);
    // console.log({user: responseData.user})
    console.log({hmmm: responseData?.user})
    // console.log({user: responseData.user})
    if (responseData === null) {
      console.log({hey: responseData})
      throw new Error("Some error occured not allowing sign in process to continue.");
    } else if (responseData?.user === null) {
      throw new Error("The user name or password provided is incorrect.");
    }
    return responseData;
  } catch(err: any) {
    log({err}, "Some error occured not allowing sign in process to continue.");
    throw new Error("Some error occured not allowing sign in process to continue.")
  }
}

function getLoginReturnUrlEncoded() {
  const { activeDataSourceBaseUrl } = useDataSourceStore();
  const regex = /https?:\/\/[^/]*\/?(.*?)\/?$/;
  const subst = `$1/`;
  // The substituted value will be contained in the result variable
  const result = activeDataSourceBaseUrl.replace(regex, subst);
  const loginReturnUrl = result.startsWith("/") ? result : "/"+result;
  // const loginReturnUrl = "/";
  const loginReturnUrlEncoded = encodeURIComponent(loginReturnUrl);
  return loginReturnUrlEncoded;
}

export interface Credentials {
  username: string, password: string
}

export async function submitLogout(baseURL?: string) {
  try {
    const { postUrlEncoded } = useApi(); // Assuming useApi provides the API functions
    const returnUrlString = '?ReturnUrl=/'; // Redirect to home or login page after logout

    await postUrlEncoded('Account/LogOff' + returnUrlString, {});


  } catch (err: any) {
    log({ err }, "Some error occurred during the logout process.");
    throw new Error("Some error occurred during the logout process.");
  }
}

export async function updateAccount (itemId: any , model : any ){
  const {put} = useApi();
  model.id = Number(itemId) ; 
  const { responseBody } = await put('api/accounts/'+itemId,[model], {isLogoutOnInvalidAuth: true});
  const responseData = responseBody;
  return responseData;
}
export async function getAccountDetails (itemId:string ) {
  const {get} = useApi();
  const { responseBody } = await get('api/accounts/'+itemId,{isLogoutOnInvalidAuth: true});
  const responseData = responseBody;
  return responseData;
}
export async function submitUploadBannerImage(fileData: File) {
  const { post } = useApi();
  const fileBinaryData = await fileData.arrayBuffer();

  const { responseBody } = await post(
    'api/accounts/banner-image?data='+encodeURIComponent('{}')+'&fileData='+encodeURIComponent(fileData.name),
    fileBinaryData,
    {
      isLogoutOnInvalidAuth: true,
      config: {
        headers: {
          'Content-Type': 'application/octet-stream',
          'Accept': 'application/octet-stream',
          'X-Mime-Type': fileData.type,
          'X-File-Name': encodeURIComponent(fileData.name)
        },
      }
    }
  );
  const responseData = responseBody;
  return responseData;
}
export async function deleteBannerImage() {
  const { deleteRequest } = useApi();

  const { responseBody } = await deleteRequest('api/accounts/banner-image', {isLogoutOnInvalidAuth: true});
  const responseData = responseBody;
  return responseData;
}

export async function sendPasswordResetEmail(email: string) {
  const { postUrlEncoded } = useApi();
  const { responseBody ,response } = await postUrlEncoded('Account/ChangePasswordRequest', {userName: email})
  const responseData = getModelFromViewCode(responseBody);
  if(response.status !== 200 && response.status !== 201){
    throw new Error(responseData?.errors?.[0] || "Some error occured not allowing password reset process to continue.");
  }
  if (responseData?.errors?.length > 0) {
    throw new Error(responseData.errors[0]);
  }
  return responseData;
}

export async function getPasswordResetData(token: string) {
  const { get } = useApi();
  const { responseBody } = await get('Account/ChangePassword?ResetCode='+token)
  const responseData = getModelFromViewCode(responseBody);
  if (responseData?.errors?.length > 0) {
    throw new Error(responseData.errors[0]);
  }
  return responseData;
}

export async function submitNewPassword({
  resetCode,
  newPassword,
  confirmPassword,
  user
}: {
  resetCode: string;
  newPassword: string;
  confirmPassword: string;
  user: Object;
}) {
  const { post } = useApi();
  const { responseBody, response } = await post(
    "/api/Accounts/ChangePassword",
    { newPassword, confirmPassword, resetCode, user }
  );

  if (response.status === 200 || response.status === 201) {
    return;
  }

  const responseData = typeof responseBody === "string" ? getModelFromViewCode(responseBody) : responseBody;
  //  if statues not 200 or 201
  if (response.status !== 200 && response.status !== 201) {
    throw new Error(
      responseData?.errors?.[0] ||
        "Some error occured not allowing password reset process to continue."
    );
  }

  return 
}

export default submitLogin;