<template>
  <div class="menu-container">
    <v-menu
      open-on-hover
    >
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
        >
          <slot name="expander"></slot>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          class="sub-item"
          @click="handleListItemClick(item)"
        >
          <v-list-item-title>
            {{ item.label }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
const router = useRouter();
function handleListItemClick(item: any) {
  if (item.path) router.push(item.path)
  else if (item.event) item.event.callback()
}
defineProps(['items'])
</script>

<style lang="scss" scoped>
.menu-container :deep(.v-btn) {
  box-shadow: none;
  text-transform: none;
  background: none;
  margin: 0;
  padding: 0;
  min-width: 1em;
  :hover {
    box-shadow: none;
  }
  .v-btn__content {
    color: #DAE4ED;
  }

  .v-btn__overlay {
    display: none;
  }

  .down-icon {
    margin-top: 0.3em;
    margin-left: 0.3em;
    opacity: 0.5;
  }
}


.sub-item {
  border-radius: 0.3em;
  margin: 0.3em;
  min-height: auto;
  cursor: pointer;
  :deep(.v-list-item-title) a {
    text-decoration: none;
    color: inherit;
  }
  
  &:hover {
    background-color: #191c3314;
  }
}

.active {
  background-color: #393e5d14;
  padding: 0.375rem;
  border-radius: 0.375rem;
}
</style>