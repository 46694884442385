import SharingLevelsMapping from '@/data/mappings/SharingLevels.map';

export const sharingLevelOptions = [
  // TODO: think about a cleaner way to write this.
  {
    label: SharingLevelsMapping[1],   // My Organization
    value: 1
  },
  {
    label: SharingLevelsMapping[2],   // Shared
    value: 2
  },
];