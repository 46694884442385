<template>
  <ItemsLister v-bind="itemsListerConfig" :itemsListHelpers="campaignsListHelpers" />
</template>

<script lang="ts" setup>
import ItemsLister from '@/components/ListingPages/ItemsLister/ItemsLister.vue';
import itemsListerConfig from './config/itemsLister.config';
import useCampaignsListHelpers from './campaignsListHelpers';
const campaignsListHelpers = useCampaignsListHelpers();
</script>

<style lang="scss" scoped>
</style>