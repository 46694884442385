import { transformerNode } from '@/views/MediaEditorView/SlideEditor/Stage/baseElementsRefs';
import { log } from '@/helpers/utils';
import useTextSpriteEditAreaHelpers from '../../TextSpriteEditArea/composables/textSpriteEditAreaHelpers';
import useSlide from '@/views/MediaEditorView/SlideEditor/composables/slide';

export function handleTextSpriteDblClick(e: any) {
  const textNode = e.target;
  const { selectedTextSpriteField } = useSlide();
  
  if(selectedTextSpriteField.value != undefined) {
    selectedTextSpriteField.value.focus();
    selectedTextSpriteField.value.select();
  }
  
  return; // below code is disable temprarily in order to deliver faster
  // TODO: come back and continue the in-place text editing feature — N2LFALC4
  textNode.hide();
  transformerNode.value.hide();

  const textNodePosition = textNode.absolutePosition();

  const { 
    isTextSpriteTextEditing,
    textAreaRef,
    textContent,
    textAreaStyling
  } = useTextSpriteEditAreaHelpers();
  isTextSpriteTextEditing.value = true;
  textContent.value = textNode.text();

  // prepare textarea styling to match text node styling as possible
  // inspired from: https://konvajs.org/docs/sandbox/Editable_Text.html
  textAreaStyling.top = textNodePosition.y + 'px';
  textAreaStyling.left = textNodePosition.x + 'px';
  textAreaStyling.width = textNode.width() - textNode.padding() * 2 + 'px';
  textAreaStyling.height = textNode.height() - textNode.padding() * 2 + 5 + 'px';
  textAreaStyling.fontSize = textNode.fontSize() + 'px';
  textAreaStyling.border = 'none';
  textAreaStyling.padding = '0px';
  textAreaStyling.margin = '0px';
  textAreaStyling.overflow = 'hidden';
  textAreaStyling.background = 'none';
  textAreaStyling.outline = 'none';
  textAreaStyling.resize = 'none';
  textAreaStyling.lineHeight = textNode.lineHeight();
  textAreaStyling.fontFamily = textNode.fontFamily();
  textAreaStyling.transformOrigin = 'left top';
  textAreaStyling.textAlign = textNode.align();
  textAreaStyling.color = textNode.fill();
  const rotation = textNode.rotation();   // rotation isn't supported currently but kept for future if needed
  let transform = '';
  if (rotation) {
    transform += 'rotateZ(' + rotation + 'deg)';
  }
  let px = 0;
  // also we need to slightly move textarea on firefox
  // because it jumps a bit
  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  if (isFirefox) {
    px += 2 + Math.round(textNode.fontSize() / 20);
  }
  transform += 'translateY(-' + px + 'px)';
  textAreaStyling.transform = transform;
  textAreaStyling.height = 'auto';
  textAreaStyling.height = textAreaRef.value.scrollHeight + 3 + 'px';

  textAreaRef.value.focus();

  function removeTextarea() {
    isTextSpriteTextEditing.value = false;
    window.removeEventListener('click', handleOutsideClick);
    textNode.show();
    transformerNode.value.show();
    transformerNode.value.forceUpdate();
  }

  function setTextareaWidth(newWidth) {
    if (!newWidth) {
      // set width for placeholder
      newWidth = textNode.placeholder.length * textNode.fontSize();
    }
    // some extra fixes on different browsers
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    if (isSafari || isFirefox) {
      newWidth = Math.ceil(newWidth);
    }

    const isEdge = document.documentMode || /Edge/.test(navigator.userAgent);
    if (isEdge) {
      newWidth += 1;
    }
    textAreaStyling.width = newWidth + 'px';
  }

  textAreaRef.value.addEventListener('keydown', function (e) {
    // hide on enter
    // but don't hide on shift + enter
    if (e.keyCode === 13 && !e.shiftKey) {
      textNode.text(textContent.value);
      removeTextarea();
    }
    // on esc do not set value back to node
    if (e.keyCode === 27) {
      removeTextarea();
    }
  });

  textAreaRef.value.addEventListener('keydown', function (e) {
    const scale = textNode.getAbsoluteScale().x;
    setTextareaWidth(textNode.width() * scale);
    textAreaStyling.height = 'auto';
    textAreaStyling.height =
    textAreaStyling.scrollHeight + textNode.fontSize() + 'px';
  });

  function handleOutsideClick(e) {
    if (e.target !== textAreaRef.value) {
      textNode.text(textContent.value);
      removeTextarea();
    }
  }

  setTimeout(() => {
    window.addEventListener('click', handleOutsideClick);
  });
}