import { reactive, ref, computed } from 'vue';
import { stageNode, transformerNode, selectionRectangleNode } from '@/views/MediaEditorView/SlideEditor/Stage/baseElementsRefs';
import { stagePosition, stageScaleConfig } from '@/views/MediaEditorView/SlideEditor/Stage/baseConfigsRefs';
import { createLogger } from '@/helpers/utils';

import { getSlideShapesIntersectingWithShape } from '@/views/MediaEditorView/SlideEditor/utils/spritesGetters';
import { isNodeASlideSprite } from '@/views/MediaEditorView/SlideEditor/utils/spriteGetters';
import { 
  clearSpritesSelection,
  addSpriteToSelection,
  removeSpriteFromSelection,
  addSpritesToSelection
} from '@/views/MediaEditorView/SlideEditor/utils/spritesSelection';

// const logCustom = createLogger('debug:selectorRectangleStageHandlers');

const isMouseDownStartedOnStage = ref(false);


export const selectionRectangleProps = reactive({
  config: {
    visible: false,
  },
  positions: {
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0
  }
})

export const selectionRectanglePropsConfig_basedOnDrawMode = computed(() => {
  if(isDrawMode.value) {
    return {
      fill: 'rgba(0,0,255,0)',
      stroke: 'black',
      strokeWidth: 5,
      dash: [15, 10],
    };
  } else {
    return {
      fill: 'rgba(0,0,255,0.5)',
      stroke: 'black',
      strokeWidth: 0,
      dash: [15, 10],
    };
  }
})

export const selectionRectangleDrawPositions = reactive({
  x1: 0,
  y1: 0,
  x2: 0,
  y2: 0
});

export const isDrawMode = ref(false);
export const drawModeCallback = ref(null);

export function toggleDrawMode() {
  isDrawMode.value = !isDrawMode.value;
}

export const selectorRectangleStageHandlers = {
  onMouseDown(e: any) {
    // do nothing if we mousedown on any shape
    if (e.target !== stageNode.value && !isDrawMode.value) {
      return;
    }
    e.evt.preventDefault();

    // update selectionRectangle
    selectionRectangleProps.positions.x1 = stageNode.value.getRelativePointerPosition().x;
    selectionRectangleProps.positions.y1 = stageNode.value.getRelativePointerPosition().y;
    selectionRectangleProps.positions.x2 = stageNode.value.getRelativePointerPosition().x;
    selectionRectangleProps.positions.y2 = stageNode.value.getRelativePointerPosition().y;
    
    isMouseDownStartedOnStage.value = true;

    selectionRectangleNode.value.width(0);
    selectionRectangleNode.value.height(0);
  },

  onMouseMove(e: any) {
    // do nothing if we didn't start selection
    
    if(isMouseDownStartedOnStage.value) {
      selectionRectangleNode.value.visible(true);
    } else {
      return;
    }
    e.evt.preventDefault();
    selectionRectangleProps.positions.x2 = stageNode.value.getRelativePointerPosition().x;
    selectionRectangleProps.positions.y2 = stageNode.value.getRelativePointerPosition().y;
    const {x1, x2, y1, y2} = selectionRectangleProps.positions;

    // update selectionRectangle
    selectionRectangleNode.value.setAttrs({
      x: Math.min(x1, x2),
      y: Math.min(y1, y2),
      width: Math.abs(x2 - x1),
      height: Math.abs(y2 - y1),
    });
  },

  onMouseUp(e: any) {
    // do nothing if we didn't start selection
    // logCustom({"selectionRectangleNode.value.visible": selectionRectangleNode.value.visible(), "isMouseDownStartedOnStage": isMouseDownStartedOnStage.value}, {suffix: 'from onMouseUp after'})  //*
    
    const isSelectionRectangleZeroSize = 
      selectionRectangleNode.value.getAttr('width') === 0 &&
      selectionRectangleNode.value.getAttr('height') === 0;
    
    if (!selectionRectangleNode.value.visible() && !isMouseDownStartedOnStage.value) {
      return;
    }

    if (isSelectionRectangleZeroSize) {
      selectionRectangleNode.value.visible(false);
      isMouseDownStartedOnStage.value = false;
      return;
    }

    e.evt.preventDefault();
    
    selectionRectangleNode.value.visible(false);
    isMouseDownStartedOnStage.value = false;
    
    if(isDrawMode.value) {
      Object.assign(selectionRectangleDrawPositions, selectionRectangleProps.positions);
      drawModeCallback.value(selectionRectangleDrawPositions);
      drawModeCallback.value = null;
      return;
    }
    const selected = getSlideShapesIntersectingWithShape(selectionRectangleNode.value);
    
    
    const modifierPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
    addSpritesToSelection(selected, {clearPrevious: !modifierPressed})
    // log({node: transformerNode.value.getNodes(), selected}, {ns: 'from onMouseUp after'});
  },
  
  onMouseClick(e: any) {
    // logCustom({nodes: transformerNode.value.nodes()}, {suffix: "from onMouseClick before: transformerNode()"})
    
    // if we are already selecting with rect, do nothing
    if (selectionRectangleNode.value.isVisible()) {
      return;
    }

    
    // logCustom({isVisible: selectionRectangleNode.value.isVisible()}, {suffix: "from onMouseClick"})
    // logCustom({'e.target': e.target}, {suffix: "from onMouseClick"})
    
    // logCustom({nodes: transformerNode.value.nodes()}, {suffix: "from onMouseClick before: transformerNode()"}
    
    // logCustom({testD: e.target.getClassName()});
    
    // if click on empty area, remove all selections
    // logCustom({'e.target === stageNode.value': (e.target === stageNode.value), 'e.target': e.target}, {suffix: "from onMouseClick"});

    if (e.target === stageNode.value) {
      clearSpritesSelection();
      return;
    }

    
    // if clicked on shape that doesn't belong to the "slide-main" layer (e.g. may be the transformer
    // or something else), then do nothing
    if ( !isNodeASlideSprite(e.target)) {
      return;
    }

    // If clicked on a shape that belongs to "slide-main" layer
    // do we pressed shift or ctrl?
    const modifierPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
    const isAlreadySelected = transformerNode.value.nodes().indexOf(e.target) >= 0;    // clicked on shape / node is already selected
    // logCustom({transformerNode, nodes: transformerNode.value.nodes(), isSelected}, {suffix: "from onMouseClick"})

    if (!modifierPressed && !isAlreadySelected) {
      // if no key pressed and the node is not selected
      // select just one
      addSpriteToSelection(e.target, {clearPrevious: true})
    } else if (modifierPressed && isAlreadySelected) {
      // if we pressed keys and node was selected
      // we need to remove it from selection:
      removeSpriteFromSelection(e.target);
    } else if (modifierPressed && !isAlreadySelected) {
      // add the node into selection
      addSpriteToSelection(e.target);
    }
  }
}

export default selectorRectangleStageHandlers;