<template>
    <Dialog ref="previewModal" :size="'full'">
        <template #expander="{ openModal }">

            <ButtonCustomized :label="'Preview player content'" @click="loadPreview" secondary />

        </template>
        <template #dialog.title>Preview player content</template>
        <template #dialog.content="{ closeModal, closeModalAfter }">

            <div class="d-flex">

            </div>
            <!-- TODO (cleaner code): think about removing redundancy between "DeleteItemConfirmationForm" & other forms (e.g. CreateSlideForm) [DSP-N26Dui5A] -->
            <div class="preview-content ">
                <div class="md:basis-1/2">
                    <h2>
                        {{ playerName }}
                    </h2>
                    <template v-if="selectedPlayer?.Campaigns?.length==0">
                        <div class="md:basis-1">
                            No Campaign Exist
                        </div>
                        </template>
                    <template v-if="selectedPlayer?.Campaigns?.length>0">
                    
                    <div class="text-center media-src">
                        <video   class="img-preview" :id="'mediaVideo'" @loadeddata ="load()"
                            v-if="previewList?.filter(item => item.selected)[0]?.AssetType == 1">
                            <source id="videoSrc" :src="previewList?.filter(item => item.selected)[0]?.MediaContentUrl" type="video/mp4">
                            <source :src="previewList?.filter(item => item.selected)[0]?.Versions[0].PublicUrl" :type="previewList?.filter(item => item.selected)[0]?.Versions[0].Encoding">
                            <source :src="previewList?.filter(item => item.selected)[0]?.Versions[1].PublicUrl" :type="previewList?.filter(item => item.selected)[0]?.Versions[1].Encoding">

                        </video>

                        <img v-if="previewList?.filter(item => item.selected)[0]?.AssetType != 1"
                            :src="previewList?.filter(item => item.selected)[0]?.MediaContentUrl" class="img-preview" />
                    </div>

                    <div class="d-flex media-control justify-between">
                        <div class="d-flex ">
                            <WhiteThemeIcon name="resume" v-if="isPaused" @click="resumeTimer"></WhiteThemeIcon>
                            <WhiteThemeIcon name="pause" v-if="!isPaused" @click="pauseTimer"> </WhiteThemeIcon>
                            <p class="mx-2"> {{
                                formateTime(previewList?.filter(item => item.selected)[0]?.currentDuration)
                            }}
                                / {{ formateTime(previewList?.filter(item => item.selected)[0]?.MediaContentDuration) }}
                            </p>
                        </div>
                        <div class="d-flex" v-if="previewList?.filter(item => item.selected)[0]?.AssetType == 1">

                           
                            <input type="range" :value="videoTime()" min="0" max="100" step="1"  class="seek" @input="setDuration($event)" @change="setDuration($event)">
                            <WhiteThemeIcon name="speaker" v-if="videoVolume>0"></WhiteThemeIcon>
                            <WhiteThemeIcon name="muted" v-if="videoVolume==0"></WhiteThemeIcon>
                            <input type="range" min="0" max="100" step="1" class="volume" @input="SetVolume($event)" @change="SetVolume($event)">

                        </div>
                        <div class="d-flex">
                            <WhiteThemeIcon name="bullet-start" @click="previousPlaylist"></WhiteThemeIcon>
                            <WhiteThemeIcon name="bullet-end" @click="nextPlaylist"></WhiteThemeIcon>
                        </div>


                    </div>

                    <h2>{{ previewList?.filter(item => item.selected)[0]?.Title }}</h2>
                    <div class="d-flex mt-4">
                        <div class="md:basis-1/3">
                            <h4>Duration</h4>
                            <p> {{ formateTime(previewList?.filter(item => item.selected)[0]?.MediaContentDuration) }}</p>
                        </div>
                        <div class="md:basis-1/3">
                            <h4>Sharing</h4>
                            <p> {{ SharingLevel [previewList?.filter(item => item.selected)[0]?.SharingLevel ]}} </p>
                        </div>
                        <div class="md:basis-1/3">
                            <h4>Approval</h4>
                            <p> {{ previewList?.filter(item => item.selected)[0]?.ApprovalState ==0 ? 'Not approved' : 'Approved' }} </p>
                        </div>
                    </div>
                </template>
                </div>
                <div class="md:basis-1/2">
                    <div class="d-flex mb-2 items-center">
                        <div class="mx-2">
                            <input class="" v-model="previewType" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                                :value="0">
                            <label class="ml-1" for="inlineRadio1">Now</label>
                        </div>
                        <div class="">
                            <input class="" v-model="previewType" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                                :value="1">
                            <label class="ml-1" for="inlineRadio2">Specific Day & Time</label>
                        </div>
                        <div class="ml-2" v-if="previewType == 1">
                            <input type="datetime-local" id="meeting-time" v-model="datePreview" name="meeting-time"
                                :min="minDate">
                        </div>
                        <div class="mx-2">
                            <ButtonCustomized :label="'Search'" @click="searchPreview()"></ButtonCustomized>
                        </div>
                    </div>
                    <template v-if="selectedPlayer?.Campaigns">
                        <div v-for=" campaign of selectedPlayer?.Campaigns">
                            <div class="d-flex campaign-list">
                                <h4 class="playlist-name">{{ campaign.Name }} </h4>
                                <div class="d-flex">
                                    <p class="tag mr-2">Campaign</p>
                                    <span class="tag mr-2"> {{ campaign.PlayLists.length }}</span>
                                    <LeavePage v-if="user.hasReadAccessToArea('Campaigns')" :label="'campaign'" @save="editCampaign(campaign.Id)"></LeavePage>
                                    <a v-else title="Locked">
                                        <OldThemeIcon name='lock' />
                                    </a>
                                </div>
                            </div>
                            <template v-for="playlist of campaign?.PlayLists">
                                <div class="d-flex playlist-header">
                                    <OldThemeIcon name="bullet-down" @click="playlist.selected = false" class="icon mx-2"
                                        v-if="playlist.selected" />
                                    <OldThemeIcon name="bullet-right" @click="playlist.selected = true" class="icon"
                                        v-if="!playlist.selected" />
                                    <h4 class="playlist-name">

                                        {{ playlist.Name }} </h4>
                                    <div class="d-flex">
                                        <p class="playlist-tag  mr-2">Playlist</p>
                                        <span class="playlist-tag  mr-2"> {{ playlist?.Items.length }}</span>
                                        <LeavePage v-if="user.hasReadAccessToArea('Playlists')" :label="'playlist'" @save="editPlayist(playlist.Id)"> </LeavePage>
                                        <a v-else title="Locked">
                                            <OldThemeIcon name='lock' />
                                        </a>
                                    </div>
                                </div>
                                <template v-if="playlist.selected == true">
                                    <div class="d-flex slide-list" v-for="item of playlist.Items">
                                        <div class="d-flex items-center" @click="selectItem(item.Guid,playlist.Id)">
                                            <div class="image-thumb" @click="false" :class="{ 'active': item.selected }">
                                                <img :src="getPreviewUrl(item.Description)">
                                            </div>



                                            <!-- <img :src="item.MediaContentUrl" class="image-thumb mr-2 "/>  -->
                                            <span class="playlist-name ml-2">{{ item.Title }}</span>
                                        </div>
                                        <div class="d-flex mt-3">
                                            <p class="mr-5">{{ item.AssetType == 0 ? 'Image' : (item.AssetType == 1
                                                ? 'Video' : 'Slide') }}</p>
                                            
                                            <LeavePage v-if="user.hasReadAccessToArea('MediaLibrary')" :label="'media'" @save="editMedia(item.Id)"> </LeavePage>
                                            <a v-else title="Locked">
                                                <OldThemeIcon name='lock' />
                                            </a>
                                        </div>
                                    </div>
                                </template>

                            </template>

                        </div>
                    </template>

              
                </div>
              
            </div>
          

        </template>
    </Dialog>
</template>
    
<script lang="ts" setup>
import Dialog from '../../../components/Dialog/Dialog.vue';
import ButtonCustomized from "../../../components/form-fields/ButtonCustomized.vue";
import OldThemeIcon from '@/components/icons/OldThemeIcon.vue';
import usePlayerPreviewForm from './PlayerPreviewHelper';
import useUser from '@/composables/user';
import { computed, ref } from 'vue';
import WhiteThemeIcon from '@/components/icons/WhiteThemeIcon.vue'
import router from '@/router';
import LeavePage  from '@/components/Dialog/LeavePageDialog/LeavePageDialog.vue'
const videoVolume = ref (0.4)
const emit = defineEmits(['cancel', 'save', 'exit', 'fail'])
const props = defineProps(['playerId', 'playerName'])
const { loadPlayerPreview, selectedPlayer, selectItem, previewList,
    formateTime, isPaused, pauseTimer, resumeTimer, datePreview,
    nextPlaylist,getDatePerview,media,videoSource,
    previousPlaylist ,SharingLevel,playVideo,changeCurrentVideoTime } = usePlayerPreviewForm();
const user = useUser();
const previewModal = ref();
const previewType = ref(0)
const minDate = new Date().toISOString().slice(0, new Date().toISOString().lastIndexOf(":"));
function loadPreview() {
    loadPlayerPreview(props.playerId)
    previewModal.value.openModal()
}
async function onSave() {
    emit('save');
}
function searchPreview() {
 if (previewType.value==0)
 {
    loadPlayerPreview(props.playerId)
 }else {
    getDatePerview();
 }
}
function load (){
   media.value = document.getElementById('mediaVideo') as any ;
   videoSource.value = document.getElementById('videoSrc') as any ;
   videoVolume.value= 0.4
   media.value.currentTime =0
   playVideo () ; 
}
function SetVolume(event : any )
    {
        media.value.volume = event.target.value / 100;
        videoVolume.value= event.target.value / 100;
    }
    function setDuration (event : any){
        media.value.pause();
        let duration  = (event.target.value * media.value.duration ) / 100;
        media.value.currentTime = Number(duration.toFixed(0))
        changeCurrentVideoTime(Number(duration.toFixed(0)))
    }
    function videoTime(){
        if (media.value)
        { 
           return( (media.value.currentTime * 100)  / media.value.duration)
        }
        else return 0 ;
    }
function editCampaign(id:any){
    router.push({name: 'campaign-editor', params: {id:id}});

}
function editPlayist(id:any){
    router.push({name: 'playlist-editor', params: {id: id}});

}
function editMedia(id:any){
    router.push({name: 'media-editor', params: {id: id}});

}
function getPreviewUrl(descriptionContent: string) {
    const regex = /(?<=src=")[^"]*(?=")/;
    const previewUrl = descriptionContent.match(regex) as string;
    return previewUrl;
}
    </script>
    
<style lang="scss" scoped>
.seek {
    width: 100px;
}
.volume{
    width:50px
}
.media-control {
    background: #2a2a2a;
    box-sizing: border-box;
    border-radius: 3px;
    height: 30px;
    padding: 8px;
    -moz-box-sizing: border-box;
    opacity: 1;
    width: 332px;
    text-align: left;
    margin: auto;
    color: white
}

.img-preview {

    max-width: 578px;
    max-height: 350px;
}

.media-src {
    height: 350px;
}

.preview-content {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    overflow-x: auto;
    height: 80%;

    @include medium-devices-down {
        flex-direction: column;
    }
}

.active {
    border: 1px solid rgb(33, 109, 213)
}

.image-thumb {
    :deep(img).active {

        border: 1px solid rgb(33, 109, 213)
    }
}

.campaign-list {
    width: 100%;
    background-color: blue;
    color: white;
    justify-content: space-between;
    padding: 7px;
}

.playlist-name {
    white-space: nowrap;
    width: 74%;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: start;
}

.playlist-header {
    background-color: #e0e0e0;
    color: #7A7A7A;
    padding: 7px;

    text-align: right;
    font-size: 14px;

    cursor: default;
    justify-content: space-between;

}

.tag {
    background-color: gray;
    padding-left: 8px;
    padding-right: 8px
}

.image-thumb {
    :deep(img) {

        border: 1px solid rgb(136, 136, 136);
        width: 40px;
        height: 40px;

    }
}

.image-thumb {
    :deep(a) {

        display: hidden;

    }
}




.playlist-tag {
    background-color: #7F7F7F;
    color: white;
    padding-left: 8px;
    padding-right: 8px
}

.slide-list {
    border-bottom: 1px solid lightgray;
    padding: 10px;
    justify-content: space-between;

}

.button {
    cursor: pointer;
}

.delete-item-form {
    display: flex;
    flex-direction: column;
    max-width: 30rem;
}

input[type='datetime-local'] {
    color: #666666;
    border: 1px solid #abadb3;
    height: 2.2rem;
    padding: 2px 2px 1px 2px;

    @include small-devices-down {
        width: auto;
        flex-grow: 1;
    }

    outline:none;
}

.form-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
    margin-top: 1rem;
}</style>