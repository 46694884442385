import useMediaListHelpers from '../../../../MediaLister/mediaListHelpers';
import { computed, onMounted, ref, watchEffect } from "vue";
import { useForm } from "vee-validate";
import * as yup from 'yup';
import {
  dimensionsOptionsConfig,
  orientationOptionsConfig,
} from "../config/options";
import { submitCreateMediaItem } from "@/helpers/api/media/create";
// TODO: think about moving mediaList to a separate more generic path outside the view component [DSP-N23FbmD9]
// Less preferred but needs to be thought about

export default function useCreateSlideFormHelpers() {
  // TODO: think about creating custom form handler that may be more cleaner or readable / easier to use than Vee-Validate [DSP-N23Gxg1I]
  const { mutateList } = useMediaListHelpers();
  const isLoading = ref(false);
  const isSuccess = ref(false);
  const isError = ref(false);
  const errorMessage = ref("");
  const newSlideId = ref("");

  const validationSchema = yup.object({
    name: yup.string().label('Name').required(),
    width: yup.number().label('Width').required(),
    height: yup.number().label('Height').required(),
    backgroundColor: yup.string().label('Background Color').required(),
    dimensions: yup.string().label('Dimensions'),
    orientation:  yup.string().label('Orientation')
  });

  const initialValues = {
    name: "",
    width: "",
    height: "",
    backgroundColor: "#FFFFFF",
    dimensions: dimensionsOptionsConfig[0].items[0].value,
    orientation: orientationOptionsConfig[0].value
  };

  const { errors, handleSubmit, values, setValues, isSubmitting } = useForm({ validationSchema, initialValues });

  const fieldsToSubmit = computed(() => {
    const { name, width, height, backgroundColor, orientation } = values;
    return {
      name,
      width: orientation === "portrait" ? height : width,
      height: orientation === "portrait" ? width : height,
      backgroundColor,
      assetType: "slide",
    };
  });

  watchEffect(() => {
    onDimensionsChange();
  });
  function onDimensionsChange() {
    upadeWidthAndHeightFieldsFromDimensionsField();
  }
  onMounted(() => {
    upadeWidthAndHeightFieldsFromDimensionsField();
  });
  function upadeWidthAndHeightFieldsFromDimensionsField() {
    // match & update width
    const widthMatchRegex = /\d*(?= x)/g;
    const foundWidthMatch = values.dimensions.match(widthMatchRegex);
    const widthFound = foundWidthMatch ? foundWidthMatch[0] : "";
    values.width = widthFound;
    // match height
    const heightMatchregex = /(?<=x )\d*/g;
    const foundHeightMatch = values.dimensions.match(heightMatchregex);
    const heightFound = foundHeightMatch ? foundHeightMatch[0] : "";
    values.height = heightFound;
  }
  const submitForm = handleSubmit(handleValidFormSubmit, handleInvalidFormSubmit);

  async function handleValidFormSubmit() {
    try {
      errorMessage.value = "";
      isLoading.value = true;
      const submissionResponse = await submitCreateMediaItem(
        fieldsToSubmit.value
      );
      isLoading.value = false;
      if (typeof submissionResponse[0].name === "string") {
        // if is successful
        isSuccess.value = true;
        newSlideId.value = submissionResponse[0].id;
        mutateList();
      }
    } catch (error) {
      isError.value = true;
      isLoading.value = false;
      errorMessage.value = `Something went wrong. (${error})`;
    }
  }

  async function handleInvalidFormSubmit() {
    let errorsAugmentedInSingleMessage = "";
    // TODO (cleaner code): think if there's a cleaner way to write this [DSP-N26Cti4A]
    if(Object.keys(errors.value).length){
      Object.keys(errors.value).forEach((key) => {
        errorsAugmentedInSingleMessage += errors.value[key]+". ";
      });
    }
    errorMessage.value = errorsAugmentedInSingleMessage;
  }

  return {
    values,
    submitForm,
    isError,
    isSuccess,
    isLoading,
    errorMessage,
    newSlideId
  };
}
