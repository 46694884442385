<template>
<!--
  TODO: Think about separating the styling of this to be a headless component (unstyled) to be reusable in other projects [DSP-N212np0W]
-->
<thead class="table-headers">
  <tr>
    <th v-if="config?.isCheckmarkColumn">
      <TableBulkSelectBox />
    </th>
    <th
      v-for="(column, index) in columns"
      :class="[{'sortable': column.isSortable, 'active-sort': column.columnName === sortByColumnName}]"
      v-on="{click: column.isSortable ? ()=> {setSortBy(column.columnName)} : null}"
    >
      <div class="column-label">{{ column?.name }}</div>
      <OldThemeIcon
        v-if="column.isSortable && column.columnName === sortByColumnName"
        :name="iconName"
        class="icon"
      />
    </th>
    <th>
      <!-- Item actions table head -->
    </th>
  </tr>
</thead>
</template>

<script lang="ts" setup>
import OldThemeIcon from '@/components/icons/OldThemeIcon.vue';
import TableBulkSelectBox from './TableSelectBox/TableBulkSelectBox.vue';
import { computed, inject } from 'vue';


// TODO: figure out how to assign "Config" interface to the "config" component's prop type — N2HMA0DW
interface Config {
  isCheckmarkColumn: boolean;
};

const props = defineProps({
  'columns': {
    type: [Object],
  },
  'sortByColumnName': {
    type: String,
  },
  'isDescending': {
    type: Boolean,
  },
  'config': {
    type: Object
  }
})

const emit = defineEmits(['update:sortByColumnName', 'update:isDescending']);
function setSortBy(fieldName: string) {
  if (props.sortByColumnName === fieldName) {
    emit('update:isDescending', !props.isDescending);
  } else {
    emit('update:sortByColumnName', fieldName);
    emit('update:isDescending', true);
  }
}
const iconName = computed(() => {
  if(props.isDescending) {
    return 'bullet-down';
  } else {
    return 'bullet-up';
  }
})
</script>

<style lang="scss" scoped>
.column-label {
  display: inline-block;
  vertical-align: middle;
}
.icon {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}

thead.table-headers {
  position: sticky;
  top: -2px;
  background-color: white;
  box-shadow: 0px 3px 17px rgb(0 0 0 / 16%);
  z-index: 1;
  border: 0;
  height: 2.6em;
}

.table-headers th {
  text-align: left;
  padding-right: 5px;
  padding-left: 12px;
  padding-top: 0.3rem;;
  user-select: none;
  &.sortable {
    cursor: pointer;
    &:hover {
      color: #0653b6;
    }
  }
  &.active-sort {
    /* TODO: test this on different browsers & think about if there's a better styling strategy [DSP-N21EIj9m] */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.3rem;
  }
}
</style>