<template>
  <div class="items-deletion-bar flex flex-row">
    <div class="w-1/2 flex flew-row justify-end mb-4">
      <div
        :class='[
          "delete-zone",
          {
            "dragging-playlist-item": draggingPlaylistItem,
            "dragging-playlist-item-over-delete-zone": draggingPlaylistItemOverDeleteZone
          }
        ]'
        @dragover="deleteZoneDragOverHandler"
        @dragleave="deleteZoneDragLeaveHandler"
        @drop="deleteZoneDropHandler"
      >
        <OldThemeIcon name="trash" />
      </div>
    </div>
    <div @click="clearPlaylistItems" class="clear-playlist-button">Clear Playlist</div>
  </div>
  <div class="playlist-edit-area">
    <draggable class="draggable-list" 
      :list="playlistData.items as any" 
      group="media"
      @start="draggingPlaylistItem = true" 
      @end="draggingPlaylistItem = false"
      item-key="itemKey"
      easing="cubic-bezier(1, 0, 0, 1)"
      @add="onDragAdd"
      @move="onDragMove"
      ghostClass="draggable-playlist-item-ghost"
    >
      <template #item="{element}">
        <WithContextMenu
          @dragstart="playlistItemDragStartHandler($event, element)"
          @dragend="playlistItemDragEndHandler"
        >
        <!-- <WithContextMenu :options="options"  v-slot="{activateCtxMenu}"> -->
          <template #activator="{openCtxMenu}">
            <PlaylistMediaItem
              :preview-url="element?.asset?.previewUri"
              :item="element"
              @contextmenu.prevent="openCtxMenu"
            />
          </template>
          <template #options="{closeCtxMenu}">
            <Dialog size="full">
              <template #expander2="{openModal}">
                <div
                  class="ctx-menu-option"
                  @click="doMany([openModal, closeCtxMenu])"
                >
                  <OldThemeIcon name="eye" /> Preview
                </div>
              </template>
              <template #dialog.title>Preview</template>
              <template #dialog.content>
                <MediaItemPreview :item="element?.asset" />
              </template>
            </Dialog>
            <Dialog>
              <template #expander2="{openModal}">
                <div class="ctx-menu-option" @click="doMany([openModal, closeCtxMenu])"><OldThemeIcon name="page" /> Properties</div>
              </template>
              <template #dialog.title>Properties</template>
              <template #dialog.content="{closeModal, closeModalAfter}">
                <!-- TODO: think about if this is the best way for reusability & readbility of the code to pass closeModal and have reusable form modals [DSP-N23FGHEM] -->
                <!-- TODO: think about if we can make a more generic form out of this to be easily reusable in other forms [DSP-N262AbAq] -->
                <EditPlaylistItemPropertiesForm
                  :item="element"
                  :defaultDuration="playlistData.defaultItemDuration"
                  @cancel="closeModal"
                  @submit="(duration) => {
                    log(duration, {ns: 'EditPlaylistItemPropertiesForm', color: 'purple'})
                    doMany([updatePlaylistItemDuration.bind(null, element?.asset.id, duration), closeModal])
                  }"
                />
              </template>
            </Dialog>
            <div
              class="ctx-menu-option"
              @click="doMany([removePlaylistItem.bind(null, element.asset.id), closeCtxMenu])"
            >
              <OldThemeIcon name="trash" /> Remove
            </div>
            <!-- /media/edit/ -->
            <RouterLink
              :to="'/media/edit/'+element.asset.id"
              tag="div"
              class="ctx-menu-option"
              component="div"
              @click.stop
            >
              <OldThemeIcon name="edit" /> Edit
            </RouterLink>
          </template>
        </WithContextMenu>
      </template>
    </draggable>
  </div>
</template>

<script lang="ts" setup>
import PlaylistMediaItem from './PlaylistMediaItem.vue';
import EditPlaylistItemPropertiesForm from './EditPlaylistItemPropertiesForm/EditPlaylistItemPropertiesForm.vue';
import MediaItemPreview from '@/components/ListingPages/ItemsLister/MediaItemPreview/MediaItemPreview.vue';

import OldThemeIcon from '@/components/icons/OldThemeIcon.vue';
import WithContextMenu from '@/components/WithContextMenu/WithContextMenu.vue';
import Dialog from '@/components/Dialog/Dialog.vue';

import { doMany } from '@/helpers/utils';

import { reactive, ref } from 'vue';
import draggable from 'vuedraggable'
import usePlaylist from '../composables/playlist';
import { log } from '@/helpers/utils';

const { data: playlistData } = usePlaylist();

function clearPlaylistItems() {
  playlistData.items.splice(0, playlistData.items.length);
}

function removePlaylistItem(assetId) {
  const itemToRemoveIndex = playlistData.items.findIndex(item => item.asset.id.toString() === assetId.toString());
  playlistData.items.splice(itemToRemoveIndex, 1);
}

function updatePlaylistItemDuration(assetId, duration) {
  const itemToUpdateIndex = playlistData.items.findIndex(item => item.asset.id.toString() === assetId.toString());
  playlistData.items[itemToUpdateIndex].duration = duration; // TODO: get the value from the form [DSP-N23FGHEM
}


// playlist item drag helpers ---------------------------- START
const draggingPlaylistItem = ref(false);
function playlistItemDragStartHandler(event: any, item: any) {
  draggingPlaylistItem.value = true;
  event.dataTransfer.setData("asset-id", item.asset.id);
  // log('dragging started', {ns: 'playlistItemDragStartHandler', color: 'purple'});
  // event.dataTransfer.setDragImage(new Image(), 0, 0);
}

function playlistItemDragEndHandler() {
}

function onDragAdd(event: any) {
  log({item: event.item}, {ns: 'onDragAdd', color: 'purple'});
}

function onDragMove(event: any) {
  log({item: event.dragged}, {ns: 'onDragMove', color: 'purple'});
}
// playlist item drag helpers ---------------------------- END


// delete zone helpers ---------------------------- START
const draggingPlaylistItemOverDeleteZone = ref(false);
function deleteZoneDragOverHandler(event: any) {
  // alert('drag over')
  event.preventDefault();
  event.dataTransfer.dropEffect = "move";
  draggingPlaylistItemOverDeleteZone.value = true;
}

function deleteZoneDragLeaveHandler(event: any) {
  event.preventDefault();
  draggingPlaylistItemOverDeleteZone.value = false;
}

function deleteZoneDropHandler(event: any) {
  event.preventDefault();
  const assetId = event.dataTransfer.getData("asset-id");
  console.log({assetId});
  removePlaylistItem(assetId);
  draggingPlaylistItemOverDeleteZone.value = false;
}
// delete zone helpers ---------------------------- END

</script>

<style lang="scss" scoped>

.draggable-list {
  display: flex;
  flex-wrap: wrap;
}
.playlist-edit-area {
  background-color: #6e6e6e;
  min-height: 120px;
  padding: 15px;
}

.items-deletion-bar {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.clear-playlist-button {
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.delete-zone {
  width: 4rem;
  display: flex;
  justify-content: center;
  border-left: 2px solid #e6e6e6;
  border-right: 2px solid #e6e6e6;
  justify-self: end;
  transition: all 0.2s ease-in-out;
  &.dragging-playlist-item {
    border: 0.375rem dashed rgba(255, 0, 0, 0.5);
    padding: 0.375rem;
    border-radius: 0.375rem;
    height: 3rem;
    align-items: center;
    &.dragging-playlist-item-over-delete-zone {
      border: 0.375rem dashed rgba(255, 0, 0, 1);
      padding: 0.75rem;
      background-color: rgba(255, 0, 0, 0.5);
      width: 5rem;
      height: 4rem;
    }
  }
  
}

:deep(.draggable-playlist-item-ghost) {
  /* background-color: red; */
  &.list-item-custom {
    td {
      display: none;
    }
    .drag-component-cell {
      display: block;
      .playlist-item {
        border: 0.375rem dashed white;
        padding: 0;
        margin: 0;
      }
    }
  }
  .playlist-item {
    border: 0.375rem dashed white;
    padding: 0;
    margin: 0;
  }
}
</style>