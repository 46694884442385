<template>

  <div v-if="(value != 'All') && tagCategory">
    <DeleteTag :value="tagValue" :category="tagCategory" />
  </div>
</template>

<script lang="ts" setup>
import { defineProps } from 'vue';
import DeleteTag from './DeleteTag.vue';
const props = defineProps<{
  value: string
}>();


const [tagValue, tagCategory] = props.value.split('\0');



</script>