<template>
<div class="grid-item-skeleton">
  <div class="thumbnail-image-container-skeleton">
    <div class="thumbnail-image-skeleton" ref="imageSkeletonContainerRef">
      <loading-skeleton :containerRef="imageSkeletonContainerRef" />
    </div>
  </div>
  <div class="item-name-skeleton" ref="nameSkeletonContainerRef">
    <loading-skeleton :containerRef="nameSkeletonContainerRef" />
  </div>
  <div class="item-actions-skeleton" ref="actionsSkeletonContainerRef">
    <loading-skeleton :containerRef="actionsSkeletonContainerRef" />
  </div>
</div>

</template>

<script lang="ts" setup>
import { ref } from 'vue';

const imageSkeletonContainerRef = ref<HTMLDivElement>();
const nameSkeletonContainerRef = ref<HTMLDivElement>();
const actionsSkeletonContainerRef = ref<HTMLDivElement>();

</script>

<style lang="scss" scoped>



.name-and-type-skeletons-container {
  display: flex;
  flex-direction: column;
}

/* TODO: think if it's a good idea to de-duplicate redundancy with (ItemsGridList) — N29EjV7e */
.grid-item-skeleton {
  width: 10rem;
  height: 10rem;
  @include small-devices-down {
    width: 90vw;
    height: 90vw;
  }
  border-radius: 0.375rem;
  /* background-color: grey; */
  border: 1px solid rgb(136 136 136 / 41%);
  @include small-devices-up {
    border: 5px solid rgb(255 255 255 / 0%);    
    transition: border 0.2s ease-out;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item-name-skeleton {
  width: 90%;
  height: 1.5rem;
}


.item-actions-skeleton {
  margin-top: 0.375rem;
  width: 50%;
  height: 1.75rem;
}


.thumbnail-image-container-skeleton {
  height: 64%;
  @include small-devices-down {
    height: 83%;
  }
  width: 100%;
  padding: 0.375rem;
  .thumbnail-image-skeleton {
    height: 100%;
    width: 100%;
  }
}
</style>