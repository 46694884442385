<template>
  <div class="p-4 flex flex-col gap-2">
    <SelectField
      v-if="user.isAuditor()"
      :options="approvalStateOptions"
      v-model="basicInfo.approvalState"
      label="Approval"
      label-class-name="basis-1/2"
      field-class-name="basis-1/2"
    />
    <InfoField
      v-else
      label="Approval"
      :content="approvalStateLabel"
      label-class-name="basis-1/2"
      content-class-name="basis-1/2"
    />
    <InfoField
      label="Last Audited By"
      label-class-name="basis-1/2"
      field-class-name="basis-1/2"
    />
    <InfoField
      label="Last Audited On"
      label-class-name="basis-1/2"
      field-class-name="basis-1/2"
    />
  </div>
</template>

<script lang="ts" setup>
import SelectField from '@/components/form-fields/SelectField/SelectField.vue';
import InfoField from '@/components/form-fields/InfoField/InfoField.vue';

import useSlide from '@/views/MediaEditorView/SlideEditor/composables/slide';

import { approvalStateOptions } from '@/data/options/approvalStateOptions'
import { computed } from 'vue';

import useUser from '@/composables/user';
const user = useUser();

const { basicInfo } = useSlide();
const approvalStateLabel = computed(()=> {
  const matchedApprovalState = approvalStateOptions.find(option => option.value == basicInfo.approvalState) as (typeof approvalStateOptions)[number];
  return matchedApprovalState.label;
})
</script>

<style lang="scss" scoped>
</style>