<template>
  <div v-if="mobile" class="relative">
    <div class="pane-title">
      <template v-if="entityItemsNamePlural">
        {{ entityItemsNamePlural }} List Filters
      </template>
    </div>
    <div class="status-bar">
      <StatusBar :isLoading="isLoading || isValidating" />
    </div>
  </div>
  <!-- TODO (UI / UX improvement): add loading skeleton for filters pane when page is initializing (use "isMediaListInitialized")-->
  <div :class='["filters-pane", "w-60"]'>
    <!-- TODO (UI / UX improvement): make "SearchBar" support "model" so both "mobile view" & "desktop view" are linked -->
    <SearchBar @textChange="onSearchTextChange" />

    <!-- TODO TO Confinue later — N2REQu5j - START -->
    <!-- {{ filtersConfigSectioned }}
    <template v-for="filtersConfigSection in filtersConfigSectioned">
      <template v-if="filtersConfigSection?.[0]?.type === 'collapsibleSelect'">
        <div
          :class='["filter-option-section", { "separated": filtersConfigSection?.[0]?.isSeparatedAbove }]'
        >
          <template v-for="filterConfig in filtersConfigSection">
            <template v-if='filtersConfig.type === "collapsibleSelect"'>
              <Collapsible
                :title="filterConfig.label"
              >
                <SelectExpandedField
                  v-model="filters[filterConfig.name]"
                  :options="filterConfig.options"
                  labelKey="name"
                  value-key="data"
                  :vertical="true"
                />
              </Collapsible>
            </template>
          </template>
        </div>
      </template>
      <template v-else>
        <div
          v-for="filterConfig in filtersConfigSection"
          :class='["filter-option-section", { "separated": filterConfig?.isSeparatedAbove }]'
        >
          <div class="filter-option-label">{{ filterConfig.label }}</div>
          <SelectExpandedField
            v-model="filters[filterConfig.name]"
            :options="filterConfig.options"
            labelKey="name"
            value-key="data"
          />
        </div>
      </template>
    </template> -->
    <!-- TODO TO Confinue later — N2REQu5j - END -->

    <!-- TODO: TO Remove after "N2REQu5j" is done - START -->
    <div
      v-for="filterConfig in filtersConfig"
      :class='["filter-option-section", { "separated": filterConfig?.isSeparatedAbove }]'
    >
      <template v-if='filterConfig?.type === "collapsibleSelect"'>
        <Collapsible
          :title="filterConfig.label"
        >
          <SelectExpandedField v-model="filters[filterConfig.name]" :options="filterConfig.options" label-key="name"
            value-key="data" :vertical="true" :OptionsExtraButtons="MetaDataOptionsComponent" />
        </Collapsible>
      </template>
      <template v-else-if='filterConfig?.type === "selectMenu"'>
        <SelectField
          :options="filterConfig.options"
          v-model="filters[filterConfig.name]"
          label-key="name"
          value-key="data"
        />
      </template>
      <template v-else-if='filterConfig != null'>
        <div class="filter-option-label">{{ filterConfig.label }}</div>
        <SelectExpandedField
          v-model="filters[filterConfig.name]"
          :options="filterConfig.options"
          label-key="name"
          value-key="data"
        />
      </template>
    </div>
    <!-- TODO: TO Remove after "N2REQu5j" is done - START -->
    <!-- <div
      class="filter-option-section"
      v-for="filterConfig in filtersConfig.slice(0, filtersConfig.length-2)"
    > -->
      <!--
        TODO: Think about separating filter-option-section to a separate component [DSP-FE-N1VDHA60]
          - TODO: also think about having an array of filter options and looping through them in order to make this component more generic and more reusable for other lists (other than media) [DSP-FE-N1VHLs0W]
            - current thoughts: this needs to be generalized in order to be reusable for other lists (other than media) (this will make the process creating other lists very easy and smooth)
            - be careful if parts of this refactoring may make something more complex unneccessarily.
      -->
      <!-- <div class="filter-option-label">{{ filterConfig.label }}</div>
      <SelectExpandedField
        v-model="filters[filterConfig.name]"
        :options="filterConfig.options"
        labelKey="name"
        value-key="data"
      />
    </div> -->
    <!-- TODO: think about a more readable clean way for adding a visual separator [DSP-N1VKhnBS] -->
    <!-- <div class="filter-option-section separated">
      <Collapsible
        v-for="filterConfig in filtersConfig.slice(filtersConfig.length-2)"
        :title="filterConfig.label"
      >
        <SelectExpandedField
          v-model="filters[filterConfig.name]"
          :options="filterConfig.options"
          labelKey="name"
          value-key="data"
          :vertical="true"
        />
      </Collapsible>
    </div> -->
    <!-- TO REMOVE - END -->
  </div>
</template>
<script lang="ts" setup>
import SearchBar from '@/components/form-fields/SearchBar/SearchBar.vue';
import SelectExpandedField from '@/components/form-fields/SelectExpandedField/SelectExpandedField.vue';
import Collapsible from '@/components/Collapsible/Collapsible.vue';
import { computed, inject, ref } from 'vue';
import StatusBar from '@/components/form-fields/StatusBar/StatusBar.vue';
import SelectField from "@/components/form-fields/SelectField/SelectField.vue";

defineProps([
  'mobile'    // for showing the mobile version of the component "filters pane"
]);

const itemsListHelpers = inject('itemsListHelpers') as any
const listerConfig = inject('listerConfig') as any

const { MetaDataOptionsComponent } = listerConfig;
const {
  searchTerm,
  isLoading,
  isValidating,
  filters,
  filtersConfig,
  entityItemsNamePlural,
  entityItemName
} = itemsListHelpers;

function onSearchTextChange(text: string) {
  searchTerm.value = text;
}

const filtersConfigSectioned = computed(() => {
  const filtersConfigSectioned_prepared = [];
  filtersConfigSectioned_prepared.push([]);   // add first section
  let currentSectionIndex = 0;
  let currentSection = filtersConfigSectioned_prepared[currentSectionIndex];

  filtersConfig.forEach((filterConfig, index) => {
    if(!filterConfig?.isSeparatedAbove) {
      currentSection.push(filterConfig);
    } else {
      filtersConfigSectioned_prepared.push([]);   // add new section
      currentSectionIndex++;    // increment current section index
      currentSection = filtersConfigSectioned_prepared[currentSectionIndex];
      currentSection.push(filterConfig);
    }
  })
  return filtersConfigSectioned_prepared;
})

</script>
<style lang="scss" scoped>
.pane-title {
  font-size: 1.4rem;
}
.filters-pane {
  /* background-color: rgb(128 128 128 / 4%); */
}

.filter-option-label {
  font-weight: bold;
  margin-bottom: 0.25rem;
  font-size: 1rem;
}

.filter-option-section {
  margin-top: 1rem;
  margin-bottom: 1rem;

  &.separated {
    border-top: 2px solid #e6e6e6;
    padding-top: 11px;
  }
}

.status-bar {
  position: absolute;
}

</style>
