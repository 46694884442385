import useMediaListHelpers from '../../../../MediaLister/mediaListHelpers';
import {
  requestGetS3UploadUrl,
  requestUploadMediaFileToS3,
  requestAddUploadedMedia,
} from "@/helpers/api/media/upload";
import { formatBytes, log } from '@/helpers/utils';
import useNotifications from "@/components/Notifications/composables/notifications";

export default function useUploadMediaFormHelpers() {
  const { mutateList } = useMediaListHelpers();
  const notification = useNotifications();

  async function submitDirectUploadFileToS3(file: File, reactiveState: any) {
    const fileExtension = file.name.split('.').pop() as string;
    const { url: s3UploadUrl, s3ObjectKey } = await requestGetS3UploadUrl(fileExtension);

    // prepare file basic info
    reactiveState["name"] = file.name;
    reactiveState["sizeHumanReadable"] = formatBytes(file.size);
    reactiveState["instance"] = file;

    // prepare cancel method
    const controller = new AbortController();
    reactiveState["cancel"] = () => {
      controller.abort();
      reactiveState["status"] = "canceled";
      notification.normal(reactiveState["name"]+' file upload canceled!');
    }

    function onUploadProgress(progressEvent: ProgressEvent) {
      reactiveState["progressPercentage"] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      if (reactiveState["progressPercentage"] === 100) {
        reactiveState["status"] = "processing";
      } else if (reactiveState["progressPercentage"] < 100) {
        reactiveState["status"] = "uploading";
      }
    }

    const uploadToS3Response = await requestUploadMediaFileToS3(file, onUploadProgress, controller.signal, s3UploadUrl);
    if (uploadToS3Response.success !== true) {
      throw new Error("The upload to S3 was not successful.");
    }

    const saveUploadedMediaToServerResponse = await requestAddUploadedMedia(file, s3ObjectKey);

    if (saveUploadedMediaToServerResponse.success === true) {
      // if is successful
      reactiveState["status"] = "success";
      notification.success(reactiveState["name"]+' file upload succeeded!');
      mutateList();
    } else {
      // if is not successful
      handleError("Adding uploaded media was not successful.");
    }
    mutateList();

    function handleError(error: any = null) {
      console.log(error);
      log({error: error}, {ns: "ERROR", color: "red"});

      if(reactiveState["status"] === "canceled") return;
      reactiveState["status"] = "failed";
      reactiveState["error"] = `Something went wrong.`;
      // reactiveState["error"] = `Something went wrong. (${error})`;
      notification.failure(reactiveState["name"]+' file upload unsuccessful!');
      // notification.failure(reactiveState["name"]+' file upload unsuccessful!'+(error?.message ? ' ('+error.message+')' : ''));
    }
  }

  return {
    submitDirectUploadFileToS3
  };
}