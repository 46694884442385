<!-- Notes
  Side Inspirers / Helper References
    Button Drawing & Animations: https://css-tricks.com/line-animated-hamburger-menu/
 -->

<template>
  <div
    :class="['hamburger-icon', {opened: isOpen}]"
  >
    <svg width="100" height="100" viewBox="0 0 100 100" :class="{opened: isOpen}">
      <path class="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" fill="currentColor" />
      <path class="line line2" d="M 20,50 H 80" fill="currentColor" />
      <path class="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" fill="currentColor" />
    </svg>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

export interface Props {
  isOpen?: boolean
  height?: string,
  width?: string,
  openedHeight?: string,
  openedWidth?: string,
  color?: string,
  openedColor?: string,
}

const props = withDefaults(defineProps<Props>(), {
  isOpen: false,
  height: "1em",
  width: "1em",
  onOpenHeight: '',
  onOpenWidth: '',
  color: "#25294a",
  openedColor: "white",
})

const openedHeightComputed = computed(() => props.openedHeight || props.height);
const openedWidthComputed = computed(() => props.openedWidth || props.width);

</script>

<style lang="scss" scoped>
.hamburger-icon {
  background-color: transparent;
  border: none;
  display: flex;
  padding: 0;
}

.hamburger-icon svg {
  height: v-bind(height);
  width: v-bind(width);

  &.opened{
    height: v-bind(openedHeightComputed);
    width: v-bind(openedWidthComputed);
  }
  transition:
    width 300ms ease-in-out,
    height 300ms ease-in-out;
}
.line {
  fill: none;
  stroke: v-bind(color);
  stroke-width: 6;
  transition:
    stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.opened .line {
  stroke: v-bind(openedColor);
}

.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
</style>