import useSWRV from "swrv";
// import LocalStorageCache from 'swrv/dist/cache/adapters/localStorage';
import type { fetcherFn } from "swrv/dist/types";
import useUserStore from '@/stores/user';
import { computed } from "vue";

type ApiSWRKey = string | ApiSWRKeyFn;
type ApiSWRKeyFn = () => ApiSWRCompositeKeyBases | void | string;
interface ApiSWRCompositeKeyBases {
  key: string,
  sideComposite?: any
}
type CompositeKeyFn = () => string;

// const cacheLocalStorageInstance = new LocalStorageCache('swrv');

export default function useApiSWR(keyOrFn: ApiSWRKey, fetcher: fetcherFn<any>) {
  let key: string, keyFn: ApiSWRKeyFn, compositeKeyFn: CompositeKeyFn;
  const { userId, checkLogin } = useUserStore();

  if (typeof keyOrFn == 'string') {
    key = keyOrFn;
    compositeKeyFn = () => {
      const cacheKey = JSON.stringify({key, composite: {userId: userId}});
      return cacheKey;
    };
  } else if (typeof keyOrFn == 'function') {
    keyFn = keyOrFn;
    compositeKeyFn = () => {
      const cacheKey = JSON.stringify({key, composite: {userId, ...(keyFn())}});
      return cacheKey;
    };
  } else {
    throw new Error("keyOrFn must be a string or a function");
  }
  const config = {
    revalidateOnFocus: false
  }
  const {data, mutate, error, isValidating} =  useSWRV(compositeKeyFn, fetcher, config)

  const isLoading = computed(() => ((data.value === undefined && !error.value)));

  return {data, mutate, error, isValidating, isLoading};
}