import useUserStore from '@/stores/user';
import { onMounted, watchEffect } from 'vue';

export function useUser() {
  const user = useUserStore();
  onMounted(() => {user.checkLogin()});

  return user;
}

export default useUser;