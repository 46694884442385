<template>
  <Dialog>
    <template #expander="{openModal}">
      <!-- TODO (cleaner code): think about moving the margin styling (e.g. "tailwind margin styling below") to the parent component in order to make this component more reusbale (also you may do the same for similar components, e.g. "DeleteItemButtonWithDialog") [DSP-N26F7b8N] -->
      <a href="#" title="Copy">
        <OldThemeIcon
          name='copy'
          @click.stop="openModalHandler(openModal)"
          :class="['button', {disabled: disabled}]"
        />
      </a>
    </template>
    <template #dialog.title>Warning</template>
    <template #dialog.content="{closeModal, closeModalAfter}">
      <CopyItemConfirmationForm
        @cancel="closeModal"
        @success="closeModalAfter(1000)"
        v-bind="{itemId}"
      />
    </template>
  </Dialog>
</template>

<script lang="ts" setup>
import Dialog from '@/components/Dialog/Dialog.vue';
import CopyItemConfirmationForm from './CopyItemConfirmationForm/CopyItemConfirmationForm.vue';
import OldThemeIcon from '@/components/icons/OldThemeIcon.vue';

const props = defineProps(['itemId', 'disabled'])

// TODO: think about a more clean way to write the openModal function directly on component with the conditional activation of event
function openModalHandler(openModalCallback: Function) {
  if(props.disabled) return;
  openModalCallback();
}
</script>

<style lang="scss" scoped>
.button {
  cursor: pointer;
  &.disabled {
    cursor: default;
    opacity: 0.3;
  }
}
</style>