<template>
  <!-- TODO (cleaner code): think about removing redundancy between "DeleteItemConfirmationForm" & other forms (e.g. CreateSlideForm) [DSP-N26Dui5A] -->
  <div class="delete-item-form">
    <div class="confirmation-text">
      {{ isSingleItem ? 'Are you sure you want to delete this item?' : `Are you sure you want to delete these items (${lastSelectedItemsCount} selected)?` }}
    </div>
    <div class="status-bar-container">
      <StatusBar :errorMessage="errorMessage" :isLoading="/* true ||  */isLoading" loadingMessage="Deleting..."
        :isSuccess="isSuccess" :successMessage="(isSingleItem ? '1 item Deleted!' : `${lastSelectedItemsCount} items Deleted!`)" />
    </div>
    <div class="form-buttons">
      <ButtonCustomized label="Cancel" @click="onCancel" secondary />
      <ButtonCustomized label="Delete" @click="onSubmit" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import StatusBar from "@/components/form-fields/StatusBar/StatusBar.vue";
import ButtonCustomized from "@/components/form-fields/ButtonCustomized.vue";
import useNotifications from "@/components/Notifications/composables/notifications";
import useDeleteItemConfirmationFormHelpers from "./composables/deleteItemConfirmationFormHelpers";
import { computed, ref, watchEffect, inject } from "vue";
import { isDefined } from '@/helpers/utils';

// TODO (performance / cleaner code): to think about if this way of passing "itemId" for the "DeleteItemConfirmationForm" is the best for performance & code organization [DSP-N26EVh6g]
  // As we have many items (25 - 100), so make sure we don't have 25 - 100 renders and that isn't affecting performance.

const props = defineProps(['itemId', 'itemsIds'])
const emit = defineEmits(['submit', 'cancel', 'success', 'fail'])
const itemsListHelpers = inject('itemsListHelpers')

const notification = useNotifications();

const { submitDeleteAction, isLoading, isSuccess, errorMessage } = useDeleteItemConfirmationFormHelpers( itemsListHelpers );

const isSingleItem = computed(() => {     // false: means multiple items
  if(props.itemId || props.itemsIds.length === 1) {
    return true;
  } else if(props.itemsIds) {
    return false;
  }
});

const idOrIdsToSubmit = computed(() => {
  if(isSingleItem.value && !props.itemsIds) {
    return props.itemId;
  } else if (isSingleItem.value && props.itemsIds) {
    return props.itemsIds[0];
  } else {
    return props.itemsIds.join(',');
  }
});


// TODO: think about deduplicating "lastSelectedItemsCount" from here and in other similar places (e.g. CopyItemConfirmationForm) — N2GKoFE6
const lastSelectedItemsCount = ref(0);
watchEffect(() => {
  if (isDefined(props.itemsIds) && props.itemsIds.length !== 0) {
    lastSelectedItemsCount.value = props.itemsIds.length;
  }
})

async function onSubmit() {
  await submitDeleteAction(idOrIdsToSubmit.value);
  const deletionNotificationMessage = isSingleItem.value ? '1 item is deleted successfuly!' : `${lastSelectedItemsCount.value} items are deleted successfuly!`;
  if(isSuccess.value === true) {
    notification.deletion(deletionNotificationMessage);
    emit('success');
  } else {    // on failure
    emit('fail');
  }
}

function onCancel() {
  emit('cancel');
}

</script>

<style lang="scss" scoped>
.delete-item-form {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
}
.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1rem;
}
</style>