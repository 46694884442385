export default function prepareSearchQuery(
  searchTerm: string|null = null,
  queryFieldNames = ["Name"]
) {
  const searchQueryPrepared = {
    fieldNames: queryFieldNames,
    term: searchTerm
  };
  
  return searchQueryPrepared;
}