import { onMounted, reactive, ref, computed } from 'vue';
import useUser from '@/composables/user';
import useIsMobile from '@/composables/utils/isMobile';

const selectedItemsIds = reactive([] as number[]);
const selectedItems = reactive([] as any[]);

const isAlreadyMounted = ref(false);
const lastShiftKeyPressed =ref(false);
export default function useListSelection({
  clearSelectionTriggerElement = null as HTMLElement | null,
  afterAddToSelectionCallback = null as Function | null,
  afterRemoveFromSelectionCallback = null as Function | null,
  afterClearSelectionCallback = null as Function | null,
  afteraddMultipleItemsToSelectionCallback = null as Function | null,
} = {}) {
  const user = useUser();
  const isMobile = useIsMobile();
  const selectListItemClickHandler = (
    e: any,
    id: number,
    filteredList: any[],
    itemIndex: number,
    { // config
      afterSelectionActionArguments = null as any,
      isAddToSelectionWithoutMetaKey = false
    } = {}
  ) => {
    // TEMP INSPIRATION NOTE: First inspired from previously written code in "LayersPane.vue" ← {{repo::bitshuttle-frontend}}\src\components\SlideEditor\…\LayersPane.vue
    const modifierPressed = e.ctrlKey || e.metaKey ||  e.shiftKey;
    const isShiftKey = e.shiftKey ;
    const  isCtrlKey = e.ctrlKey 
    const isAlreadySelected = isItemSelected(id);
    if (!modifierPressed && !isMobile.value && !isAddToSelectionWithoutMetaKey) {
      // if meta key is not pressed and whether sprite is selected or not
      addItemToSelection(id, {clearPrevious: true, afterSelectionActionArguments})
    } else if (!modifierPressed && (isMobile.value || isAddToSelectionWithoutMetaKey)) {
      if(isItemSelected(id)) {
        removeItemFromSelection(id, { afterSelectionActionArguments });
      } else {
        addItemToSelection(id, {clearPrevious: false, afterSelectionActionArguments})
      }
    } else if (isShiftKey && !isAlreadySelected  ) {
      let addedList=[]
      const lastItemSelectedIndex = filteredList.findIndex((item:any) => item.id == selectedItemsIds[selectedItemsIds.length-1])
      if (!isCtrlKey && !lastShiftKeyPressed.value)
      { lastShiftKeyPressed.value = true 
        selectedItemsIds.splice(0,selectedItemsIds.length-1); 
      } else if (isCtrlKey) {
        lastShiftKeyPressed.value = false 
      }
      
      if (lastItemSelectedIndex>itemIndex) {
        addedList = filteredList.slice(itemIndex, lastItemSelectedIndex);
      } else {
        addedList = filteredList.slice(lastItemSelectedIndex, itemIndex+1);
      }
      addedList.forEach((item : any ) => {
        if(!isItemSelected(item.id)) {
          addItemToSelection(item.id, { afterSelectionActionArguments });
        }
      })
    } else if (isShiftKey && isAlreadySelected ) {
      if (isCtrlKey) {
        lastShiftKeyPressed.value = false 
        return ; 
      }
      const removedIds : any[] = [];
      selectedItemsIds.forEach((id) => {
        if ( filteredList.findIndex((item:any) => item.id ==id) > itemIndex){
          removedIds.push(id)
        }
      })
      removedIds.forEach(id=> {
        removeItemFromSelection(id, { afterSelectionActionArguments });
      })
    } else if (modifierPressed && isAlreadySelected ) {
      removeItemFromSelection(id, { afterSelectionActionArguments });
    } else if (modifierPressed && !isAlreadySelected) {
      lastShiftKeyPressed.value = false 
      addItemToSelection(id, { afterSelectionActionArguments });
    }
  };
  

  // TODO: think if there's a better performance friendly way to attach clearItemsSelection — N2DERM37
  if(!isAlreadyMounted.value && clearSelectionTriggerElement !== null) {
    onMounted(()=> {
      clearSelectionTriggerElement.addEventListener('click', clearItemsSelection);
      return () => {
        clearSelectionTriggerElement.removeEventListener('click', clearItemsSelection);
      };
    })
    isAlreadyMounted.value = true;
  }
  


  const addItemToSelection = function (
    itemId: number,
    {
      clearPrevious = false,
      afterSelectionActionArguments = null as any,
      callback = null as Function | null
    } = {}
  ) {
    // TEMP INSPIRATION NOTE: First inspired from previously written code in "addSpriteToStoreSelection" ← {{repo::bitshuttle-frontend}}\src\components\SlideEditor\utils\spritesSelection.ts
    if(clearPrevious) {
      clearItemsSelection();
    }
    selectedItemsIds.push(itemId);
    selectedItems.push(afterSelectionActionArguments.item)
    if(afterAddToSelectionCallback !== null) {
      afterAddToSelectionCallback(afterSelectionActionArguments);
    }

    if(callback !== null) {
      callback();
    }
  }

  const clearItemsSelection = function ({
    callback = null as Function | null
  } = {}) {
    // TEMP INSPIRATION NOTE: First inspired from previously written code in "clearSpritesStoreSelection" ← {{repo::bitshuttle-frontend}}\src\components\SlideEditor\utils\spritesSelection.ts
    selectedItemsIds.splice(0,selectedItemsIds.length);
    selectedItems.splice(0,selectedItems.length)

    if(afterClearSelectionCallback !== null) {
      // TODO: think about if needed to pass "afterSelectionActionArguments" to "afterClearSelectionCallback" if there's a needed use case — N2GJmHFO
      afterClearSelectionCallback();
    }

    if(callback !== null) {
      callback();
    }
  }

  const removeItemFromSelection = function (
    itemId: number,
    { 
      afterSelectionActionArguments = null as any,
      callback = null as Function | null
    } = {},
  ) {
    // TEMP INSPIRATION NOTE: First inspired from previously written code in "removeSpriteFromStoreSelection" ← {{repo::bitshuttle-frontend}}\src\components\SlideEditor\utils\spritesSelection.ts
    selectedItemsIds.splice(selectedItemsIds.indexOf(itemId), 1);
    selectedItems.splice(selectedItems.indexOf(afterSelectionActionArguments.item),1)
    if(afterRemoveFromSelectionCallback !== null) {
      afterRemoveFromSelectionCallback(afterSelectionActionArguments);
    }

    if(callback !== null) {
      callback();
    }
  }

  const isItemSelected = (id: number) => {
    // TEMP INSPIRATION NOTE: First inspired from previously written code in "isSpriteSelected" ← {{repo::bitshuttle-frontend}}\src\components\SlideEditor\utils\spritesSelection.ts
    const isItemSelected = selectedItemsIds.indexOf(id) >= 0;
    return isItemSelected;
  }
  
  const addMultipleItemsToSelection = function(
    itemsArray: any[], 
    { 
      afterSelectionActionArguments = null as any,
      itemIdGetterCallback = null as Function | null,
      clearPrevious = false,
      callback = null as Function | null,
      afterClearCallback = null as Function | null,
    } = {},
  ) {
    if(clearPrevious) {
      clearItemsSelection({callback: afterClearCallback});
    }

    const itemsIdsToAddToSelection = [];

    if(itemIdGetterCallback === null) {
      itemsIdsToAddToSelection.push(...itemsArray);
    }
    
    else if(itemIdGetterCallback !== null) {
      itemsArray.forEach((item) => {
        itemsIdsToAddToSelection.push(itemIdGetterCallback(item));
      });
    }

    selectedItemsIds.push(...itemsIdsToAddToSelection)
    selectedItems.push (...itemsArray)
    if(afteraddMultipleItemsToSelectionCallback !== null) {
      afteraddMultipleItemsToSelectionCallback(afterSelectionActionArguments);
    }

    if(callback !== null) {
      callback();
    }
  }

  const isSelectedItemsCountEquals = function(count: number) {
    return selectedItemsIds.length === count;
  }

  const isUserHasCreateAccessToAllSelectedItems = computed(() => {
    const noCreateAccessItem = selectedItems.find((item) => {
      return (
        !user.hasCreateAccessToItem(item) ||
        !user.hasCreateAccessToAssetType(item)
      )
    });
    if(noCreateAccessItem) {
      return false;
    }
    return true;
  });
  
  const isUserHasDeleteAccessToAllSelectedItems = computed(() => {
    const noCreateAccessItem = selectedItems.find((item) => {
      return (
        !user.hasDeleteAccessToItem(item) ||
        !user.hasDeleteAccessToAssetType(item?.assetType)
      );
    });
    if(noCreateAccessItem) {
      return false;
    }
    return true;
  });
  
  const isUserHasWriteAccessToAllSelectedItems = computed(() => {
    const noCreateAccessItem = selectedItems.find((item) => {
      return (
        !user.hasWriteAccessToItem(item) ||
        !user.hasWriteAccessToAssetType(item?.assetType)
      )
    });
    if(noCreateAccessItem) {
      return false;
    }
    return true;
  });

  return {
    selectListItemClickHandler,
    addItemToSelection,
    clearItemsSelection,
    removeItemFromSelection,
    isItemSelected,
    addMultipleItemsToSelection,
    isSelectedItemsCountEquals,
    isUserHasCreateAccessToAllSelectedItems,
    isUserHasDeleteAccessToAllSelectedItems,
    isUserHasWriteAccessToAllSelectedItems,
    selectedItemsIds,
    selectedItems
  }
}