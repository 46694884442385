import { reactive, ref, watch } from "vue";
import _pick from "lodash/pick";
import { log } from "@/helpers/utils";
import { fetchMediaItemById } from "@/helpers/api/media/fetch";
import AssetTypesMapping from '@/data/mappings/AssetTypes.map';
import { useHasUnsavedChangesHelpers } from "@/composables/utils/hasUnsavedChangesHelpers";

export interface ImageOrVideoBaseEditableDataToSubmit {
  id: null | string,
  name: string,
  tags: any[],
  expirationTime: any,
  previewUri: any,
  sourceUri: any,
  approvalState: any,
  lastAuditedBy: any,
  sharingLevel: number,
  assetType: string,
}

interface ImageOrVideoData extends ImageOrVideoBaseEditableDataToSubmit {
  versions?: any[],
  dateLastModified?: number
}

const data: ImageOrVideoData = reactive({
  id: null as null | string,
  name: "",
  tags: [],
  expirationTime: null,
  previewUri: null,
  sourceUri: null,
  approvalState: null,
  lastAuditedBy: null,
  sharingLevel: 1,
  assetType: null,
  versions: [],
  dateLastModified: 0
});

function showAlert(event: any) {   // needs to be outside "useImageOrVideo" in order to keep reference to it while removing event
  event.returnValue = 'You have unsaved changes!';
}

export default function useImageOrVideo() {
  const {
    unsetHasUnsavedChanges,
    initializeHasUnsavedChangesWatcher,
    isLoading,
    hasUnsavedChanges,
    isDataInitialized
  } = useHasUnsavedChangesHelpers({
    statesToWatchForChanges: data
  });


  function initializeRetrievedData(assetEntityRetrieved) {
    isDataInitialized.value = false;
    isLoading.value = true;
    const pickedData = _pick(assetEntityRetrieved, Object.keys(data));
    Object.assign(data, pickedData);
    if(data.expirationTime) {
      data.expirationTime = new Date(assetEntityRetrieved.expirationTime).toISOString().slice(0,16);
    }
    setTimeout(() => {
      isDataInitialized.value = true;
      isLoading.value = false;  
    }, 1000)
  }

  async function loadImageOrVideoItem(id) {
    isLoading.value = true;
    const mediaItemResponse = await fetchMediaItemById(id || data.id as string) as any;
    const mediaResponseData = mediaItemResponse.entities[0];
    initializeRetrievedData(mediaResponseData)
  }

  async function reloadImageOrVideoItem() {
    await loadImageOrVideoItem(data.id);
  }

  return {
    initializeRetrievedData,
    loadImageOrVideoItem,
    reloadImageOrVideoItem,
    data,
    hasUnsavedChanges,
    unsetHasUnsavedChanges,
    initializeHasUnsavedChangesWatcher
  }
}