import { log } from '@/helpers/utils';
import useApi, { getModelFromViewCode } from '../api';


export async function getUpdateOrganizationItem(itemId: string) {
  const { get } = useApi();
  const { responseBody } = await get('organizations/edit/'+itemId, {isLogoutOnInvalidAuth: true});
  const responseData = responseBody;
  return responseData;
}

export async function submitDeleteOrganizationItem(itemId: string) {
  const { deleteRequest } = useApi();
  
  const { responseBody } = await deleteRequest('api/organizations/'+itemId, {isLogoutOnInvalidAuth: true});
  const responseData = responseBody;
  return responseData;
}
export async function getOrganizationById(itemId:any){
  const {get} = useApi();
  
  const { responseBody } = await get('api/organizations/'+itemId, {isLogoutOnInvalidAuth: true});
  const responseData = responseBody;
  return responseData;
  
}
export async function updateOrganization(itemId:string, model : any ){
  const {put} = useApi();
  model.id = Number(itemId) ; 
  const { responseBody } = await put('api/organizations/'+itemId,[model], {isLogoutOnInvalidAuth: true});
  const responseData = responseBody;
  return responseData;
  
}
export async function addLocation (itemId : string , model : any)
{  const {post} = useApi();
const { responseBody } = await post('api/Organizations/'+itemId+'/locations',[model], {isLogoutOnInvalidAuth: true});
const responseData = responseBody;
return responseData;
}
export async function addChildOrganization (itemId : string , model : any)
{  const {post} = useApi();
const { responseBody } = await post('api/Organizations/'+itemId+'/organizations',[model], {isLogoutOnInvalidAuth: true});
const responseData = responseBody;
return responseData;
}



