<template>
    <Dialog ref="addTag">
        <template #expander="{ openModal }">
            <a @click="openModal" class="cursor-pointer create-link"> {{ btnName }}</a>

        </template>
        <template #dialog.title>
            Add Location
        </template>
        <template #dialog.subtitle>
            {{ title }}
        </template>
        <template #dialog.content="{ closeModal, closeModalAfter }">
            <!-- TODO (cleaner code): think about removing redundancy between "DeleteItemConfirmationForm" & other forms (e.g. CreateSlideForm) [DSP-N26Dui5A] -->
            <div>
                <StatusBar :errorMessage="errorMessage" />
                <div class="d-flex mb-2">
                    <label for="name" class="basis-1/2 text-sm">Name &#9733;</label>
                    <TextField v-model="values.name" class="basis-1/2" width="100%" />
                </div>
                <div class="d-flex mb-2">
                    <label for="name" class="basis-1/2 text-sm">Region &#9733;</label>
                    <div class="styled-select basis-1/2">
                        <select name="region" id="region" v-model="values.region">
                            <option :value="region" v-for="region of  regions">
                                {{ region.name }}
                            </option>
                        </select>
                        <OldThemeIcon name="bullet-down" class="icon" />

                    </div>

                </div>
                <div class="d-flex mb-2">
                    <label for="name" class="basis-1/2 text-sm">Country &#9733;</label>
                    <div class="styled-select basis-1/2">
                        <select name="country" id="country" v-model="values.country"
                            :disabled="values?.region?.countries?.length > 0 ? false : true">
                            <option :value="country" v-for="country of  values?.region?.countries ">
                                {{ country.name }}
                            </option>
                        </select>
                        <OldThemeIcon name="bullet-down" class="icon" />

                    </div>


                </div>
                <div class="d-flex mb-2">
                    <label for="name" class="basis-1/2 text-sm">State</label>
                    <div class="styled-select basis-1/2">

                        <select name="state" id="state" v-model="form.state"
                            :disabled="values?.country?.states?.length > 0 ? false : true">
                            <option :value="state" v-for="state of  values?.country?.states">
                                {{ state.name }}
                            </option>
                        </select>
                        <OldThemeIcon name="bullet-down" class="icon" />

                    </div>


                </div>
                <div class="d-flex mb-2">
                    <label for="name" class="basis-1/2 text-sm">City &#9733;</label>
                    <TextField v-model="values.city" class="basis-1/2" width="100%" />
                </div>
                <div class="d-flex mb-2">
                    <label for="name" class="basis-1/2 text-sm">Postal Code </label>
                    <TextField v-model="form.zip" class="basis-1/2" width="100%" />
                </div>
                <div class="d-flex mb-2">
                    <label for="name" class="basis-1/2 text-sm">Time Zone &#9733;</label>
                    <div class="styled-select basis-1/2">

                        <select name="timezone" id="timezone" v-model="values.timezone">
                            <option :value="zone.Id" v-for="zone of  timeZone ">
                                {{ zone.DisplayName }}
                            </option>
                        </select>
                        <OldThemeIcon name="bullet-down" class="icon" />

                    </div>


                </div>
                <div class="d-flex mb-2">
                    <label for="name" class="basis-1/2 text-sm">Contact Name </label>
                    <TextField v-model="form.contactName" class="basis-1/2" width="100%" />
                </div>
                <div class="d-flex mb-2">
                    <label for="name" class="basis-1/2 text-sm">Phone </label>
                    <TextField v-model="form.phoneNumber" class="basis-1/2" width="100%" />
                </div>
                <div class="d-flex mb-2">
                    <label for="name" class="basis-1/2 text-sm">Hours</label>
                    <TextField v-model="form.hours" class="basis-1/2" width="100%" />
                </div>
                <div class="form-buttons">
                    <ButtonCustomized label="Cancel" @click="closeModal()" secondary />
                    <ButtonCustomized label="Save" @click="submitForm()" />
                </div>
            </div>

        </template>
    </Dialog>
</template>
      
<script lang="ts" setup>
import Dialog from '@/components/Dialog/Dialog.vue';
import ButtonCustomized from "@/components/form-fields/ButtonCustomized.vue";
import { computed, onMounted, ref, watch } from 'vue';
import TextField from "@/components/form-fields/TextField.vue";
import { useForm } from "vee-validate";
import * as yup from 'yup';
import StatusBar from "@/components/form-fields/StatusBar/StatusBar.vue";
import { getRegion } from '@/helpers/countries/countries';
import OldThemeIcon from '@/components/icons/OldThemeIcon.vue';

const emit = defineEmits(['cancel', 'save', 'exit', 'fail'])
const props = defineProps({
    'title': {
        type: String,
        default: true
    },
    'timeZone': {
        type: Object
    },
    'btnName': {
        type: String
    }
})
const validationSchema = yup.object({
    name: yup.string().label('name').required(),
    region: yup.object().label('region').required(),
    country: yup.object().label('country').required(),
    city: yup.string().label('city').required(),
    timezone: yup.string().label('timezone').required(),

});

const { errors, handleSubmit, values, setFieldValue, isSubmitting } = useForm({ validationSchema });
const errorMessage = ref()
const addTag = ref()
const form = ref({
    city: "",
    contactName: "",
    country: "" as any,
    hours: "",
    name: "",
    phoneNumber: "",
    region: "" as any,
    state: "" as any,
    timezone: "",
    zip: ""
})
const submitForm = handleSubmit(onSave, handleSubmitInvalid);
const regions = getRegion()
async function handleSubmitInvalid() {
    let errorsAugmentedInSingleMessage = "";
    // TODO (cleaner code): think if there's a cleaner way to write this [DSP-N26Cti4A]
    if (Object.keys(errors.value).length) {
        Object.keys(errors.value).forEach((key) => {
            errorsAugmentedInSingleMessage += errors.value[key] + ". ";
        });
    }
    errorMessage.value = errorsAugmentedInSingleMessage;
}
async function onSave() {
    form.value.name = values.name;
    form.value.city = values.city
    form.value.region = values.region.name;
    form.value.timezone = values.timezone;
    form.value.country = values.country.name
    form.value.state = form.value.state.name
    emit('save', form.value);
    addTag.value.closeModal()
}

</script>
      
<style lang="scss" scoped>
.create-link:hover {
    text-decoration: underline;
}

.select {
    border: 1px solid #abadb3;
    height: 35px;
    padding: 2px 2px 1px 2px;
    outline: none;
    background: transparent;
    left: 0;
    font-size: 14px;
    top: 0;
    z-index: 2;
    cursor: pointer;
    width: 100%;

    &:disabled {
        margin-right: auto;
        background-color: #e6e6e6;
        color: #999999;
    }
}

.button {
    cursor: pointer;
}

.delete-item-form {
    display: flex;
    flex-direction: column;
    max-width: 30rem;
}

.form-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
    margin-top: 1rem;
}

.disabled {
    pointer-events: none;
    background-color: #E6E6E6;
    color: #999999
}

.icon {
    position: absolute;
    top: 8px;
    right: 2px;
    z-index: 1;
    pointer-events: none;
}

.styled-select {
    /* TODO: to separate templates related from generic later as it was quickly copied  */
    /* TODO: to think about the className it was quickly copied  */

    border: 1px solid #abadb3;
    /* Old disabled styling */
    /* line-height: 0; */
    /* NEW STYLING */
    line-height: 1em;
    display: flex;
    justify-content: center;
    height: 34px;
    overflow: hidden;
    padding: 0;
    position: relative;

    /* width: 46px; */
    width: 100%;

    :deep(select) {
        background: transparent;
        border: 0;
        left: 0;
        font-size: 14px;
        top: 0;
        appearance: none;
        z-index: 1;
        cursor: pointer;
        padding: 9px;
        margin: 0;

        width: 100%;
        padding: 0 1.2em 0 0.6em;
        outline: none;
        color: #666666;
    }
}
</style>