import filtersDisplayConfig from "./filtersDisplay.config";
import columnsRenderersConfig from "./columnsRenderers.config";
import AssetTypesMapping from '@/data/mappings/AssetTypes.map';
import apiPathsConfig from './apiPaths.config';
import MediaTableListItem from "../MediaListItem/MediaTableListItem.vue";
import availableActionsConfig from "./availableActions.config";
import availableListViewsConfig from "./availableListViews.config";
import listNamesConfig from "./listNames.config";

// TODO: think about separating "resultsManipulationCallback" & "tableViewConfig" to separate files & if it's needed — N2QJIj5p

const resultsManipulationCallback = (resultItem) => {
  // Prepare assetType
  resultItem.assetType = AssetTypesMapping[resultItem.assetType];
}

const tableViewConfig = {
  loadingSkeleton: {
    type: 'withImage',
    count: 5
  }
}

const itemsListerConfig = {
  TableViewItem: MediaTableListItem,
  apiPathsConfig,
  resultsManipulationCallback,
  filtersDisplayConfig,
  columnsRenderersConfig,
  tableViewConfig,
  availableActionsConfig,
  availableListViewsConfig,
  ...listNamesConfig
}

export default itemsListerConfig;