<template>
<div class="item-skeletons-container">
  <div class="image-skeleton" ref="imageSkeletonContainerRef">
    <loading-skeleton :containerRef="imageSkeletonContainerRef" />
  </div>
  <div class="name-and-type-skeletons-container">
    <div class="name-skeleton" ref="nameSkeletonContainerRef">
      <loading-skeleton :containerRef="nameSkeletonContainerRef" />
    </div>
    <div class="type-skeleton" ref="typeSkeletonContainerRef">
      <loading-skeleton :containerRef="typeSkeletonContainerRef" />
    </div>
  </div>
</div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

const imageSkeletonContainerRef = ref<HTMLDivElement>();
const nameSkeletonContainerRef = ref<HTMLDivElement>();
const typeSkeletonContainerRef = ref<HTMLDivElement>();

</script>

<style lang="scss" scoped>

.item-skeletons-container {
  display: flex;
  flex-direction: row;
}

.name-and-type-skeletons-container {
  display: flex;
  flex-direction: column;
}

.image-skeleton {
  height: 6em;
  width: 6em;
  margin: 0.375rem;
}

.name-skeleton {
  height: 2.5em;
  width: 15em;
  margin: 0.375rem;
}

.type-skeleton {
  height: 2em;
  width: 4em;
  margin: 0.375rem;
}
</style>