const baseApiPaths = {
  'initialize': 'roles',
  'search': 'rolesearch'
}

const actionsApiPaths = {
  'export': 'roles/export',
  'delete': 'api/roles',
  'edit': 'roles/edit'
}

const apiPathsConfig = {
  ...baseApiPaths,
  ...actionsApiPaths
}

export default apiPathsConfig;