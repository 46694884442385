import type { ImageSprite } from "@/helpers/types/media/slide/Sprite";

export function getImageBestFitParams(sprite: ImageSprite) {
  const spriteWidth = sprite.width;
  const spriteHeight = sprite.height;
  const sourceAssetWidth = sprite.sourceAssetWidth || 0;
  const sourceAssetHeight = sprite.sourceAssetHeight || 0;
  const bestFitScalingRatio = (sourceAssetWidth == 0 || sourceAssetHeight == 0) ?
    1 :
    Math.min(spriteWidth / sourceAssetWidth, spriteHeight / sourceAssetHeight);
  const newWidth = sourceAssetWidth * bestFitScalingRatio;
  const newHeight = sourceAssetHeight * bestFitScalingRatio;
  const newImageX = (spriteWidth - newWidth)/2;
  const newImageY = (spriteHeight - newHeight)/2;

  return {
    newWidth,
    newHeight,
    newImageX,
    newImageY
  }
}

export function applyImageFitmentForSprite(
  fitment: 'fill' | 'best-fit' | 'original',
  sprite: ImageSprite
) {
  if(fitment === 'fill') {
    sprite.imageX = 0;
    sprite.imageY = 0;
    sprite.imageWidth = sprite.width;
    sprite.imageHeight = sprite.height;
  }
  
  else if (fitment === 'best-fit') {
    const {
      newWidth,
      newHeight,
      newImageX,
      newImageY
    } = getImageBestFitParams(sprite)
    
    sprite.imageX = newImageX;
    sprite.imageY = newImageY;
    sprite.imageWidth = newWidth;
    sprite.imageHeight = newHeight;
  }
  
  else if (fitment === 'original') {
    sprite.imageX = 0;
    sprite.imageY = 0;
    const sourceAssetWidth = sprite.sourceAssetWidth;
    const sourceAssetHeight = sprite.sourceAssetHeight;
    sprite.imageWidth = sourceAssetWidth;
    sprite.imageHeight = sourceAssetHeight;
  }

  sprite.fitment = fitment;
}

export function refreshImageSpriteFitment(sprite: ImageSprite) {
  applyImageFitmentForSprite(sprite.fitment, sprite);
}