import { ref, computed, onBeforeMount } from 'vue';

const theme = ref('old-theme');

export function useTheme() {
  const theme_fromLocalStorage = localStorage.getItem('theme') as string;
  if(theme_fromLocalStorage !== null) {
    theme.value = theme_fromLocalStorage;
  }

  function setTheme(themeName: string) {
    localStorage.setItem('theme', themeName);
    theme.value = themeName;
  }

  return {
    theme,
    setTheme
  }
}

export default useTheme;