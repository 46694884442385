import { computed, onMounted, reactive, ref, watchEffect } from "vue";
import { useForm } from "vee-validate";
import * as yup from 'yup';
import useItemsListHelpers  from "@/components/ListingPages/ItemsLister/composables/itemsListHelpers/itemsListHelpers";
import { reassignPlayer } from "@/helpers/api/player/player";
// import useCampaignList from "@/views/CampaignView/CampaignLister/composables/campaignList";
export default function useReassignPlayerForm() {
  // TODO: think about creating custom form handler that may be more cleaner or readable / easier to use than Vee-Validate [DSP-N23Gxg1I]

  const isLoading = ref(false);
  const isSuccess = ref(false);
  const isError = ref(false);
  const errorMessage = ref("");
  const playerId = ref("");
  const newPlayerId = ref("");

 // const selectedLocationId  = ref ()
  const validationSchema = yup.object({
    code: yup.string().label('Registration code').required(),
  });

  const initialValues = {
    code: "",
  };

  const { errors, handleSubmit, values, setFieldValue , isSubmitting } = useForm({ validationSchema, initialValues });
  const fieldsToSubmit = computed(() => {
    const { code } = values;
    return {
      code,
    };
  });
  
  const submitForm = handleSubmit(handleSubmitValid, handleSubmitInvalid);

  async function handleSubmitValid() {
    try {
      errorMessage.value = "";
      isLoading.value = true;
      const submissionResponse = await reassignPlayer( playerId.value,
        fieldsToSubmit.value.code 
      );
      isLoading.value = false;
      console.log('ddd');;
      console.log(submissionResponse)
      console.log(submissionResponse)
      if (submissionResponse?.playerId) {
        // if is successful
        isSuccess.value = true;
        newPlayerId.value = submissionResponse.playerId;
       // mutateList();
      }
      if (submissionResponse?.errors){
        if (submissionResponse.errors[0]?.message){
          errorMessage.value = submissionResponse.errors[0]?.message
        }
      }
    
    } catch (error :any ) {
      isError.value = true;
      isLoading.value = false;
      errorMessage.value ='Something went wrong';
    }
  }
  async function handleSubmitInvalid() {
    let errorsAugmentedInSingleMessage = "";
    // TODO (cleaner code): think if there's a cleaner way to write this [DSP-N26Cti4A]
    if(Object.keys(errors.value).length){
      Object.keys(errors.value).forEach((key) => {
        errorsAugmentedInSingleMessage += errors.value[key]+". ";
      });
    }
    errorMessage.value = errorsAugmentedInSingleMessage;
  }
  return {
    values,
    submitForm,
    isError,
    isSuccess,
    isLoading,
    errorMessage,
    playerId,
    newPlayerId
    
  };
}
