// Defines filters display order and labels in UI

const filtersDisplayConfig = [
  {
    "name": "FILTER_TYPE",
    "label": "Media Type",
    options: [
      {name: 'Slides', data: 'Slides'}
    ],
    selectedOptions: ["Slides"]
  },
  {
    "name": "FILTER_ACCESSTYPE",
    "label": "Sharing"
  },
  {
    "name": "FILTER_STATUS",
    "label": "Status"
  },
  {
    "name": "FILTER_AUDITING",
    "label": "Auditing",
    isSeparatedAbove: true
  },
  {
    "name": "FILTER_CREATED",
    "label": "Date Created",
    isSeparatedAbove: true,
    "type": "collapsibleSelect"
  },
  {
    "name": "FILTER_MODIFIED",
    "label": "Date Modified",
    "type": "collapsibleSelect"
  }
]

export default filtersDisplayConfig;