import useUserStore from '@/stores/user';


export function checkInvalidAuth(response: any) {
  const responseUrl = response.request.responseURL;
  // console.log({responseUrl});
  const isRedirectToLoginPage = Boolean( responseUrl.match(/\/Account\/LogOn/) );
  const isInvalidAuth = isRedirectToLoginPage;
  if(isInvalidAuth) {
    throw {name: 'InvalidAuthError', message: 'Login to authenticated user is required.'}
  }
  return isInvalidAuth;
}

export async function runAndHandleInvalidAuth(callback: Function, onInvalidAuthHandler: Function = logoutUser) {
  try {
    const response = await callback();
    checkInvalidAuth(response);
    return response;
  } catch(err: any) {
    if (err.name == 'InvalidAuthError') {
      onInvalidAuthHandler();
    }
  }
}

// side helper functions

function logoutUser() {
  const user = useUserStore();
  user.logout();
}