import {
  sprites,
  spritesReversed,
  basicInfo,
  clearSprites,
  selectedSpritesIds
} from '../slide';
import { loadImages } from "./spritesImageLoading";

import cloneDeep from "lodash/cloneDeep";
import { ref, type Ref, shallowRef, type ShallowRef, watch, nextTick } from "vue";

interface SlideHistoryItem {
  basicInfo: typeof basicInfo;
  sprites: typeof sprites;
}

export const historyStep = ref(-1);
export const history = shallowRef([]) as ShallowRef<SlideHistoryItem[]>;
export const isPauseHistoryWatcher = ref(true);
import { removeSpriteFromSelection } from '@/views/MediaEditorView/SlideEditor/utils/spritesSelection';

export function initializeHistoryWatcher(isDataInitialized: Ref<boolean>) {
  watch([basicInfo, sprites], async () => {
    if (!isDataInitialized.value || isPauseHistoryWatcher.value) return;
    await nextTick();
    addHistorySnapshot();
  });
}

export function onMultiStepChangeStartHistoryHandler() {
  pauseHistory();
}

export function onMultiStepChangeEndHistoryHandler({takeSnapshot = true} = {}) {
  if(takeSnapshot) addHistorySnapshot();
  resumeHistory();
}

export async function undo() {
  if (historyStep.value === 0) {
    return;
  }
  pauseHistory();
  historyStep.value -= 1;
  const previousHistorySnapshot = history.value[historyStep.value];
  const { basicInfo: basicInfoPrevious, sprites: spritesPrevious } = previousHistorySnapshot;
  Object.assign(basicInfo, basicInfoPrevious);
  removeSnapshotMissingSpritesFromSelection(spritesPrevious)
  await updateSpritesFromHistorySnapshot(spritesPrevious);
  resumeHistory();
}

export async function redo() {
  if (historyStep.value === history.value.length - 1) {
    return;
  }
  pauseHistory();
  historyStep.value += 1;
  const nextHistorySnapshot = history.value[historyStep.value];
  const { basicInfo: basicInfoNext, sprites: spritesNext } = nextHistorySnapshot;
  Object.assign(basicInfo, basicInfoNext);
  removeSnapshotMissingSpritesFromSelection(spritesNext)
  await updateSpritesFromHistorySnapshot(spritesNext);
  resumeHistory();
}

export function pauseHistory() {
  isPauseHistoryWatcher.value = true;
}

export async function resumeHistory() {
  await nextTick();
  isPauseHistoryWatcher.value = false;
}

export function clearHistory() {
  history.value = [];
  historyStep.value = -1;
}

export function removeSnapshotMissingSpritesFromSelection(spritesSnapshot: any[]) {
  // remove non-existing (missing) sprites in a history snapshot from selection
  selectedSpritesIds.forEach(spriteId => {
    const isSpriteInSnapshot = spritesSnapshot.findIndex(sprite => sprite.id == spriteId) >= 0;
    if(!isSpriteInSnapshot) {
      removeSpriteFromSelection(spriteId);
    }
  })
}

export function addHistorySnapshot() { // adds history snapshot from current state
  const spritesClones = sprites.map(sprite => cloneDeep(sprite));
  const historySnapshotItem = {basicInfo: {...basicInfo}, sprites: [...spritesClones]};
  history.value = history.value.slice(0, historyStep.value + 1);
  history.value = history.value.concat([historySnapshotItem]);
  historyStep.value += 1;
}

async function updateSpritesFromHistorySnapshot(spritesSnapshot: any[]) {
  clearSprites();
  const spritesSnapshotClone = spritesSnapshot.map(sprite => cloneDeep(sprite));
    // important: use clones in order use references to history items
    // (so modifications to sprites do not affect previous history snapshots)
  
  spritesReversed.push(...spritesSnapshotClone.slice().reverse());
  await nextTick();
  loadImages();
}