<template>
  <div class="content-container">
    <div class="form-box">
      <div class="form-header">
        <img class="logo" src="/src/assets/images/bitshuttle.png" alt="bitSHUTTLE logo" />
      </div>
      <div class="form-container" @keyup="keyPressHandler">
        <div class="form-title">
          <h1>Log In</h1>
        </div>
        <StatusBar
          :errorMessage="user.error"
          :isLoading="/* true ||  */user.isLoading"
          loadingMessage="Logging in..."
        />
        <input
          class="text"
          data-val="true"
          data-val-required="The User name field is required."
          id="userName"
          name="userName"
          placeholder="Username"
          type="text"
          v-model.lazy="username"
        />
        <input
          class="text"
          data-val="true"
          data-val-required="The Password field is required."
          id="password"
          name="password"
          placeholder="Password"
          type="password"
          v-model.lazy="password"
        />
        <div class="remember-me">
          <input
            class="checkbox "
            data-val="true"
            data-val-required="The Remember me? field is required."
            id="rememberMe"
            name="rememberMe"
            type="checkbox"
            value="true"
          />
          <input name="rememberMe" type="hidden" value="false" />
          <label for="rememberMe">Remember me?</label>
        </div>
        <a @click="submit" class="login-btn">Log In</a>
        <a @click="submitPasswordResetRequest" class="forgot-password">Forgot password?</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watchEffect } from 'vue';
import useUserStore from '@/stores/user';
import router from '@/router';
import StatusBar from '@/components/form-fields/StatusBar/StatusBar.vue';
import { sendPasswordResetEmail } from '@/helpers/api/user';

const user = useUserStore();
const username = ref("");
const password = ref("");

async function submit() {
  await user.login({username:username.value, password: password.value});
}
watchEffect(() => {
  if(user.isLoggedIn == true) {
    router.push({ name: 'home' })
  }
})

async function submitPasswordResetRequest() {
  try {
    // // check if username is empty or not an email
    const emailValidateRegex = /^(?!\.)[A-Za-z0-9._%+-]+(?<!\.)@[A-Za-z0-9.-]+\.[A-Za-z]{2,}(?<!\.)$/;
    if (username.value === '' || !emailValidateRegex.test(username.value)) {
      user.passwordResetErrorMessage = 'The Username field is invalid.';
      router.push({ name: 'reset-password', query: { userRequestingResetPassword: username.value, isResendSuccess: "false" } });
      return;
    }
    await sendPasswordResetEmail(username.value);
    router.push({ name: 'reset-password', query: { userRequestingResetPassword: username.value, isResendSuccess: "true" } });
  } catch (error:any) {
    console.error('handleSendPasswordResetEmail', error);
    router.push({ name: 'reset-password', query: { userRequestingResetPassword: username.value, isResendSuccess: "false" } });
    user.passwordResetErrorMessage = error?.message || "";
  }
}

function keyPressHandler(e) {
  if (e.key === 'Enter') {
    submit();
  }
}
</script>

<style lang="scss" scoped>
.content-container {
  display: flex;
  justify-content: center;
}

.forgot-password {
  color: #25294A;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.form-box {
  width: 460px;
  background-color: #ffffff;
  box-shadow: 0px 3px 17px rgb(0 0 0 / 16%);
  -webkit-box-shadow: 0px 3px 17px rgb(0 0 0 / 16%);
  list-style-type: none;
  border-radius: 6px;
}
.form-header {
  background-color: #25294A;
  display: flex;
  justify-content: center;
}
.logo {
  margin: 30px 0;
  width: 280px;
}
.form-title h1 {
  font-size: 40px;
  font-weight: 400;
}

.form-container {
  padding: 30px;
}
input {
  width: 100%;
  padding: 3% 5%;
  font-size: 16px;
  margin-top: 15px;
  border: 1px solid #abadb3;
  &:focus, &:focus-within, &:focus-visible {
    outline: none !important;
    box-shadow: 0 0 10px #719ECE;
  }
  border-radius: 0.4em;
}

.remember-me {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
}
.remember-me input {
  width: 15px;
  height: 15px;
  margin: 0 10px 0 0;
}

.login-btn {
  margin: 0;
  border: 0;
  color: #ffffff;
  cursor: pointer;
  height: 24px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  background-color: #25294A;
  margin-bottom: 3px;
  font-weight: normal;
  display: block;
  text-decoration: none;
  box-shadow: 0px 3px 9px rgba(0,0,0,0.16);
  border-radius: 6px;
  padding: 8px;
  height: 2.5em;
}

</style>
