import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { submitLogin ,submitLogout } from '@/helpers/api/user';
import type { Credentials } from '@/helpers/api/user';
import router from '@/router';
import { useRoute } from 'vue-router';
import _pick from 'lodash/pick';
import UserFlagsMapping from '@/data/mappings/UserFlags.map';
import AccessLevelsMapping from '@/data/mappings/AccessLevels.map';

export const useUserStore = defineStore('user', () => {
  const userId = ref();
  const username = ref();
  const firstName = ref();
  const lastName = ref();
  const isLoggedIn = ref(false);
  const isAdmin = ref();
  const fullName = computed(() => `${firstName.value} ${lastName.value}`)
  const isLoginCheckedLocaly = ref(false);
  const error = ref();
  const passwordResetErrorMessage = ref();
  const isLoading = ref(false);
  // const roles = ref();
  const flags = ref();
  const areaAccessLevels = ref();
  const assetTypeAccessLevels = ref();

  const route = useRoute();

  function checkLogin() {
    if(isLoginCheckedLocaly.value === true) {
      return
    }
    
    const userFromLocalStorage_string = localStorage.getItem('user') || '';
    if(!userFromLocalStorage_string) {    // user wasn't logged in before
      isLoggedIn.value = false;
    } else {
      isLoggedIn.value = true;
      copyUserFromLocalToState();
    }
    isLoginCheckedLocaly.value = true;
    redirectIfRouteRequiresAuth();
    return

    function copyUserFromLocalToState() {
      const userFromLocalStorage = JSON.parse(userFromLocalStorage_string);
      username.value = userFromLocalStorage.username;
      userId.value = userFromLocalStorage.userId;
      firstName.value = userFromLocalStorage.firstName;
      lastName.value = userFromLocalStorage.lastName;
      isAdmin.value = userFromLocalStorage.isAdmin;
      flags.value = userFromLocalStorage.flags;
      areaAccessLevels.value = userFromLocalStorage.areaAccessLevels;
      assetTypeAccessLevels.value = userFromLocalStorage.assetTypeAccessLevels;
      // roles.value = userFromLocalStorage.roles;
    }

    function redirectIfRouteRequiresAuth() {
      if (route.meta.requiresAuth && isLoggedIn.value === false) {
        router.push({ name: 'login' })
      }
    }
  }
  async function login({username: usernameInput, password}: Credentials) {
    try {
      isLoading.value = true;
      const responseData = await submitLogin({username: usernameInput, password})
      isLoading.value = false;

      username.value = responseData.user.userName;
      userId.value = responseData.user.id;
      firstName.value = responseData.user.firstName;
      lastName.value = responseData.user.lastName;
      isAdmin.value = responseData.user.isAdmin;
      flags.value = responseData.user.flags;
      areaAccessLevels.value = responseData.user.areaAccessLevels;
      assetTypeAccessLevels.value = responseData.user.assetTypeAccessLevels;
      // roles.value = getRolesPrepared(responseData.user.roles);
      isLoggedIn.value = true;

      localStorage.setItem('user', JSON.stringify({
        username: username.value,
        userId: userId.value,
        firstName: firstName.value,
        lastName: lastName.value,
        isAdmin: isAdmin.value,
        flags: flags.value,
        areaAccessLevels: areaAccessLevels.value,
        assetTypeAccessLevels: assetTypeAccessLevels.value,
        // roles: roles.value
      }));

      error.value = null;
    } catch (err: any) {
      isLoading.value = false;
      error.value = err.message;
    }
  }

  async function logout() {
    await submitLogout()
    if(this !== undefined) {
      this.$reset();
    }
    localStorage.removeItem('user');
    // redirect to login page if current path is authorized
    router.push({ name: 'login' })
  }

  interface Role {
    roleAreaAccesses: RoleAreaAccess[];
    roleAssetTypeAccesses: RoleAssetTypeAccess[];
  }

  interface RoleAreaAccess {
    access: string | number;
    area: {
      name: string;
      areaType: string | number;
    }
  }

  interface RoleAssetTypeAccess {
    access: string | number;
    assetType: string | number;
  }

  function getRolesPrepared(rolesReceived: Role[]) {
    const rolesPrepared: Role[] = [];
    rolesReceived.forEach((roleReceived: Role) => {
      const roleAreaAccessesPrepared: RoleAreaAccess[] = [];
      roleReceived.roleAreaAccesses.forEach(roleAreaAccess => {
        const roleAreaAccessPrepared = _pick(roleAreaAccess, ['access', 'area.name', 'area.areaType']);
        roleAreaAccessesPrepared.push(roleAreaAccessPrepared);
      })
      const roleAssetTypeAccessesPrepared: RoleAssetTypeAccess[] = [];
      roleReceived.roleAssetTypeAccesses.forEach(roleAssetTypeAccess => {
        const roleAssetTypeAccessPrepared = _pick(roleAssetTypeAccess, ['access', 'assetType']);
        roleAssetTypeAccessesPrepared.push(roleAssetTypeAccessPrepared);
      });
      
      const rolePrepared: Role = {
        roleAreaAccesses: roleAreaAccessesPrepared,
        roleAssetTypeAccesses: roleAssetTypeAccessesPrepared,
      };
      rolesPrepared.push(rolePrepared);
    });
    return rolesPrepared;
  }
  
  function isFlagSet(value: number, flag: number) {
    return ((value & flag) == flag);
  }

  function hasFlag(flagName: keyof typeof UserFlagsMapping) {
    if(isLoggedOutHandler()) return false;
    const userFlags = flags.value;
    return isFlagSet(userFlags, UserFlagsMapping[flagName]);
  }

  function isSuperUser() {
    return hasFlag("SuperUser");
  }
  function isAccountAdmin() {
    return hasFlag("AccountAdmin");
  }
  function isAuditor() {
    return hasFlag("Auditor") || isSuperUser();
  }
  function isAnalytics() {
    return hasFlag("Analytics") || isSuperUser() || isAccountAdmin();
  }

  function hasReadAccessToArea(areaName: string) {
    if(isLoggedOutHandler()) return false;
    const userAreaAccessLevelFlag = areaAccessLevels.value[areaName];
    return isFlagSet(userAreaAccessLevelFlag, AccessLevelsMapping["Read"]);
  }

  function hasWriteAccessToArea(areaName: string) {
    if(isLoggedOutHandler()) return false;
    const userAreaAccessLevelFlag = areaAccessLevels.value[areaName];
    return isFlagSet(userAreaAccessLevelFlag, AccessLevelsMapping["Write"]);
  }

  function hasCreateAccessToArea(areaName: string) {
    if(isLoggedOutHandler()) return false;
    const userAreaAccessLevelFlag = areaAccessLevels.value[areaName];
    return isFlagSet(userAreaAccessLevelFlag, AccessLevelsMapping["Create"]);
  }

  function hasDeleteAccessToArea(areaName: string) {
    if(isLoggedOutHandler()) return false;
    const userAreaAccessLevelFlag = areaAccessLevels.value[areaName];
    return isFlagSet(userAreaAccessLevelFlag, AccessLevelsMapping["Delete"]);
  }

  type AssetType = "Image" | "Video" | "Slide";

  function hasReadAccessToAssetType(assetType: AssetType) {
    if(isLoggedOutHandler()) return false;
    if (assetType === undefined) return true;
    const assetTypePrepared = assetType[0].toUpperCase() + assetType.substring(1);
    const userAssetTypeAccessLevel = assetTypeAccessLevels.value[assetTypePrepared];
    return isFlagSet(userAssetTypeAccessLevel, AccessLevelsMapping["Read"]);
  }

  function hasWriteAccessToAssetType(assetType: AssetType) {
    if(isLoggedOutHandler()) return false;
    if (assetType === undefined) return true;
    const assetTypePrepared = assetType[0].toUpperCase() + assetType.substring(1);
    const userAssetTypeAccessLevel = assetTypeAccessLevels.value[assetTypePrepared];
    return isFlagSet(userAssetTypeAccessLevel, AccessLevelsMapping["Write"]);
  }

  function hasCreateAccessToAssetType(assetType: AssetType) {
    if(isLoggedOutHandler()) return false;
    if (assetType === undefined) return true;
    const assetTypePrepared = assetType[0].toUpperCase() + assetType.substring(1);
    const userAssetTypeAccessLevel = assetTypeAccessLevels.value[assetTypePrepared];
    return isFlagSet(userAssetTypeAccessLevel, AccessLevelsMapping["Create"]);
  }

  function hasDeleteAccessToAssetType(assetType: AssetType) {
    if(isLoggedOutHandler()) return false;
    if (assetType === undefined) return true;
    const assetTypePrepared = assetType[0].toUpperCase() + assetType.substring(1);
    const userAssetTypeAccessLevel = assetTypeAccessLevels.value[assetTypePrepared];
    return isFlagSet(userAssetTypeAccessLevel, AccessLevelsMapping["Delete"]);
  }

  function hasReadAccessToItem(item: any) {
    return isFlagSet(item.accessLevel, AccessLevelsMapping["Read"]);
  }

  function hasWriteAccessToItem(item: any) {
    return isFlagSet(item.accessLevel, AccessLevelsMapping["Write"]);
  }

  function hasCreateAccessToItem(item: any) {
    return isFlagSet(item.accessLevel, AccessLevelsMapping["Create"]);
  }

  function hasDeleteAccessToItem(item: any) {
    return isFlagSet(item.accessLevel, AccessLevelsMapping["Delete"]);
  }

  function isLoggedOutHandler() {
    if(areaAccessLevels.value === undefined || assetTypeAccessLevels.value === undefined) {
      // important for "Dashboard" page if old users that don't have assetTypeAccessLevels stored in localStorage
      logout();
      return true;
    }
  }

  return { 
    userId,
    username,
    firstName,
    lastName,
    isLoggedIn,
    isAdmin,
    // roles,
    areaAccessLevels,
    assetTypeAccessLevels,
    fullName,
    isLoginCheckedLocaly,

    login,
    logout,
    checkLogin,
    
    hasFlag,
    isSuperUser,
    isAccountAdmin,
    isAuditor,
    isAnalytics,

    hasReadAccessToArea,
    hasWriteAccessToArea,
    hasCreateAccessToArea,
    hasDeleteAccessToArea,

    hasReadAccessToAssetType,
    hasWriteAccessToAssetType,
    hasCreateAccessToAssetType,
    hasDeleteAccessToAssetType,

    hasReadAccessToItem,
    hasWriteAccessToItem,
    hasCreateAccessToItem,
    hasDeleteAccessToItem,

    error,
    isLoading,
    passwordResetErrorMessage
  }
});

export default useUserStore;