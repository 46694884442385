<template>
  <div class="form-fields">
    <div class="formGroup">
      <label for="name">Duration </label>
      <NumberField
        v-model="duration"
        width="100%"
        :disabled="isUseDefaultDuration"
      />
    </div>
    <div class="formGroup">
      <CheckboxField v-model="isUseDefaultDuration" name="use-default-duration" label="Use default duration" />
    </div>
  </div>
  <div class="form-buttons">
    <ButtonCustomized label="Cancel" @click="onCancel" :secondary="true" />
    <ButtonCustomized label="Done" @click="onSubmit" />
  </div>
</template>

<script lang="ts" setup>
import NumberField from "@/components/form-fields/NumberField.vue";
import ButtonCustomized from "@/components/form-fields/ButtonCustomized.vue";
import CheckboxField from '@/components/form-fields/CheckboxField.vue';

import { ref, watch } from "vue";
import { log } from "@/helpers/utils";
const emit = defineEmits(['submit', 'cancel'])

const props = defineProps<{
  item: any;
  defaultDuration: number;
}>()

function onCancel() {
  emit('cancel');
}

const duration = ref(props.item.duration ? props.item.duration : props.defaultDuration);

const isUseDefaultDuration = ref(props.item.duration ? false : true)
watch(isUseDefaultDuration, () => {
  if(isUseDefaultDuration) {
    duration.value = props.defaultDuration;
  }
})

async function onSubmit() {
  const durationValueToEmit = (isUseDefaultDuration.value ? 0 : duration.value);
  // log({durationValueToEmit, isUseDefaultDuration}, {ns: 'EditPlaylistItemPropertiesForm', color: "green"});
  
  emit('submit', durationValueToEmit)
}



</script>

<style lang="scss" scoped>

.status-bar-container {
  margin-bottom: 1rem;
}

.create-slide-form {
  display: flex;
  flex-direction: column;
  max-width: 20rem;
}
.form-fields {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
.formGroup {
  display: flex;
  align-items: center;
  .select {
    width: 60%;
    margin: 0;
  }
  label {
    display: inline-block;
    width: 110px;
    color: #262626;
    font-weight: bold;
  }
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1rem;
}
</style>
