<template>
<textarea
  v-if="isTextSpriteTextEditing"
  v-model="textContent"
  class="text-sprite-text-editing-area"
  textAreaRef="textAreaRef"
  @keydown.stop
  @keyup.stop
  :style="textAreaStyling"
/>
</template>

<script lang="ts" setup>
import useTextSpriteEditAreaHelpers from './composables/textSpriteEditAreaHelpers';
const {
  isTextSpriteTextEditing,
  textAreaRef,
  textContent,
  textAreaStyling
} = useTextSpriteEditAreaHelpers();
</script>

<style lang="scss" scoped>
.text-sprite-text-editing-area {
  position: absolute;
}
</style>