import useApi, { getModelFromViewCode } from "../api";

export async function fetchRolesList(listRequestOptions: any ) {
    const { post } = useApi();
    
    const { responseBody } = await post('rolesearch', listRequestOptions, {isLogoutOnInvalidAuth: true});
    const responseData = responseBody;
    return responseData;
  }
  export async function createUser(userModel: any ) {
    const { post } = useApi();
    
    const { responseBody } = await post('api/users', [userModel], {isLogoutOnInvalidAuth: true});
    const responseData = responseBody;
    return responseData;
  }
  export async function getUserById(itemId:any){
    const {get} = useApi();
    
    const { responseBody } = await get('users/edit/'+itemId, {isLogoutOnInvalidAuth: true});
    const responseData = getModelFromViewCode(responseBody);
    return responseData;
    
  }
  export async function updateUser(itemId:string, model : any ){
    const {put} = useApi();
    model.id = Number(itemId) ; 
    const { responseBody } = await put('api/users/'+itemId,[model], {isLogoutOnInvalidAuth: true});
    const responseData = responseBody;
    return responseData;
    
  }
  export async function resetPassword (model:any){
    const {post} = useApi();
    const { responseBody } = await post('api/Accounts/ResetPassword/',model, {isLogoutOnInvalidAuth: true});
    const responseData = responseBody;
    return responseData;
  }