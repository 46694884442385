const baseApiPaths = {
  'initialize': 'organizations',
  'search': 'organizationsearch'
}

const actionsApiPaths = {
  'export': 'organizations/export',
  'delete': 'api/organizations',
  'edit': 'organizations/edit'
}

const apiPathsConfig = {
  ...baseApiPaths,
  ...actionsApiPaths
}

export default apiPathsConfig;