<template>
  <!-- TODO: having a more generic color field [DSP-N23E220J]
      [Helpful for background color form & others]
-->
  <div class="field">
    <label
      v-if="label || $slots.label"
      :for="name"
    >
      <template v-if="!$slots.label">
        {{ label }}
      </template>
      <slot name="label" v-else></slot>
      <template v-if="required"> &#9733;</template>
    </label>
    <TextField
      v-model.uppercase="colorValue"
      :width="width"
      class="color-text"
      :name="name"
      :id="name || id"
    />
    <ColorPicker
      v-model.uppercase="colorValue"
      @focusin="$emit('colorFocusin')"
      @focusout="$emit('colorFocusout')"
      @change="$emit('colorChange')"
    />
  </div>
</template>

<script lang="ts" setup>
import TextField from "@/components/form-fields/TextField.vue";
import ColorPicker from "@/components/form-fields/ColorPicker/ColorPicker.vue";

import { ref, watch } from 'vue';

export interface Props {
  modelValue: string,
  label?: string,
  modelModifiers?: Function,
  name?: string,
  id?: string,
  required?: boolean,
  width?: string
};

const props = withDefaults(defineProps<Props>(), {
  'modelModifiers': () => ({}),
  'width': "11ch"
})

const emit = defineEmits(['update:modelValue', 'colorFocusin', 'colorFocusout', 'colorChange']);

const colorValue = ref(props.modelValue);

watch(colorValue, () => {
  emit('update:modelValue', colorValue.value);
})

</script>

<style lang="scss" scoped>
.field {
  display: flex;
  align-items: center;
  label {
    display: inline-block;
    color: #262626;
    font-weight: bold;
    margin-right: 0.375rem;
  }
}
</style>