<template>
  <component :is="(isItemSelected(item.id) ? (disabled? RadioMarkIcon :CheckMarkIcon  ) : (disabled? EmptyCircleIcon :EmptyBoxIcon))"
    :class='["item-selection-checkmark", {"selected": isItemSelected(item.id), "item-hovered": isItemHovered(item.id) ,"disabled":disabled }]'
    @click.stop="mediaListItemSelectHandler($event, item, itemIndex, {isAddToSelectionWithoutMetaKey: true})"
  />
</template>

<script lang="ts" setup>
import CheckMarkIcon from "@/components/icons/CheckMarkIcon.vue";
import EmptyBoxIcon from "@/components/icons/EmptyBoxIcon.vue";
import RadioMarkIcon from "@/components/icons/RadioMarkIcon.vue";
import EmptyCircleIcon from '@/components/icons/EmptyCircleIcon.vue'
import { inject } from "vue";

import useMediaListItemSelectionHelpers from '../../composables/mediaListItemSelectionHelpers/mediaListItemSelectionHelpers';
import useItemTableSelectBoxHelpers from "./itemTableSelectBoxHelpers";

const itemsListHelpers = inject('itemsListHelpers');

defineProps(['item','itemIndex','disabled'])

const {
  mediaListItemSelectHandler,
  isItemSelected
} = useMediaListItemSelectionHelpers( itemsListHelpers );

const {
  isItemHovered
} = useItemTableSelectBoxHelpers();

</script>

<style lang="scss" scoped>
.item-selection-checkmark {
  color: #B1C4CF;
  transition: opacity, color 0.2s ease-out;
  opacity: 0;
  &.item-hovered {
    opacity: 0.8;
  }
  &.selected, &:hover {
    color: #1c69d4;
    opacity: 1;
  }
}
.disabled{
  pointer-events: none;
}
</style>