<!-- Linked TODO: check refactoring task / intent "N2LLlJD3" -->

<template>
  <SimpleEntityListingPage
    entityItemsNamePlural="Roles"
    entityItemName="Role"
    areaAccessName="Administration"
    :ItemsLister="RolesLister"
    :createItemPage = true 
     @create="createRole()"
  />
</template>

<script lang="ts" setup>
import SimpleEntityListingPage from '@/components/ListingPages/SimpleEntityListingPage/SimpleEntityListingPage.vue';
import router from '@/router';
import RolesLister from './RolesLister/RolesLister.vue';

function createRole (){
  router.push('roles/create');

}
</script>

<style lang="scss" scoped>

</style>