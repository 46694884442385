import { computed, onMounted, reactive, ref, watch, watchEffect } from "vue";
import { useForm } from "vee-validate";
import * as yup from 'yup';
import { createUser, fetchRolesList } from "@/helpers/api/users/users";
export default function useCreateUserForm() {
  // TODO: think about creating custom form handler that may be more cleaner or readable / easier to use than Vee-Validate [DSP-N23Gxg1I]

  const isLoading = ref(false);
  const isSuccess = ref(false);
  const isError = ref(false);
  const errorMessage = ref("");
  const newUserId = ref("");
  const selectedGroup  = ref ()
  const roles = ref () 
  const hasChanges = ref (false)
  const userFlags = ref (
    {
        None: 0,
    
        Auditor: 1,
        AccountAdmin: 2,
        SuperUser:4,
        Analytics: 8
    })
  const validationSchema = yup.object({
    firstName: yup.string().label('First name').required(),
    lastName : yup.string().label('Last name').required(),
    username : yup.string().label('Email address').required(),
    group : yup.object().label('Organization').required()

  });

  const initialValues = {
    firstName: "",
    lastName:"",
    username :"",
    phone:"",
    address1:"",
    address2 :"",
    region:"" as any ,
    country:"" as any ,
    state :""as any ,
    city:"",
    zip:"",
    roles:[],
    group : null as any ,
    flags:0,
    auditorFlag : false , 
    superUserFlag : false ,
    accountFlag : false , 
    analyticsFlag : false 
    
  };
  const { errors, handleSubmit, values, setFieldValue , isSubmitting } = useForm({ validationSchema, initialValues });
watch(values, (newFields,oldFields,) => {
  hasChanges.value = true 
  if (hasChanges.value)
  {
    onBeforeLoad() ; 
  }else {
    removeBeforeLoad();
  }
})

watch(hasChanges, (newValue,oldValue,) => {
  if (newValue)
  {
    onBeforeLoad() ; 
  }else {
    removeBeforeLoad();
  }
})
function onBeforeLoad () {
  window.addEventListener('hashchange', showAlert);
  window.addEventListener('beforeunload',showAlert 
  );
}
function removeBeforeLoad () {
  window.removeEventListener('hashchange', showAlert);

  window.removeEventListener('beforeunload',showAlert)

  
}
function showAlert (event :any ) {
  event.returnValue = 'You have unfinished changes!';
}
  const submitForm = handleSubmit(handleSubmitValid, handleSubmitInvalid);
 
  function fillUserModel () {
    if (values.accountFlag)
    {
      values.flags += userFlags.value.AccountAdmin
    }
    if (values.superUserFlag)
    {
      values.flags += userFlags.value.SuperUser
    }
    if (values.analyticsFlag)
    {
      values.flags += userFlags.value.Analytics
    }
    if (values.auditorFlag)
    {
      values.flags += userFlags.value.Auditor
    }
  }
  async function handleSubmitValid() {
    try {
      fillUserModel();
      let userModel = {...values}
      userModel.country = values.country.name
      userModel.state = values.state.name
      userModel.region = values.region.name 
      errorMessage.value = "";
      isLoading.value = true;
      isSuccess.value = false;

      const submissionResponse = await createUser( userModel);
      isLoading.value = false;
      if ( typeof submissionResponse[0]?.username === "string") {
        isSuccess.value = true;
        newUserId.value = submissionResponse[0].id;
      }
      if ( submissionResponse.errors && submissionResponse.errors[0]?.message){
        errorMessage.value = submissionResponse.errors[0]?.message
      }
    } catch (error :any ) {
      isError.value = true;
      isLoading.value = false;
      errorMessage.value ='Something went wrong';
    }
  }
  async function handleSubmitInvalid() {
    let errorsAugmentedInSingleMessage = "";
    // TODO (cleaner code): think if there's a cleaner way to write this [DSP-N26Cti4A]
    if(Object.keys(errors.value).length){
      Object.keys(errors.value).forEach((key) => {
        errorsAugmentedInSingleMessage += errors.value[key]+". ";
      });
    }
    errorMessage.value = errorsAugmentedInSingleMessage;
  }
  async function getRolesList (){
    const result = await fetchRolesList ({limit:100, offset:0})
    roles.value= result.results
  }
  return {
    values,
    submitForm,
    isError,
    isSuccess,
    isLoading,
    errorMessage,
    newUserId,
    getRolesList,
    selectedGroup,
    roles,
    hasChanges,
    removeBeforeLoad
  };
}
