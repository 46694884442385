<template>
  <div class="navbar" v-if="user.isLoggedIn">
    <div class="menu-mobile">
      <NavbarSideCollapsible />
    </div>
    <div class="menu-desktop">
      <NavItems desktop />
    </div>
    <a class="logo" href="/" :key="'banner-image-'+user.userId"></a>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import NavbarSideCollapsible from './NavbarSideCollapsible/NavbarSideCollapsible.vue';
import NavItems from './NavItems/NavItems.vue';
import useUser from '@/composables/user';
import useDataSourceStore from '@/stores/dataSource';
const { activeDataSourceBaseUrl } = useDataSourceStore();
const user = useUser();
const bannerImageBackgroundUrl = computed(() => `url(${activeDataSourceBaseUrl}/resources/banner-image?userId=${user.userId})`);
</script>

<style lang="scss" scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(45deg, #89b6de 0%, #a0cfd2 40%);
  padding: 0.6em 1em;
  align-items: center;
  height: 4em;

  @include x-large-devices-up {
    padding: 0 5%;
  }
  @include theme-old {
    background: none;
    padding: initial;
    height: auto;
  }
}

.menu-desktop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include theme-old {
    @include xx-large-devices-down {
      margin-left: 1em;
    }
  }

  @include medium-devices-down {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  @include medium-devices-down {
    display: none;
  }
}

.menu-mobile {
  @include medium-devices-up {
    display: none;
  }
}

.logo {
  background-image: v-bind(bannerImageBackgroundUrl);
  background-repeat: no-repeat;
    background-position: right;
  height: 40px;
  width: 200px;
  @include xx-large-devices-down {
    margin-right: 1em;
  }
  
  @include medium-devices-down {
    margin-right: 2em;
  }
}
.settings-page-link {
  color: #25294A;
}
</style>
