// baseRefs: are refs to basic slide editor elements
import { ref, shallowRef, computed } from 'vue';

export const scrollContainer = ref();
export const scrollContainerEl = computed(() => scrollContainer.value || scrollContainerElPreserved.value);
export const scrollContainerElPreserved = shallowRef();

export const stageParentContainer = ref();
export const stageParentContainerEl = computed(() => stageParentContainer.value || stageParentContainerElPreserved.value);
export const stageParentContainerElPreserved = shallowRef();

export const stage = ref();
export const stageNode = computed(() => stage.value?.getNode() || stageNodePreserved.value);
export const stageNodePreserved = shallowRef();  // Preserved ref are used for preserving node (of normal slide editor page) element reference when full screen mode gets activated.

export const slideMainLayer = ref();
export const slideMainLayerNode = computed(() => slideMainLayer.value?.getNode() || slideMainLayerNodePreserved.value);
export const slideMainLayerNodePreserved = shallowRef();

export const transformer = ref();
export const transformerNode = computed(() => transformer.value?.getNode() || transformerNodePreserved.value);
export const transformerNodePreserved = shallowRef();

export const selectionRectangle = ref();
export const selectionRectangleNode = computed(() => selectionRectangle.value?.getNode() || selectionRectangleNodePreserved.value);
export const selectionRectangleNodePreserved = shallowRef();

export const developmentStatsTextShape = ref();    // used also by "Development Layer" component
export const developmentStatsTextShapeNode = computed(() => developmentStatsTextShape.value?.getNode() || developmentStatsTextShapeNodePreserved.value);
export const developmentStatsTextShapeNodePreserved = shallowRef();

export function keepPreservedCopyOfNodes() {
  // helpful for full screen mode
    // used for preserving nodes (of normal slide editor page) elements references when full screen mode gets activated.
  
  if(scrollContainer.value && !scrollContainerElPreserved.value) {
    scrollContainerElPreserved.value = scrollContainer.value;
  }

  if(stageParentContainer.value && !stageParentContainerElPreserved.value) {
    stageParentContainerElPreserved.value = stageParentContainer.value;
  }

  if(stageNode.value && !stageNodePreserved.value) {
    stageNodePreserved.value = stageNode.value;
  }

  if(slideMainLayerNode.value && !slideMainLayerNodePreserved.value) {
    slideMainLayerNodePreserved.value = slideMainLayerNode.value;
  }

  if(transformerNode.value && !transformerNodePreserved.value) {
    transformerNodePreserved.value = transformerNode.value;
  }

  if(selectionRectangleNode.value && !selectionRectangleNodePreserved.value) {
    selectionRectangleNodePreserved.value = selectionRectangleNode.value;
  }

  if(developmentStatsTextShapeNode.value && !developmentStatsTextShapeNodePreserved.value) {
    developmentStatsTextShapeNodePreserved.value = developmentStatsTextShapeNode.value;
  }
}

const baseElementsRefs = {
  scrollContainer,
  scrollContainerEl,

  stageParentContainer,
  stageParentContainerEl,

  stage,
  stageNode,
  
  slideMainLayer,
  slideMainLayerNode,

  transformer,
  transformerNode,

  selectionRectangle,
  selectionRectangleNode,

  developmentStatsTextShape,
  developmentStatsTextShapeNode
}

export default baseElementsRefs;