import { loggerNamespaces, loggerConfig } from '@/config/logger';
import { elapsedTimeSinceLast, currentTimeFormatted } from '@/helpers/utils';

// TODO: add more control and support for node.js console logging in "log" (will be helpful for other projects) — N2K3D0DZ
// TODO: think about adding support for using a wildcard enabling / disabling of namespaces (e.g. "debug:*") — N2K4O38s
// TODO: think about which is better name "createLogger" or "getLogger" — N2K4cI5H
// TODO: think about adding different default generated colors for suffixes / prefixes (seems not needed) — N2K54I0l
// TODO: think about adding ability to add custom css console log styles for each namespace — N2K5Gd2F
  // (may be using "style" prop in loggerNameSpaces item config)
// TODO: think about adding a "loggerConfig" option to disable by default (so any namespace without "enaabled set to true" or "disabled set to false" will be disabled by default) — N2K5bR9f
// TODO: think about printing "objects" / arrays using JSON.stringify() — N2K6Td0h

/*
  Quick Usage Examples
    Usage Example 1:
      // someMessage: can be a text string or an object you want to inspect or any other value type.
      log(someMessage, {ns: 'testA', suffix: 'from onMouseUp A'});
      log(someMessage, {ns: 'test', suffix: 'from onMouseUp B'});
      log(someMessage, {ns: 'test', suffix: 'from onMouseUp C'});
    Usage Example 2: (with customized logger)
      const logCustomized = createLogger({ns: 'testCreatedA'})
      logCustomized(someMessage, {suffix: 'from onMouseUp D1'});
      logCustomized(someMessage, {suffix: 'from onMouseUp D2'});
      logCustomized(someMessage, {suffix: 'from onMouseUp D3'});
      logCustomized(someMessage, {suffix: 'from onMouseUp D4'});
*/

export function createLogger(initialOptions: LogOptions | string) {
  const initialOptionsPrepared = typeof initialOptions === 'string' ? {ns: initialOptions} : initialOptions;
  return function logger(message: LogMessage, options: LogOptions = {}) {
    const optionsAugmented = {...initialOptionsPrepared, ...options};
    log(message, optionsAugmented);
  }
}

interface LogOptions {
  obj?: any | null; // object to print along with the message
  ns?: string | null; // namespace shorthand
  namespace?: string | null;
  suffix?: string | null; // namespace suffix
  prefix?: string | null; // namespace prefix
  color?: string | null; // namespace color
}

type LogMessage = string | object;

// TODO: think about adding short aliases for options like "s" for "suffix", "p" for "prefix", "c" for "color" — N2K5wvAh
  // Also be careful in this because this may make code not clean / readable however it has a pros of creating shorter log statements for debugging

export function log(
  message: LogMessage,
  options: LogOptions|string = {}
) {
  let namespaceParsed;
  // inspired by: debug library (https://www.npmjs.com/package/debug)
  if(typeof options === 'object') {
    // get namespace settings
    namespaceParsed = (options?.ns || options?.namespace) as string;
  }
  else if (typeof options === 'string') {
    namespaceParsed = options;
  }

  const namespaceSettings = loggerNamespaces?.[namespaceParsed];
  if(namespaceSettings?.disabled) return;

  let elapsedSinceLastLog, elapsedSinceLastNamespaceLog, elapsedTime_messagePart = '';
  if(namespaceSettings?.showElapsedTimeSinceLastLogCall !== false) {
    elapsedSinceLastLog = elapsedTimeSinceLast('log');
    elapsedTime_messagePart += ' +'+elapsedSinceLastLog;
  }

  if(namespaceSettings?.showElapsedTimeSinceLastNamespaceLogCall === true) {
    elapsedSinceLastNamespaceLog = elapsedTimeSinceLast('log:'+namespaceParsed);
    elapsedTime_messagePart += ' (+'+elapsedSinceLastLog+')';
  }

  const namespacePrefix = options?.prefix || namespaceSettings?.prefix || '';
  const namespaceSuffix = options?.suffix || namespaceSettings?.suffix || '';

  let namespaceColor = options?.color || namespaceSettings?.color;
  if(!namespaceColor && namespaceParsed) {
    if(!namespacesDefaultAssignedColors[namespaceParsed]) {
      namespacesDefaultAssignedColors[namespaceParsed] = colors.length%2 === 0 ? colors.shift() : colors.pop();
        // the colors.length%2 part for having more contrast between default generated colors
        // TODO: think if there's a better way to have more contrast between default generated colors — N2K4CT8g
    }
    namespaceColor = namespacesDefaultAssignedColors[namespaceParsed];
  } else if (!namespaceColor) {
    namespaceColor = 'black';
  }
  const namespaceName_messagePart = (namespacePrefix ? ':' : '')+(namespaceParsed ? namespaceParsed+':' : 'log:')+(namespaceSuffix ? ':' : '');
  const namespaceNamePrefix_messagePart = namespacePrefix ? namespacePrefix+':' : '';
  const namespaceNameSuffix_messagePart = namespaceSuffix ? namespaceSuffix+':' : '';

  const namespace_messagePart = namespaceNamePrefix_messagePart+namespaceName_messagePart+namespaceNameSuffix_messagePart;
  const namespace_messageStyle = 'color:'+namespaceColor+'; font-weight: bold; font-style: italic; font-size: 1.3em;';
  
  const elapsedTime_messageStyle = namespace_messageStyle;
  
  const currentTime_messageStyle = 'color: grey;'
  const currentTime_messagePart = loggerConfig.showCurrentTime ? currentTimeFormatted()+' ' : '';

  const isMessageNotAString_messagePart = typeof message !== 'string' ? ' (message is not a string)' : '';
  const isMessageNotAString_messageStyle = 'color: grey;'

  const isHasAttachedObject_messagePart = options?.obj ? ' (has attached object)' : '';
  const isHasAttachedObject_messageStyle = 'color: grey;'

  const bodyMessageToLog_messagePart = typeof message === 'string' ? ' '+message : '';
  const bodyMessageToLog_messageStyle = 'color: grey;'+' font-weight: normal;';


  console.log(
    '%c'+currentTime_messagePart+
    '%c'+namespace_messagePart+
    '%c'+bodyMessageToLog_messagePart+
    '%c'+isMessageNotAString_messagePart+
    '%c'+isHasAttachedObject_messagePart+
    '%c'+elapsedTime_messagePart,
    currentTime_messageStyle,
    namespace_messageStyle,
    bodyMessageToLog_messageStyle,
    isMessageNotAString_messageStyle,
    isHasAttachedObject_messageStyle,
    elapsedTime_messageStyle
  );

  if(typeof message !== 'string') {
    console.log(message)
  };

  if(options?.obj) {
    console.log(message)
  };
}

export default log;

const namespacesDefaultAssignedColors = {} as any;

const colors = [
  // Colors were copied from: https://github.com/debug-js/debug/blob/f851b00eb006d400e757dca33568773910365519/src/browser.js
  '#0000CC',
  '#0000FF',
  '#0033CC',
  '#0033FF',
  '#0066CC',
  '#0066FF',
  '#0099CC',
  '#0099FF',
  '#00CC00',
  '#00CC33',
  '#00CC66',
  '#00CC99',
  '#00CCCC',
  '#00CCFF',
  '#3300CC',
  '#3300FF',
  '#3333CC',
  '#3333FF',
  '#3366CC',
  '#3366FF',
  '#3399CC',
  '#3399FF',
  '#33CC00',
  '#33CC33',
  '#33CC66',
  '#33CC99',
  '#33CCCC',
  '#33CCFF',
  '#6600CC',
  '#6600FF',
  '#6633CC',
  '#6633FF',
  '#66CC00',
  '#66CC33',
  '#9900CC',
  '#9900FF',
  '#9933CC',
  '#9933FF',
  '#99CC00',
  '#99CC33',
  '#CC0000',
  '#CC0033',
  '#CC0066',
  '#CC0099',
  '#CC00CC',
  '#CC00FF',
  '#CC3300',
  '#CC3333',
  '#CC3366',
  '#CC3399',
  '#CC33CC',
  '#CC33FF',
  '#CC6600',
  '#CC6633',
  '#CC9900',
  '#CC9933',
  '#CCCC00',
  '#CCCC33',
  '#FF0000',
  '#FF0033',
  '#FF0066',
  '#FF0099',
  '#FF00CC',
  '#FF00FF',
  '#FF3300',
  '#FF3333',
  '#FF3366',
  '#FF3399',
  '#FF33CC',
  '#FF33FF',
  '#FF6600',
  '#FF6633',
  '#FF9900',
  '#FF9933',
  '#FFCC00',
  '#FFCC33'
];
