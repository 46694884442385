<template>
  <component 
    :is="wrapperLink ? 'a': 'div'" 
    :href="wrapperLink"
    class="thumbnail-image-container" 
    @click.prevent
  >
    <ImageWithLoadingAndErrorIndicators
      :src="previewUri"
      imageClass="thumbnail-image"
      :revalidateIfLessThanAge=60000
      :dateLastModified="dateLastModified"
      :refreshAfterIfRevalidate=30000
    />
    <v-tooltip
      v-if="alerts.length"
      :text="alertsAugmented"
      color="white"
      content-class="alerts-overlay-content"
    >
      <template v-slot:activator="{ props }">
        <OldThemeIconRed
          class="alerts-indicator"
          name="alert"
          v-bind="props"
        />
      </template>
    </v-tooltip>
  </component>
</template>

<script lang="ts" setup>
import ImageWithLoadingAndErrorIndicators from '@/components/ImageWithLoadingAndErrorIndicators/ImageWithLoadingAndErrorIndicators.vue';
import OldThemeIconRed from '@/components/icons/OldThemeIconRed/OldThemeIconRed.vue';
import { computed } from 'vue';

const props = defineProps(['previewUri', 'alerts', 'dateLastModified', 'wrapperLink']);

const alertsAugmented = computed(() => {
  let messageAugmented = '';
  if (props.alerts.length) {
    props.alerts.forEach((alert, index) => {
      messageAugmented += alert.message + (props.alerts[index + 1] ? ', ' : '');
    });
  }
  return messageAugmented;
});
</script>

<style lang="scss" scoped>
.thumbnail-image-container {
  height: 64%;
  @include small-devices-down {
    height: 83%;
  }
  width: 100%;
  padding: 0.375rem;
  :deep(.thumbnail-image) {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border: 1px solid #888888;
  }
}

.alerts-indicator {
  position: absolute;
  bottom: 5px;
  right: 5px;
}
</style>

<style>
/* TODO (cleaner + more maintainable code): think about if veutify style overriding like this is a good option on the long run [DSP-N26Nns5J] */
.alerts-overlay-content {
  /* Overrides default veutify styling */
  background-color: #ffffff !important;
  color: #25294A !important;
  box-shadow: 0px 3px 17px rgb(0 0 0 / 16%);
  padding: 5px;
}
</style>