<template>
<FieldsGroup>
  <TextField label="Name" v-model="basicInfo.name" />
</FieldsGroup>
<FieldsGroup>
  <FieldsRow>
    <NumberField
      label="Width"
      v-model="basicInfo.width"
      name="spriteWidth"
      maxlength="4"
    />
    <NumberField
      label="Height"
      v-model="basicInfo.height"
      name="spriteHeight"
      maxlength="4"
    />
  </FieldsRow>
</FieldsGroup>
<FieldsGroup>
  <ColorField
    v-model="basicInfo.backgroundColor"
    label="Background Color"
    @colorFocusin="onMultiStepChangeStartHistoryHandler"
    @colorFocusout="onMultiStepChangeEndHistoryHandler({takeSnapshot: false})"
    @colorChange="onMultiStepChangeEndHistoryHandler()"
  />
</FieldsGroup>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import useSlide from '@/views/MediaEditorView/SlideEditor/composables/slide';
import TextField from '@/components/form-fields/TextField.vue';
import NumberField from '@/components/form-fields/NumberField.vue';
import FieldsGroup from '@/components/form-fields/FieldsGroup.vue';
import FieldsRow from '@/components/form-fields/FieldsRow.vue';
import ColorField from '@/components/form-fields/ColorField/ColorField.vue';
import {
  onMultiStepChangeStartHistoryHandler,
  onMultiStepChangeEndHistoryHandler
} from '@/views/MediaEditorView/SlideEditor/composables/helpers/history';

const { basicInfo } = useSlide();

</script>

<style lang="scss" scoped>

</style>