<template>
<OldThemeIcon name="export" class="icon" @click="exportList"/>
</template>

<script lang="ts" setup>
import OldThemeIcon from '@/components/icons/OldThemeIcon.vue';
import { submitExportList } from "@/helpers/api/list/export";
import { promptSaveDataToFile } from '@/helpers/utils';
import { inject } from "vue";

const itemsListHelpers = inject('itemsListHelpers');

const {
  filters,
  sortBy,
  searchTerm,
  queryFieldNames,
  apiPathsConfig
} = itemsListHelpers;

async function exportList() {
  const { responseData, fileName } = await submitExportList({
    exportApiPath: apiPathsConfig.export,
    filters,
    sortBy,
    searchTerm: searchTerm.value,
    queryFieldNames
  });
  promptSaveDataToFile(responseData, fileName);
}
</script>

<style lang="scss" scoped>

</style>