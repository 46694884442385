<template>
    <Dialog :expanderClass="'d-inline'" ref="player">
      <template #expander="{ openModal }">
    <ButtonCustomized :label="'Reassign'" @click="openModal"  /> 
  
      </template>
      <template #dialog.title>
       Warning
      </template>
      <template #dialog.content="{ closeModal, closeModalAfter }">
  
  
        <!-- TODO (cleaner code): think about removing redundancy between "DeleteItemConfirmationForm" & other forms (e.g. CreateSlideForm) [DSP-N26Dui5A] -->
        <div class="confirmation-text">
            Are you sure you want to reassign the current player?
            <br>
This process is not reversible.        </div>
        <div class="form-buttons">
          <ButtonCustomized label="Cancel" @click="closeModal" secondary />
          <ButtonCustomized label="Continue" @click="onSave" />
        </div>
  
      </template>
    </Dialog>
  </template>
      
  <script lang="ts" setup>
  import Dialog from '../../../components/Dialog/Dialog.vue';
  import ButtonCustomized from "../../../components/form-fields/ButtonCustomized.vue";
  import { ref } from 'vue';
  const player = ref()
  const emit = defineEmits(['save'])

  async function onSave() {
    emit('save');
    player.value.closeModal()
  }
  
  </script>
      
  <style lang="scss" scoped>
  .button {
    cursor: pointer;
  }
  
  .delete-item-form {
    display: flex;
    flex-direction: column;
    max-width: 30rem;
  }
  
  .form-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
    margin-top: 1rem;
  }
  .change-link {
    border: 0;
    cursor: pointer;
    display: inline-block;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 6px;
    text-transform: uppercase;
    color: #25294A;
    font-weight: bold;
  }
  .change-link:hover{
    text-decoration: underline;
}
  
  </style>