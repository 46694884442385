// "data source" here means a source that we will bring long-living data from.
import type { DataSource } from "@/components/TopHeader/SideBars/DataSourceSwitcher/DataSource";

export const dataSources: Array<DataSource> = [
  {
    name: "live",
    label: "Live Production",
    baseUrl: "http://signage.bitshuttle.net",
  },
  {
    name: "liveDev1",
    label: "Live Dev 1 (SmarterASP.NET)",
    baseUrl: "http://htopology-002-site2.etempurl.com",
  },
  {
    name: "liveDev2",
    label: "Live Dev 2 (bitshuttle integration)",
    baseUrl: "https://signage-int.bitshuttle.net/"
  },
  {
    name: "localhost_9000",
    label: "Localhost:9000",
    baseUrl: "http://localhost:9000/"
  },
  {
    name: "localhost_8081",
    label: "Localhost:8081",
    baseUrl: "http://localhost:8081/"
  },
  {
    name: "relative",
    label: "Relative",
    baseUrl: "relative",
  },
  {
    name: "sidevs_api",
    label: "api.signage.sidevs.com",
    baseUrl: "https://api.signage.sidevs.com",
  },
  {
    name: "sidevs_v1",
    label: "signage.sidevs.com/v1/",
    baseUrl: "https://signage.sidevs.com/v1/",
  },
  {
    name: "integration_v1",
    label: "signage-int.bitshuttle.net/v1/",
    baseUrl: "https://signage-int.bitshuttle.net/v1/",
  },
  {
    name: "/v1",
    label: "/v1",
    baseUrl: "/v1",
    isDefault: true,
  },
  {
    name: "localStorage",
    label: "Local Storage",
    isLocalStorage: true 
  }
];


export default dataSources;