<template>
  <Dialog ref="campaign">
    <template #expander="{ openModal }">
      <a @click="openModal" class="cursor-pointer mt-1"> Add  </a>

    </template>
    <template #dialog.title>
      Add Campaign
    </template>
    <template #dialog.content="{ closeModal, closeModalAfter }">
      <h3>{{playerTitle}}</h3>


      <!-- TODO (cleaner code): think about removing redundancy between "DeleteItemConfirmationForm" & other forms (e.g. CreateSlideForm) [DSP-N26Dui5A] -->
      <div>
        <SimpleEntityListingPage :entityItemsNamePlural="''" entityItemName="Campaign"
          areaAccessName="Campaigns"
          :ItemsLister="CampaignsLister"   :selectMode=true  />
        <div class="form-buttons">
          <ButtonCustomized label="Add" @click="onSave" />
        </div>
      </div>

    </template>
  </Dialog>
</template>
    
<script lang="ts" setup>
import Dialog from '../../../components/Dialog/Dialog.vue';
import ButtonCustomized from "../../../components/form-fields/ButtonCustomized.vue";
import SimpleEntityListingPage from '@/components/ListingPages/SimpleEntityListingPage/SimpleEntityListingPage.vue';
import CampaignsLister from '../../CampaignsListView/CampaignsLister/CampaignsLister.vue';
import useListSelection from '@/components/ListingPages/ItemsLister/ItemsList/composables/listSelection';
import { ref } from 'vue';
const campaign = ref()
const emit = defineEmits(['cancel', 'save', 'exit', 'fail'])
defineProps({
  'playerTitle': {
    type: String,
    default: true
  },
  'showBtn':{
    type: Boolean,
    default: true
  }

})
const {
  selectedItems,
  clearItemsSelection
} = useListSelection({
  clearSelectionTriggerElement: document.body
});

async function onSave() {
  emit('save', selectedItems);
  clearItemsSelection () ;
  campaign.value.closeModal()
}

</script>
    
<style lang="scss" scoped>
.button {
  cursor: pointer;
}

.delete-item-form {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1rem;
}
</style>