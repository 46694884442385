import { ref, reactive } from "vue";
import { scrollContainerEl, stageParentContainerEl, slideMainLayerNode } from '../baseElementsRefs';
import { stagePosition, stageScaleConfig, stageHeight, stageWidth } from '../baseConfigsRefs';


// Initial inspirer
  // "Solution 4: Emulate screen moving with transform." in https://konvajs.org/docs/sandbox/Canvas_Scrolling.html

const PADDING = 50;

export const slideMainLayerPositionProps = reactive({})
export const scrollContainerPositionProps = reactive({});
export const styles_largeContainer = reactive({
  width: "100%",
  height: "100%"
});

export function repositionStage() {
  if(scrollContainerEl.value === undefined) return;
  const dx = scrollContainerEl.value.scrollLeft;
  const dy = scrollContainerEl.value.scrollTop;
  stageParentContainerEl.value.style.transform =
    'translate(' + dx + 'px, ' + dy + 'px)';
  
  stagePosition.value = {
    x: -dx+PADDING,
    y: -dy+PADDING
  };

  // Styling the large container -------
  // styles_largeContainer.width = ;
  // styles_largeContainer.height = ;
  // Styling the large container ---------------------------- START

  // update "scroll container" positioning props
  {
    const boundingClientRect = scrollContainerEl.value.getBoundingClientRect();
    Object.assign(scrollContainerPositionProps, {
      boundingClientRect
    });
  }
  // update "slide main layer" positioning props
  {
    const absolutePosition = slideMainLayerNode.value.absolutePosition();
    const position = slideMainLayerNode.value.position();
    const clientRect = slideMainLayerNode.value.getClientRect();
    Object.assign(slideMainLayerPositionProps, {
      absolutePosition,
      position,
      clientRect
    });
  }
  // Styling the large container ---------------------------- END
  
  if(slideMainLayerPositionProps.clientRect.height + PADDING > stageHeight.value) {
    styles_largeContainer.height = (slideMainLayerPositionProps.clientRect.height + (PADDING*2)) + "px";
  } else {
    styles_largeContainer.height = "100%";
  }
  
  if(slideMainLayerPositionProps.clientRect.width + PADDING > stageWidth.value) {
    styles_largeContainer.width = (slideMainLayerPositionProps.clientRect.width + (PADDING*2)) + "px";
  } else {
    styles_largeContainer.width = "100%";
  }

  // if()
  // styles_largeContainer.width
  // -----------------------
  
}

export default function usePanStageHelpers() {
  return {
    repositionStage,
    slideMainLayerPositionProps,
    scrollContainerPositionProps,
    styles_largeContainer
  }
}