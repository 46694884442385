<template>
<SelectField
  :modelValue="modelValue"
  :options="fontsFamilies"
  @update:modelValue="updateTextFontValue"
  label="Font"
  labelKey="name"
  valueKey="name"
/>
</template>

<script lang="ts" setup>
import SelectField from '@/components/form-fields/SelectField/SelectField.vue';

import useSlide, {type FontFamily} from '@/views/MediaEditorView/SlideEditor/composables/slide';

const props = defineProps<{
  modelValue: string;
}>();

const emit = defineEmits(['update:modelValue', 'update:fontFamily', 'update:font']);


const { fontsFamilies, updateSelectedSpriteFontFamilyWithStyleName } = useSlide();

function updateTextFontValue(fontFamilyNameSelection: string) {
  emit('update:modelValue', fontFamilyNameSelection);
  updateSelectedSpriteFontFamilyWithStyleName();
}
</script>

<style lang="scss" scoped>

</style>