<template>
  <td class="name-cell">
    <RouterLink
      v-if="!selectMode && !singleSelect && areaAccess?.hasWriteAccess"
      :to="`/${apiPathsConfig.edit}/${item.id}`"
      tag="div"
      :class="['font-bold', 'title']"
      component="a"
      @click.stop
    >
      {{item.username}}
    </RouterLink>
    <div v-else>
      {{item.username}}
    </div>
  </td>
  <td>
    {{ item.firstName }}
  </td>
  <td>
    {{ item.lastName }}
  </td>
  <td>
    {{ _get(item, 'group.name', 'None') }}
  </td>
  <td class="min-w-[7rem]">
    <component :is="renderers.TimeFormatDateOnly" :epochTime="item.dateCreated" />
  </td>
  <!-- 
  <td>
    {{ item.parentName }}
  </td>
  <td class="used-by-cell">
    <component :is="renderers.UsedBy" :items="item.locations" label="Campaigns" />
  </td> -->
</template>

<script lang="ts" setup>
import _get from 'lodash/get';
const props = defineProps<{
  item: any;
  itemIndex: any;
  ItemActionsButtons?: any;
  ItemTableSelectBox?: any;
  columnsConfig: any;
  renderers: any;
  filteredListItems: any;
  apiPathsConfig?: any;
  listerConfig?: any;
  selectMode?: boolean;
  singleSelect?: boolean;
}>();

const {
  areaAccess
} = props.listerConfig;
</script>

<style lang="scss" scoped>
.title {
  color: #25294A;
  text-decoration: none;
  cursor: pointer;
  max-width: 200px;
  display: inline-block;
}
.title:hover {
  text-decoration: underline;
}

.name-cell {
  word-break: break-word;
  min-width: 10rem;
}

.used-by-cell {
  min-width: 3.6rem;
}

.last-connected-cell {
  min-width: 11.5rem;
}
</style>