<template>
  <div class="create-slide-form">
    <h1 class="title separetor">CREATE ROLE </h1>
    <div class="status-bar-container">
      <StatusBar :errorMessage="errorMessage" :isLoading="/* true ||  */isLoading" loadingMessage="Saving..."
        :isSuccess="isSuccess" successMessage="Saved!" />
    </div>
    <div class="create-user ">
      <div class="form-fields">
        <!-- TODO: think about adding below-field error message & error state indication (e.g. red border) [DSP-N26CxI4P] -->
        <div class="formGroup">
          <label for="name"> Name &#9733;</label>
          <TextField v-model="values.name" width="100%" />
        </div>
        <div class="formGroup mt-3">
          <h3 class="access-title" for="name"> Application Areas </h3>
          <a class="change-link  mx-2" @click="grantAllArea()">Grant All</a> 
          <a class="change-link " @click=revokeAllArea()>Revoke All</a>
        </div>
        <div>
          <div class="formGroup" v-for="item of createModel?.commonEntity.roleAreaAccesses ">
          <label for="name">{{ item.area.name }}</label>
          <div class="access-level d-flex items-center py-2" :class="{'active':item.access> accessLevels[0].value}">
            <input type="radio" class="mx-1" :id="'noAccess'+item.area.name" v-model="item.access" :value="accessLevels[0].value" @change="hasChanges=true"  >
          <label :for="'noAccess'+item.area.name"> No Access</label>
          </div>
          <div class="access-level d-flex items-center py-2" :class="{'active':item.access >= accessLevels[1].value}">
            <input type="radio"  class="mx-1" :id="'View'+item.area.name" v-model="item.access" :value="accessLevels[1].value"  @change="hasChanges=true" >
          <label :for="'View'+item.area.name">View</label>
         </div>
          <div class="access-level d-flex items-center py-2" :class="{'active':item.access >= accessLevels[2].value}">
            <input type="radio"  class="mx-1" :id="'Edit'+item.area.name"  v-model="item.access" :value="accessLevels[2].value"  @change="hasChanges=true" >
          <label :for="'Edit'+item.area.name"> Edit</label>
          </div>
          <div class="access-level d-flex items-center py-2" :class="{'active':item.access >= accessLevels[3].value}">
            <input type="radio"  class="mx-1" :id="'Create'+item.area.name"  v-model="item.access" :value="accessLevels[3].value"  @change="hasChanges=true" >
          <label :for="'Create'+item.area.name"> Create</label>
          </div>
          <div class="access-level d-flex items-center py-2" :class="{'active':item.access == accessLevels[4].value}">
            <input type="radio"  class="mx-1" :id="'Delete'+item.area.name" v-model="item.access" :value="accessLevels[4].value"  @change="hasChanges=true" >
          <label :for="'Delete'+item.area.name"> Delete</label>
          </div>
        
        </div>
  
        
        </div>

        <div class="formGroup mt-3">
          <h3 class="access-title" for="name"> Media Types </h3>
          <a class="change-link  mx-2" @click="grantAllAsset()">Grant All</a> 
          <a class="change-link " @click="revokeAllAsset()">Revoke All</a>
        </div>
        <div>
          <div class="formGroup" v-for="item of createModel?.commonEntity.roleAssetTypeAccesses ">
          <label for="name">{{ findAssetById(item.assetType) }}</label>
          <div class="access-level d-flex items-center py-2" :class="{'active':item.access> accessLevels[0].value}">
            <input type="radio" class="mx-1" :id="'noAccess'+item.assetType" v-model="item.access" :value="accessLevels[0].value" @change="hasChanges=true"   >
          <label :for="'noAccess'+item.assetType"> No Access</label>
          </div>
          <div class="access-level d-flex items-center py-2" :class="{'active':item.access >= accessLevels[1].value}">
            <input type="radio"  class="mx-1" :id="'View'+item.assetType" v-model="item.access" :value="accessLevels[1].value"  @change="hasChanges=true" >
          <label :for="'View'+item.assetType">View</label>
         </div>
          <div class="access-level d-flex items-center py-2" :class="{'active':item.access >= accessLevels[2].value}">
            <input type="radio"  class="mx-1" :id="'Edit'+item.assetType"  v-model="item.access" :value="accessLevels[2].value" @change="hasChanges=true" >
          <label :for="'Edit'+item.assetType"> Edit</label>
          </div>
          <div class="access-level d-flex items-center py-2" :class="{'active':item.access >= accessLevels[3].value}">
            <input type="radio"  class="mx-1" :id="'Create'+item.assetType" v-model="item.access" :value="accessLevels[3].value" @change="hasChanges=true" >
          <label :for="'Create'+item.assetType"> Create</label>
          </div>
          <div class="access-level d-flex items-center py-2" :class="{'active':item.access == accessLevels[4].value}">
            <input type="radio"  class="mx-1" :id="'Delete'+item.assetType" v-model="item.access" :value="accessLevels[4].value" @change="hasChanges=true" >
          <label :for="'Delete'+item.assetType"> Delete</label>
          </div>
        
        </div>
  
        
        </div>

      </div>
    </div>

    <div class="form-buttons">
      <ButtonCustomized label="Exit" v-if="!hasChanges" @click="onExit" secondary />
      <BeforeExitDialogue v-if="hasChanges" @save="submitAndExit" @exit="onExit"> </BeforeExitDialogue>
      <ButtonCustomized label="Save" @click="onSubmit" :disabled="!hasChanges" />
      <Saving ref="savingDialog"></Saving>
    </div>
  </div>
</template>
  
<script lang="ts" setup>
import TextField from "@/components/form-fields/TextField.vue";
import useCreateRoleForm from "./CreateRoleHelper";
import ButtonCustomized from "@/components/form-fields/ButtonCustomized.vue";
import StatusBar from "@/components/form-fields/StatusBar/StatusBar.vue";
import useNotifications from "@/components/Notifications/composables/notifications";
import router from '@/router';
import { onBeforeMount, ref } from "vue";
 import BeforeExitDialogue from '@/components/Dialog/BeforeExitDialog/BeforeExitDialogue.vue';
 import Saving from '@/components/Dialog/SavingDialog/Saving.vue'

const notification = useNotifications();
const savingDialog = ref ()
const { values, submitForm, isLoading, isSuccess, errorMessage, accessLevels,
  newRoleId  ,hasChanges,removeBeforeLoad,getRoleModel
  ,createModel,findAssetById} = useCreateRoleForm();
  //getRoleModel();
onBeforeMount(async () => {
   await getRoleModel();

})

async function onSubmit() {
  await submitForm();
  if (isSuccess.value === true) {
    hasChanges.value = false 
    notification.success('Role created successfully!');
    router.push({ name: 'role-editor', params: { id: newRoleId.value } });
  } 
}
  async function submitAndExit() {
  savingDialog.value.open()
  await submitForm();
  savingDialog.value.close()
  if (isSuccess.value === true) {
    notification.success('Role created successfully!');
    removeBeforeLoad();
    router.push({ name: 'role-editor', params: { id: newRoleId.value } });
  }
}

function onExit() {
  removeBeforeLoad()
  router.push({ name: 'roles'});
}
function grantAllArea (){
  createModel.value.commonEntity.roleAreaAccesses.forEach((item:any )=> {
    item.access= accessLevels[4].value
  })
}
function revokeAllArea (){
  createModel.value.commonEntity.roleAreaAccesses.forEach((item:any )=> {
    item.access= accessLevels[0].value
  })
}
function grantAllAsset (){
  createModel.value.commonEntity.roleAssetTypeAccesses.forEach((item:any )=> {
    item.access= accessLevels[4].value
  })
}
function revokeAllAsset (){
  createModel.value.commonEntity.roleAssetTypeAccesses.forEach((item:any )=> {
    item.access= accessLevels[0].value
  })
}
</script>
  
<style lang="scss" scoped>
    .change-link {
    border: 0;
    cursor: pointer;
    display: inline-block;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 6px;
    text-transform: uppercase;
    color: #25294A;
    font-weight: bold;
  }
  .change-link:hover{
    text-decoration: underline;
}
.active {
  background-color: #3caf13
}


.separetor {
  border-bottom: 1px solid #e6e6e6;
}

.status-bar-container {
  margin-bottom: 1rem;
}

.create-user {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;

  @include medium-devices-down {
    flex-direction: column;
  }
}

.create-slide-form {
  display: flex;
  flex-direction: column;
}

.form-fields {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.formGroup {
  display: flex;
  align-items: center;

  .select {
    width: 60%;
    margin: 0;
  }

  label {
    display: inline-block;
    width: 200px;
    color: #262626;
    font-weight: bold;
  }
}
.access-level{
  label{
    width:fit-content !important
  }
}
.access-title {
  width: 200px; 
}
.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1rem;
}
</style>
  