<template>
    <div @click="toggleCollapse" :class='["title", titleClassName]'>
        <CaretDownIcon :class="['icon', { expanded: !isCollapsed }]" />
        <slot name="title">{{ title }}</slot>
    </div>
    <div :class="['collapsible-content', { expanded: !isCollapsed }, contentClassName]">
        <slot></slot>
    </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import CaretDownIcon from "@/components/icons/CaretDownIcon.vue";
defineProps(["title", "contentClassName", "titleClassName"]);
const isCollapsed = ref(true);
function toggleCollapse() {
    isCollapsed.value = !isCollapsed.value;
}
</script>
<style scoped lang="scss">
$transitionTime: 0.3s;

.title {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    .icon {
        width: 20px;
        transform: rotate(270deg);

        // transition: transform $transitionTime;
        &.expanded {
            transform: rotate(0deg);
        }
    }
}

.collapsible-content {
    overflow: hidden;
    transition: max-height,padding $transitionTime ease-out;
    max-height: 0;
    margin-left: 1.35rem;
    background-color: rgb(128 128 128 / 8%);
    border-radius: 0.375rem;
    margin-right: 1rem;
    padding: 0;
    /* opacity: 0; */
    &.expanded {
        /* max-height: 300px; */
        max-height: fit-content;
        /* opacity: 1; */
        padding: 0.375rem;
    }
}
</style>