const baseApiPaths = {
  'initialize': 'players',
  'search': 'playersearch'
}

const actionsApiPaths = {
  'export': 'players/export',
  'delete': 'api/players',
  'edit': 'players/edit'
}

const apiPathsConfig = {
  ...baseApiPaths,
  ...actionsApiPaths
}

export default apiPathsConfig;