import { defineStore } from 'pinia';
import { ref, computed, reactive, watch, onMounted } from 'vue';
import dataSourcesConfigured from '@/config/dataSources';
import useUser from '@/composables/user';
import type { DataSource } from '@/components/TopHeader/SideBars/DataSourceSwitcher/DataSource';
import { log } from '@/helpers/utils';


export const useDataSourceStore = defineStore('dataSource', () => {
  const dataSources = reactive(dataSourcesConfigured);
  onMounted(initiateActivatePreviouslySetDataSource);
  
  const isInitiated = ref<boolean>(false);
  const activeDataSource = computed(() => dataSources.find(item => item.isActive === true));
  if(!isInitiated.value) {
    initiateActivatePreviouslySetDataSource();
    isInitiated.value = true;
  }
  const activeDataSourceBaseUrl = computed(() => {
    const activeSource_baseUrl = activeDataSource.value?.baseUrl || "";
    const isDev = import.meta.env.DEV;
    if ( activeSource_baseUrl === "relative" ||  activeSource_baseUrl === "") {
      return window.location.origin
    } else if (!activeSource_baseUrl.match("^https?://")) {
      return window.location.origin+activeSource_baseUrl;
    }
    const baseUrlToReturn = isDev ? activeSource_baseUrl : activeSource_baseUrl;
      // TODO: to think about making the baseUrlToReturn some fixed value on production and disabling the data source
    return baseUrlToReturn;
  })


  const user = useUser();
  // const sources = computed(() => {
  //   const sourcesToReturn: any = {};
  //   for (let i = 0; i < dataSources.length; i++) {
  //     const source = dataSources[i];
  //     sourcesToReturn[source.name] = source
  //   }
  //   return sourcesToReturn
  // })

  function initiateActivatePreviouslySetDataSource() {
    const activeDataSourceName_fromLocalStorage = localStorage.getItem('data-source') as string;
    if(!(activeDataSourceName_fromLocalStorage === null)) {
      activateDataSource(activeDataSourceName_fromLocalStorage);
    } else {
      activateDefaultDataSource();
    }
  }

  function deactivatePreviouslyActiveDataSource() {
    const previouslyActiveSource = dataSources.find(item => item.isActive) as DataSource;
    console.log({previouslyActiveSource})
    previouslyActiveSource.isActive = false;
  }

  function activateDataSource(nameOrObject: string | DataSource) {
    let sourceToSetAsActive: DataSource;
    if (typeof nameOrObject === 'string') {
      const sourceName = nameOrObject;
      sourceToSetAsActive = dataSources.find(item => item.name === sourceName) as DataSource;  
    } else if (typeof nameOrObject === 'object') {
      sourceToSetAsActive = nameOrObject;
    } else {
      throw new Error('nameOrObject: nameOrObject is not a string or an object');
    }

    if (sourceToSetAsActive === undefined) {
      log("Previously stored dataSource doesn't match any dataSource in dataSources list. It's going to be set to default.", {color: 'red', ns: 'Warning'})
      activateDefaultDataSource();
      return;
    }
    sourceToSetAsActive.isActive = true;
  }

  function activateDefaultDataSource() {
    const defaultDataSource = dataSources.find(item => item.isDefault) as DataSource;
    if(!defaultDataSource) {
      throw new Error('Please set a default data source');
    }
    activateDataSource(defaultDataSource);
  }

  function selectDataSource(name: string) {
    console.log("hello")
    localStorage.setItem('data-source', name);
    deactivatePreviouslyActiveDataSource();
    activateDataSource(name);
    user.logout();
    
    // location.reload();
  }

  return {
    activeDataSourceBaseUrl,
    dataSources,
    selectDataSource
  }
});


export default useDataSourceStore;