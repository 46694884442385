// Defines filters display order and labels in UI

const filtersDisplayConfig = [
  {
    "name": "FILTER_GROUP",
    "label": "Organizations",
    "type": "selectMenu"
  }
];

export default filtersDisplayConfig;