<template>
  <!-- First brought from: https://headlessui.com/vue/dialog -->
  <slot name="expander2" v-bind="{openModal, closeModal, closeModalAfter}"></slot>  <!-- TODO: temp to clean ASAP -->
    <!-- TODO: "expander2" is a temporary workaround in order not to break styling that needs a div wrapper by replacing "expander".  -->
      <!-- TODO: think about cleaning "expander2" later may be by adding a side option and making the default without a div wrapper -->
  <div v-if="!$slots.expander2" class="flex items-center justify-center" v-bind:class="expanderClass">
    <!-- TODO: think / find if there's a cleaner or more readable way to way to pass the click event handler from slot outlet to the slet inlet [DSP-N21DK5BS]  -->
    <slot name="expander" v-bind="{openModal, closeModal, closeModalAfter}"></slot>
  </div>
  <!-- 
    Note: Stop propagation modifier is important for not unnecessarily triggering items deselct event — N2FCUcEt
    TODO: to think if there's a cleaner way to hand unnecessarily items deselect trigger — N2FCE8BF
  -->
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>
  
      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              :class='
                [
                  panelClass,
                  "transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all",
                  {"w-[100vw]": size == "fullscreen"},
                  {"h-[98vh]": size == "fullscreen"},
                  {"w-full max-w-screen-xl": size == "full"},
                  {"h-[90vh]": size == "full"},
                ]'
            >
              <DialogTitle
                as="div"
                class="dialog-title"
              >
                <div class="flex gap-2 items-center">
                  <h3><slot name="dialog.title"></slot></h3>
                  <slot name="dialog.title-after"></slot>
                  <div class="flex w-full justify-end" v-if="$slots['dialog.top-right']">
                    <slot name="dialog.top-right" v-bind="{closeModal, closeModalAfter}"></slot>
                  </div>
                </div>
              </DialogTitle>
              <h2 v-if='$slots["dialog.subtitle"]' :class='["dialog-subtitle", "mb-4"]'>
                <slot name="dialog.subtitle"></slot>
              </h2>
              <button v-if="!disableCloseButton" class="close-button" @click.stop="closeModal">
                <CloseIcon />
              </button>
              <!--
                TODO: think if there's a better way to handle theme styling,
                  may be teleport (portal) the whole dialog rendering to be inside
                  and at the end of the ThemeProvider (like done in Notifications) -->
              <ThemeProvider>
                <slot name="dialog.content" v-bind="{openModal,closeModal, closeModalAfter}"></slot>
              </ThemeProvider>
  
              <!-- <div class="mt-4">
                <button
                  type="button"
                  class="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                  @click="closeModal"
                >
                  Got it, thanks!
                </button>
              </div> -->
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  
  </template>
  
  <script lang="ts" setup>
  import { ref } from 'vue'
  import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
  } from '@headlessui/vue';
  import CloseIcon from "@/components/icons/CloseIcon.vue";
  import ThemeProvider from '@/components/ThemeProvider';
  
  export interface Props {
    size?: string | number;
    panelClass?: string;
    expanderClass?: string;
    disableCloseButton?: boolean;
  }

  const props = withDefaults(defineProps<Props>(), {
    disableCloseButton: false
  });
  
  const isOpen = ref(false)
  
  function closeModal() {
    isOpen.value = false
  }
  
  function closeModalAfter(delay: number = 1500) {
    setTimeout(closeModal, delay)
  }
  
  function openModal() {
    isOpen.value = true
  }
  
  defineExpose({
    openModal ,
    closeModal,
    closeModalAfter
  })
  </script>
  
  <style lang="scss" scoped>
  .close-button {
    position: absolute;
    right: 1em;
    top: 1em;
    &:focus {
      outline: 0;
    }
  }
  
  .dialog-title {
    h3 {
      font-size: 32px;
    }
    color: #25294A;
    font-weight: bold;
    margin-right: 13px;
    text-transform: none;
    margin-top: 15px;
  }

  .dialog-subtitle {
    color: #25294A;
  }
  
  </style>