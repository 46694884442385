import { submitUpdatePOSSource } from "@/helpers/api/posSource/update";
import { ref, watch } from "vue";
import { useForm } from "vee-validate";
import * as yup from 'yup';
import usePOSSource from "./posSource";
  // TODO: to think about: moving "data" out of here into the parent caller and make it passed via "submitForm" function

export default function savePOSSourceFormHelpers() {
  const { data } = usePOSSource();

  const isLoading = ref(false);
  const isSuccess = ref(false);
  const isError = ref(false);
  const errorMessage = ref("");
  const newSlideId = ref("");

  const validationSchema = yup.object({
    name: yup.string().label('Name').required(),
    posSourceUrl: yup.string().label('POS Source URL').required(),
  });

  const initialValues = {
    name: "",
    posSourceUrl: "",
  };

  watch(data, () => {
    // update form values on data change;
    values.name = data.name;
    values.posSourceUrl = data.posSourceUrl;
  })
  const { errors, handleSubmit, values, setValues, isSubmitting } = useForm({ validationSchema, initialValues });
  
  const submitForm = handleSubmit(handleValidFormSubmit, handleInvalidFormSubmit);

  async function handleValidFormSubmit() {
    try {
      errorMessage.value = "";
      isLoading.value = true;
      const submissionResponse = await submitUpdatePOSSource(data);
      isLoading.value = false;
      if (typeof submissionResponse[0].name === "string") {
        // if is successful
        isSuccess.value = true;
      }
    } catch (error) {
      isError.value = true;
      isLoading.value = false;
      errorMessage.value = `Something went wrong. (${error})`;
    }
  }

  async function handleInvalidFormSubmit() {
    let errorsAugmentedInSingleMessage = "";
    // TODO (cleaner code): think if there's a cleaner way to write this [DSP-N26Cti4A]
    if(Object.keys(errors.value).length){
      Object.keys(errors.value).forEach((key) => {
        errorsAugmentedInSingleMessage += errors.value[key]+". ";
      });
    }
    errorMessage.value = errorsAugmentedInSingleMessage;
  }

  return {
    values,
    submitForm,
    isError,
    isSuccess,
    isLoading,
    errorMessage,
  };
}