<template>
  <!-- TODO: think about un-duplicating similarities between some other component     [DSP-N1QAEm1F]
        {repo}\src\components\form-fields\NumberField.vue
        {repo}\src\components\form-fields\SeachBar\SeachBar.vue
  -->
<div :class='["field", {"flex-row-reverse": !suffixLabel}, "gap-2"]'>
  <input
    type="checkbox"
    :name="generatedFieldName"
    :id="generatedFieldName"
    v-model="checked"
    @change="emitValue"
  />
  <label :for='generatedFieldName' v-if="label">{{ label }}<template v-if="required"> &#9733;</template></label>
</div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

const props = defineProps({
  'modelValue': {
    type: Boolean
  },
  'label': {
    type: String,
    default: ""
  },
  'suffixLabel': {
    type: Boolean,
    default: true
  },
  'name': {
    default: ""
  },
  'maxlength': {
    default: "60"
  },
  'required': {
    default: false
  },
  'modelModifiers': { default: () => ({}) },
})

const emit = defineEmits(['update:modelValue'])
const checked = ref(props.modelValue)
const generatedFieldName = props.name ? `checkbox-${props.name}`: "";

function emitValue(event: Event) {
  // const value = parseForModelModifiers(props.modelModifiers, event.target.value);
  console.log({value: checked.value})
  emit('update:modelValue', checked.value)
}
</script>

<style lang="scss" scoped>
/* TODO:
    [ ] to think about a clean way for how to have cross-components common styles
 */

.field {
  display: flex;
  align-items: center;
  
  label {
    color: #262626;
    font-weight: bold;
    cursor: default;
    margin-right: 1.2rem;
  }
  /* margin-right: 0.7rem; */

  input[type='text'] {
    color: #666666;
    border: 1px solid #abadb3;
    height: 2.2rem;
    padding: 2px 2px 1px 2px;
    @include small-devices-down {
      width: auto;
      flex-grow: 1;
    }
    outline:none;
  }
}


</style>