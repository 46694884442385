<template>
    <h2>Uptime</h2>
    <div class="d-flex justify-between">
        <div>
            <p class=""> <span class="font-bold">
        Date Last Connected 
    </span>
      <TimeFormat :epochTime="lastConnectTime"> </TimeFormat>  
    </p>
        </div>
        <div>
            <select name="ranges" id="ranges" class="select" @change="changeView($event)" v-model="uptimeViewRange">
    <option :value="range.id" v-for="range of uptimeViewRanges">{{ range.name }}</option>
  </select>
        </div>
    </div>



    <div id="uptime-graph" style="width:100%; height:400px;"></div>

    <div class="mt-3">
        <p  class="font-bold">Uptime Details</p>
        <table>
        <thead>
          <tr class="table-separetor">
            <th>Start Date</th>
            <th>End Date</th>
          </tr>
        </thead>
        <tbody class="table-list">
                <tr class="table-row"  v-for="(range,index) of displayedRanges">
            <td>
                {{ getTimeFormated(range.dateStarted) }}
                <!-- <TimeFormatDateOnly :epochTime=""> </TimeFormatDateOnly> -->
                 </td>
            <td>  
                {{ getTimeFormated(range.dateEnded) }}
                <!-- <TimeFormatDateOnly :epochTime="range.dateEnded"> </TimeFormatDateOnly>  -->
            </td>
        </tr>      
        </tbody>
      </table>
    </div>
    <div v-if="timeRanges?.length>0">
      <PageNavigator :recordsLength="timeRanges?.length" @changeOffset="changeTableIndex($event)"> </PageNavigator>
    </div>
</template>

<script setup>
import {onMounted , ref } from 'vue'
import Highcharts from 'highcharts'
import {searchUptime} from '@/helpers/api/player/player'
import TimeFormat from '@/components/ListingPages/ItemsLister/ItemsList/FieldsRenderers/TimeFormat.vue'
import TimeFormatDateOnly from '@/components/ListingPages/ItemsLister/ItemsList/FieldsRenderers/TimeFormatDateOnly.vue'
import  PageNavigator from './PageNavigator.vue'
const props = defineProps(['playerId','lastConnectTime'])
const uptimeViewRanges = ref (	[
		{ id: "day", name: "Last 24 Hours", numDays: 1 },
		{ id: "week", name: "Last Week", numDays: 7 },
		{ id: "month", name: "Last Month", numDays: 30 },
		{ id: "year", name: "Last Year", numDays: 365 }
	])
const uptimeViewRange = ref (uptimeViewRanges.value[0].id)
const timeRanges = ref ()
const tableOffset = ref (0)
const lastIndex = ref (25)
const displayedRanges = ref ([])
//784441
onMounted(() => {

    searchUptimeDetail(uptimeViewRanges.value[0].id)
})

function setHighChart (uptimeResult) {
    var data = [];
        var utcOffset = new Date().getTimezoneOffset() * -60000;
        console.log(utcOffset)
        var min = new Date();

        switch (uptimeViewRange.value)
        {
            case "day":
                min.setTime(min.getTime() - 86400000);
                break;

            case "week":
                min.setTime(min.getTime() - 604800000);
                break;

            case "month":
                min.setTime(min.getTime() - 2592000000);
                break;

            case "year":
                min.setTime(min.getTime() - 31536000000);
                break;
        }

        min = min.getTime();
        min += utcOffset;
        var max = new Date().getTime() + utcOffset;

        var results = uptimeResult.slice().reverse();

        for (let i = 0; i < results.length; i++)
        {
            const uptime = results[i];

            const startDate = parseInt(uptime.dateStarted) + utcOffset;
            const endDate = parseInt(uptime.dateEnded) + utcOffset;
            const hours = Math.round((endDate - startDate) / 36000) / 100;

            data.push([startDate, 0]);
            data.push([endDate, hours]);
            data.push([endDate+1, 0]);
        }

        //series[0].xAxis.setExtremes(min, max);
      //  series[0].setData(data);
  const uptimeChart=  Highcharts.chart('uptime-graph', {
        chart:
            {
                type: 'area'
            },
            tooltip:
            {
               
            },
            colors: ["#389edf"],
            legend:
		    {
		        enabled: false
		    },
            title:
		    {
		        text: 'Uptime Chart'
		    },
            xAxis:
		    {
		        showFirstLabel: true,
		        type: 'datetime',
		        startOnTick: false,
		        min: min,
		        max: max,
		        crosshair: {
		            width: 1,
		            color: 'rgb(151 151 151 / 70%)'
		        }
		    },
            yAxis:
		    {
		        showFirstLabel: false,
		        title:
                {
                    text: 'Hours'
                },
                crosshair: {
                    width: 1,
                    color: 'rgb(151 151 151 / 70%)'
                }
		    },
            series:
            [
                {
                    name: "Uptime (Hours)",
                    data: data,
                   
                   
                   
                }
            ],
            credits:
		    {
		        enabled: false
		    }
        });

      //  uptimeChart.series[0].xAxis.setExtremes(min, max);
}
async function searchUptimeDetail(rangeId){
  const daysNum = uptimeViewRanges.value.find(range => range.id === rangeId).numDays;
  const result = await searchUptime(props.playerId, daysNum)
  timeRanges.value = result.uptimes
  displayedRanges.value = timeRanges.value.slice(0,25)
  setHighChart (result.uptimes )
}
function changeView(event)
{ searchUptimeDetail(event.target.value)
   
}
function changeTableIndex (offsets) {
    tableOffset.value = offsets.offset;
    lastIndex.value = offsets.lastIndex
    displayedRanges.value = timeRanges.value.slice(tableOffset.value,lastIndex.value)

}
function getTimeFormated (time) {
    const date = new Date(time);

   return  date.toLocaleDateString('en-GB', {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  hour : 'numeric',
  minute : 'numeric',
  second : 'numeric',
  hourCycle: 'h23'

});
}
</script>
<style lang="scss" scoped>
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
.table-row {
    border: 1px solid #d6d6d6;
    border-left: none;
    border-right: none;

}
th,
td {
  text-align: left;
  padding: 8px;
}
.table-list {
    box-shadow: 0px 3px 17px #00000029;
    border-radius: 4px;
}
.select {
    border: 1px solid #abadb3;
    height: 35px;
    padding: 2px 2px 1px 2px;
    text-align: right;
    outline:none;
    background: transparent;
    left: 0;
    font-size: 14px;
    top: 0;
    z-index: 2;
    cursor: pointer;
    padding: 9px;

    &:disabled {
      margin-right: auto;
      background-color: #e6e6e6;
      color: #999999;
    }
}
</style>