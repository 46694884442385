<template>
  <div class="create-slide-form">
    <div class="status-bar-container">
      <StatusBar
        :errorMessage="errorMessage"
        :isLoading="/* true ||  */isLoading"
        loadingMessage="Saving..."
        :isSuccess="isSuccess"
        successMessage="Saved!"
      />
    </div>
    <div class="form-fields">
      <!-- TODO: think about adding below-field error message & error state indication (e.g. red border) [DSP-N26CxI4P] -->
      <TextField
        label="Name"
        name="name"
        v-model="values.name"
        required
        class="field"
      />
      <SelectField
        label="Dimensions"
        :options="dimensionsOptionsConfig"
        v-model="values.dimensions"
        class="field"
        required
      />
      <SelectField
        label="Orientation"
        :options="orientationOptionsConfig"
        v-model="values.orientation"
        required
        class="field"
      />
      <NumberField
        label="Width"
        name="width"
        v-model="values.width"
        :disabled="values.dimensions != ''"
        required
        class="field"
      />
      <NumberField
        label="Height"
        name="height"
        v-model="values.height"
        :disabled="values.dimensions != ''"
        required
        class="field"
      />
      <ColorField
        v-model="values.backgroundColor"
        required
      >
        <template #label>Background <br />color</template>
      </ColorField>
    </div>
    <div class="form-buttons">
      <ButtonCustomized label="Cancel" @click="onCancel" :secondary="true" />
      <ButtonCustomized label="Create" @click="onSubmit" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import SelectField from "@/components/form-fields/SelectField/SelectField.vue";
import NumberField from "@/components/form-fields/NumberField.vue";
import TextField from "@/components/form-fields/TextField.vue";
import ColorField from "@/components/form-fields/ColorField/ColorField.vue";
import ButtonCustomized from "@/components/form-fields/ButtonCustomized.vue";
import StatusBar from "@/components/form-fields/StatusBar/StatusBar.vue";

import useCreateSlideFormHelpers from "./composables/createSlideFormHelpers";
import useNotifications from "@/components/Notifications/composables/notifications";

import router from '@/router';

import {
  dimensionsOptionsConfig,
  orientationOptionsConfig
} from "./config/options";

const emit = defineEmits(['submit', 'cancel', 'success', 'fail'])

const notification = useNotifications();

const { values, submitForm, isLoading, isSuccess, errorMessage, newSlideId } = useCreateSlideFormHelpers();

async function onSubmit() {
  await submitForm();
  if(isSuccess.value === true) {
    notification.success('Slide created successfully!');
    emit('success');
    router.push({name: 'media-editor', params: {id: newSlideId.value}});
  } else {    // on failure
    emit('fail');
  }
}

function onCancel() {
  emit('cancel');
}
</script>

<style lang="scss" scoped>

.status-bar-container {
  margin-bottom: 1rem;
}

.create-slide-form {
  display: flex;
  flex-direction: column;
  max-width: 20rem;
}
.form-fields {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.field {
  :deep(label) {
    width: 110px;
  }
  :deep(.select) {
    width: 60%;
  }
}
.formGroup {
  display: flex;
  align-items: center;
  .select {
    width: 60%;  // 
    margin: 0;
  }
  label {
    display: inline-block;
    width: 110px; //
    color: #262626;
    font-weight: bold;
  }
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1rem;
}
</style>
