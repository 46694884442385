<template>
  <content-with-loading-spinner :class="['reload-button-container']" :isLoading="isLoading">
    <v-tooltip :text="tooltipText">
      <template v-slot:activator="{ props }">
        <div
          class="reload-button"
          :style="{ 'background-image': backgroundsUrls['reload'] }"
          v-bind="props"
          @click="reloadHandler"
        >
        </div>
      </template>
    </v-tooltip>
  </content-with-loading-spinner>
</template>

<script lang="ts" setup>
import { useImportedPathsAsCssUrls } from '@/composables/utils/importedPaths';
import reloadButtonImageUrl from './icons/reload-button-hover.png';
defineProps<{
  isLoading: boolean;
  tooltipText: string;
  reloadHandler: () => void;
}>()

const backgroundsUrls = useImportedPathsAsCssUrls({
  "reload": reloadButtonImageUrl,
});

</script>

<style lang="scss" scoped>
.reload-button {
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-bottom: 2px;
}
</style>