import { ref } from 'vue';
import {updateDevelopmentStats} from './utils';

export const isDevModeOn = ref(false);

export const devStageHandlers = {
  onMouseDown(e: any) {
  },

  onMouseMove(e: any) {
    updateDevelopmentStats(e)
  },

  onMouseUp(e: any) {
    
  },
  
  onMouseClick(e: any) {
  },
}

export default devStageHandlers;