const baseApiPaths = {
  'initialize': 'locations',
  'search': 'locationsearch'
}

const actionsApiPaths = {
  'export': 'locations/export',
  'delete': 'api/locations',
  'edit': 'locations/edit'
}

const apiPathsConfig = {
  ...baseApiPaths,
  ...actionsApiPaths
}

export default apiPathsConfig;