<template>
  <FieldsGroup>
    <FieldsRow>
      <!-- TODO: to come back and improve styling of the text box later to be somehow flexible with screen / container size — N2LFaZC5   -->
      <TextFieldWarpper
        :style='{
          width: "100%",
        }'
      >
        <label for="spriteText">
          Text
        </label>
        <input
          type="text"
          name="spriteText"
          v-model="selectedSprite.text"
          ref="selectedTextSpriteField"
        />
      </TextFieldWarpper>
    </FieldsRow>
  </FieldsGroup>
  <FieldsGroup label="Position">
    <FieldsRow>
      <NumberField label="X" v-model="selectedSprite.x" name="spriteX" maxlength="4" />
      <!--
        TODO later:
          [ ] add validation for numbers
          [ ] Think about using Veutify form instead of input
          [ ] Think about how to handle undo / redo feature compatibility
          [ ] Check if we can assign v-model to an object prop in like in the reverted commits.
            [ ] Also think about if this will produce cleaner / more extensible code
      -->
      <NumberField label="Y" v-model="selectedSprite.y" name="spriteY" maxlength="4" />
    </FieldsRow>
  </FieldsGroup>
  <FieldsGroup label="Size">
    <FieldsRow>
      <NumberField label="W" v-model="selectedSprite.width" name="spriteWidth" maxlength="4" />
      <NumberField label="H" v-model="selectedSprite.height" name="spriteHeight" maxlength="4" />
    </FieldsRow>
  </FieldsGroup>
  <FieldsGroup>
    <FieldsRow>
      <TextFontSelection
        v-model="selectedSprite.fontFamily"
        class="mb-2"
      />
    </FieldsRow>
    <FieldsRow>
      <!-- TODO: to come back and improve styling of the text box later to be somehow flexible with screen / container size — N2LFaZC5   -->
      <NumberField label="Font Size" name="spriteFontSize" v-model="selectedSprite.fontSize" />
    </FieldsRow>
    <FieldsRow>
      <!-- TODO: to come back and improve styling of the text box later to be somehow flexible with screen / container size — N2LFaZC5   -->
      <ColorField
        v-model="selectedSprite.fill"
        label="Text Color"
        class="mt-2"
        @colorFocusin="onMultiStepChangeStartHistoryHandler"
        @colorFocusout="onMultiStepChangeEndHistoryHandler({takeSnapshot: false})"
        @colorChange="onMultiStepChangeEndHistoryHandler()"
      />
    </FieldsRow>
    <div class="flex gap-3 mt-2">
      <TextStylingToggles
        v-model:fontStyle="selectedSprite.fontStyle"
        v-model:textDecoration="selectedSprite.textDecoration"
      />
      <TextAligmnentSelection
        v-model="selectedSprite.align"
      />
    </div>
  </FieldsGroup>
  <!-- <LinkWithPOSButton /> --> <!-- TODO (jul-2023): enable again when POS is ready -->
  <!-- Link with POS dynamic value (button) -->
</template>

<script lang="ts" setup>
import NumberField from '@/components/form-fields/NumberField.vue';
// import LinkWithPOSButton from './LinkWithPOS/ButtonAndDialog.vue';
import FieldsGroup from '@/components/form-fields/FieldsGroup.vue';
import FieldsRow from '@/components/form-fields/FieldsRow.vue';
import TextField from "@/components/form-fields/TextField.vue";
import ColorField from '@/components/form-fields/ColorField/ColorField.vue';
// import ColorField from "@/components/form-fields/ColorField/ColorField.vue";
import TextAligmnentSelection from './TextAlignmentSelection/TextAligmnentSelection.vue';
import TextStylingToggles from './TextStylingToggles/TextStylingToggles.vue';
import TextFontSelection from './TextFontSelection/TextFontSelection.vue';
import useSlide, { type FontFamily } from '@/views/MediaEditorView/SlideEditor/composables/slide';
import type { TextSprite } from '@/helpers/types/media/slide/Sprite';
import { type ComputedRef } from 'vue';
import TextFieldWarpper from '@/components/form-fields/TextFieldWrapper.vue';
import {
  onMultiStepChangeStartHistoryHandler,
  onMultiStepChangeEndHistoryHandler
} from '@/views/MediaEditorView/SlideEditor/composables/helpers/history';

interface UseSlideForTextSprite extends ReturnType<typeof useSlide> {
  selectedSprite: ComputedRef<TextSprite>
}

const {
  selectedSprite,
  selectedTextSpriteField
} = useSlide() as UseSlideForTextSprite;
</script>

<style lang="scss" scoped></style>