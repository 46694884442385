import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import VueKonva from 'vue-konva';
import VueProgressBar from "@aacassandra/vue3-progressbar";
import HighchartsVue from 'highcharts-vue'

// pinia
import { resetStore } from '@/plugins/reset-store';
const pinia = createPinia();
pinia.use(resetStore)

// main styles
import '@/assets/styles/main.css'
//import "bootstrap-icons/font/bootstrap-icons.css"

// Vuetify    | https://next.vuetifyjs.com/en/getting-started/installation/
// TODO: To remove from project as they didn't implement table component yet for Vue3 and gives errors if used
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
})


// Global components
import ContentWithLoadingSkeleton from '@/components/ContentLoadingSkeleton/ContentWithLoadingSkeleton.vue';
import ContentWithLoadingInlineDots from '@/components/ContentLoadingSkeleton/ContentWithLoadingInlineDots.vue';
import LoadingSkeleton from '@/components/ContentLoadingSkeleton/LoadingSkeleton.vue';
import ContentWithLoadingSpinner from '@/components/ContentLoadingSkeleton/ContentWithLoadingSpinner.vue';
import LoadingSpinner from '@/components/ContentLoadingSkeleton/LoadingSpinner.vue';

// progress bar
// TODO: think about removing these options as they don't seem helpful
const progressBarOptions = {
  color: "#bffaf3",
  failedColor: "#874b4b",
  thickness: "1rem",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: false,
  location: "top",
  inverse: false,
  autoFinish: false
};

const app = createApp(App)
app.use(pinia)
app.use(router)
app.use(VueKonva)
app.use(vuetify)
app.use(HighchartsVue)
app.use(VueProgressBar, progressBarOptions)
app.provide('progressBar', app.config.globalProperties.$Progress)
app.mount('#app')
app.component('content-with-loading-skeleton', ContentWithLoadingSkeleton);
app.component('content-with-loading-inline-dots', ContentWithLoadingInlineDots);
app.component('content-with-loading-spinner', ContentWithLoadingSpinner);
app.component('loading-skeleton', LoadingSkeleton);
app.component('loading-spinner', LoadingSpinner);