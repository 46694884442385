import useApi from '../api';

export async function submitUpdatePOSSource(data : any ){
  // To clean posted data
  const {put} = useApi();
  console.log({data});
  const itemId = data.id;
  const { responseBody } = await put('api/posSource/'+itemId,[data], {isLogoutOnInvalidAuth: true});
  
  const responseData = responseBody;
  return responseData;
}