<!--
  TODO: think about a shorter name for the component [DSP-N21NeS82]
 -->
<template>
  <!-- <span style="font-size: 7px">{{ isImageFresh }}</span> -->
  <img
    :class='["image-loaded", imageClass, imageLoadedClass]'
    :src="src+cacheUniqueKeySuffix"
    @error="handleError"
    @load="handleLoad"
    v-if="!(src==null) && !isError   /* imgUrl && !(src==null) && !isError */"
    ref="imageElement"
    style="display: none"
  />

  <!-- <div style="font-size: 8px;">{{ dateLastModified }}</div> -->  <!-- For Debugging -->
  <!-- Missing image placeholder -->
  <div
    :class='["image-placeholder image-error", imageClass, imageErrorClass]'
    :style="`background-image: ${backgroundsUrls['missing-image-icon']};`"
    ref="missingImagePlaceholder"
    v-if="isError || (src==null)"
  >
  </div>
  <!-- Loading placeholder -->
  <!-- TODO: continue loading indicator [DSP-N21NmF44] -->
  <div
    :class='["image-placeholder image-loading", imageClass, imageLoadingClass]'
    :style="`background-image: ${backgroundsUrls['preloader-image-icon']};`"
    ref="loadingPlaceholder"
    v-if="(!isLoaded && !isError && !(src==null))"
  >
  </div>
</template>

<script lang="ts" setup>
import { useImportedPathsAsCssUrls } from '@/composables/utils/importedPaths';
import { sleep } from "@/helpers/utils";
import { computed, ref, watch } from 'vue';
import missingImageIconUrl from './assets/missing-image-icon.png';
import preloaderImageIconUrl from './assets/preloader.gif';
import { nextTick } from 'vue';


// TODO: think about passing loading / error placeholder uris. [DSP-N21NYQ9s]
export interface Props {
  src: string
  imageLoadedClass?: string | string[]
  imageErrorClass?: string | string[]
  imageLoadingClass?: string | string[]
  imageClass?: string | string[]
  refreshAfter?: number
  refreshAfterIfRevalidate?: number
  dateLastModified?: number
  revalidateIfLessThanAge?: number
}

const props = withDefaults(defineProps<Props>(), {
  revalidateIfLessThanAge: 60
})
const { refreshAfterIfRevalidate, dateLastModified, revalidateIfLessThanAge } = props;

const imageElement = ref();
const retryCount = ref(0);
const freshImageMaxAge = 60*3;
  // if the image's dateLastModified is less than 90 seconds ago

const isImageFresh = computed(()=>{
  if(dateLastModified !== undefined) {
    const secondsElapsedSinceLastModified = (Date.now() - dateLastModified) / 1000;
    // console.log({secondsElapsedSinceLastModified})
    if (secondsElapsedSinceLastModified < freshImageMaxAge) {
      return true;
    }
  }
  return false;
});

const maxRetryCount = isImageFresh.value ? 30 : 2;
const retryDelay = isImageFresh.value ? 3000 : 1000;




// if video, add a delay, and re-request the after some time // may be also add a note that video may be getting rendered now.



// ------------------------- START ----- New way of retrieving image (still draft not fully done)

// const currentInitialImgUrlToTry = computed(() => {
//   return props.src+cacheUniqueKeySuffix.value;
// });

// const imgUrl = ref("");

// watch(retryCount, ()=> {
//   nextTick().then(() => {
//     updateImageUrl();
//   })
// })

// async function updateImageUrl() {
//   try {
//     const response = await fetch(currentInitialImgUrlToTry.value, {method: 'HEAD'});
//     const redirectUrl = response.url;
//     redirectUrl.match(/^htt/);
//     if(redirectUrl) {
//       imgUrl.value = redirectUrl+currentInitialImgUrlToTry.value;
//     } else {
//       imgUrl.value = currentInitialImgUrlToTry.value;
//     }
//   } catch (e) {
//     console.log("some error happened while requesting image source: "+e)
//     return imgUrl.value = ""
//   }
// }
// ------------------------- END ----- New way of retrieving image (still draft not fully done)

const cacheUniqueKeySuffix = computed(() => {
  if(retryCount.value == 0) {
    if(dateLastModified !== undefined) {
      const secondsElapsedSinceLastModified = (Date.now() - dateLastModified) / 60;
      if (secondsElapsedSinceLastModified < 60*3) { // if passed less than 3 minutes
        return "?elapsed="+secondsElapsedSinceLastModified;
      }
    }
    return "";
  }
  
  const dummyTimestamp = (Date.now()+retryCount.value).toString();
  return "?timestamp="+dummyTimestamp;
});

const isError = ref(false);
const isLoaded = ref(false);

const backgroundsUrls = useImportedPathsAsCssUrls({
  "missing-image-icon": missingImageIconUrl,
  "preloader-image-icon": preloaderImageIconUrl
});

async function handleError(e: any) {
  if(retryCount.value >= maxRetryCount) {
    isError.value = true;
    isLoaded.value = false;
    // console.log({retryCount: retryCount.value})
  }
  await sleep(retryDelay);
  retryCount.value++;
}

function handleLoad(e: any) {
  if(imageElement.value === null) {
    return;
  }
  imageElement.value.style.display = "block";
  isError.value = false;
  isLoaded.value = true;
}



if(dateLastModified !== undefined) {
  const isLessThanRevalidateAge = (Date.now() - dateLastModified) < revalidateIfLessThanAge;
  if(isLessThanRevalidateAge) {
    setTimeout(() => {
      retryCount.value++;
    }, refreshAfterIfRevalidate)
  }
}


// watch(refreshAfter, ()=> {
//   setTimeout(()=> {
//     retryCount.value++;
//   }, refreshAfter)
// })
</script>

<style lang="scss" scoped>


.image-placeholder {
  background-repeat: no-repeat;
  background-position: center center;
}

</style>