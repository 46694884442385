<template>
  <div class="relative">
    <ProgressBarForItemsList />
    <content-with-loading-skeleton
      :class='[
        "items-list",
        {
          "browser": itemPickDragMode
        }
      ]'
      :isLoading="isLoading"
    >
      <template #loaded>
        <!-- 
          Note: Stop propagation modifier is important for not unnecessarily triggering items deselct event — N2FCUcEt
          TODO: to think if there's a cleaner way to hand unnecessarily items deselect trigger — N2FCE8BF
        -->
        <table @click.stop="">
          <SortableTableHeaders
            v-model:sort-by-column-name="sortBy.fieldName"
            v-model:is-descending="sortBy.isDescending"
            :columns="columnsConfig"
            :config="{isCheckmarkColumn: !itemPickDragMode && areaAccess.hasWriteAccess}"
            class="table-headers"
          />
          <template v-if="!itemPickDragMode">
            <tbody>
              <tr
                v-for="(item, itemIndex) of filteredListItems"
                :key="item.id"
                :class='["list-item-custom", {"selected": isItemSelected(item.id)}]'
                @click.stop="selectHandler($event, item ,itemIndex)"
                @mouseenter="handleTableItemMouseEnter(item.id)"
                @mouseleave="handleTableItemMouseLeave"
              >
                <td class="w-4" v-if="areaAccess.hasWriteAccess || selectMode || singleSelect">
                  <ItemTableSelectBox
                    v-if="
                      (
                        user.hasWriteAccessToItem(item) &&
                        user.hasWriteAccessToAssetType(item?.assetType)
                      ) ||
                      selectMode ||
                      singleSelect
                    "
                    :item="item"
                    :itemIndex="itemIndex"
                    :disabled="singleSelect" />
                </td>
                <slot
                  name="item"
                  v-bind="{
                    item,
                    itemIndex,
                    ItemActionsButtons,
                    ItemTableSelectBox,
                    columnsConfig,
                    renderers,
                    filteredListItems,
                    apiPathsConfig,
                    listerConfig,
                    ...(selectMode && {selectMode}), // conditionally add props is important in order not to have errors in 'item' slot components that don't expect this prop
                    ...(singleSelect && {singleSelect}) // conditionally add props is important in order not to have errors in 'item' slot components that don't expect this prop
                  }"
                />
                <td class="w-12">
                  <ItemActionsButtons v-bind="{item, apiPathsConfig}" class="ml-1.5 mr-3" />
                </td>
              </tr>
            </tbody>
          </template>
          <template v-else> <!-- if itemPickDragMode -->
            <draggable
              tag="tbody"
              :list="filteredListItems"
              :group="{ name: 'media', pull: 'clone', put: false }"
              :clone="itemPickDragCloneCallback"
              :sort="false"
              itemKey="id"
              @start="draggableDragStartHandler"
            >
              <!-- TODO: think about how to resolve redundancy with above similar code - N36DDLEY -->
              <template #item="{ element: item, index: itemIndex }">
                <tr
                  :class='["list-item-custom"]'
                  @dragstart="itemDragStartHandler($event, item)"
                  @dragend="itemDragEndHandler($event, item)"
                  @drag="itemDragHandler"
                >
                  <td class="drag-component-cell" v-if="itemDragDropPlaceholderComponent">
                    <itemDragDropPlaceholderComponent v-bind="{item}" ref="itemDragDropPlaceHolderComponent" />
                  </td>
                  <!-- <td>
                    {{ item }}
                  </td> -->
                  <slot
                    name="item"
                    v-bind="{
                      item,
                      itemIndex,
                      ItemActionsButtons,
                      ItemTableSelectBox,
                      columnsConfig,
                      renderers,
                      filteredListItems,
                      apiPathsConfig,
                      listerConfig,
                    }"
                  />
                  <td class="w-12">
                    <ItemActionsButtons v-bind="{item, apiPathsConfig}" class="ml-1.5 mr-3" />
                  </td>
                </tr>
              </template>
            </draggable>
          </template>

        </table>
        <div v-if="!filteredListItems.length" class="p-8">
          No items matched your search.
        </div>
        <div
          class="drag-placeholder-floating-preview"
          v-if="currentDraggedItem"
          :style="styles_forDragPlaceholderFloatingPreview"
        >
          <itemDragDropPlaceholderComponent :item="currentDraggedItem" ref="itemDragDropPlaceHolderComponent" />
        </div>
      </template>
      <template #loading>
        <div class="mt-12 ml-10">
          <ItemsTableLoadingSkeleton :type="loadingSkeletonType" :count="loadingSkeletonCount"/>
        </div>
      </template>
    </content-with-loading-skeleton>
  </div>
</template>

<script lang="ts" setup>
import ThumbnailPreview from './ThumbnailPreview.vue';
import MediaItemPreview from "../../MediaItemPreview/MediaItemPreview.vue";
import ItemActionsButtons from '../ItemActionsButtons/ItemActionsButtons.vue';
import ItemsTableLoadingSkeleton from './ItemsTableLoadingSkeleton/ItemsTableLoadingSkeleton.vue';
import TimeFormat from '../FieldsRenderers/TimeFormat.vue';
import TimeFormatDateOnly from '../FieldsRenderers/TimeFormatDateOnly.vue';
import Size from '../FieldsRenderers/Size.vue';
import UsedBy from '../FieldsRenderers/UsedBy.vue';
import AlertsIndicator from '../FieldsRenderers/AlertsIndicator.vue';
import SortableTableHeaders from './SortableTableHeaders.vue';
// import columnsConfig from '../columns.config';
import useMediaListItemSelectionHelpers from '../composables/mediaListItemSelectionHelpers/mediaListItemSelectionHelpers';
import useItemTableSelectBoxHelpers from './TableSelectBox/itemTableSelectBoxHelpers';
import ItemTableSelectBox from "./TableSelectBox/ItemTableSelectBox.vue";
import ProgressBarForItemsList from '../ProgressBarForItemsList.vue';
import draggable from 'vuedraggable';
import useUser from '@/composables/user';
const user = useUser();

import { inject, reactive, ref, computed } from 'vue';
import { log } from '@/helpers/utils';

const props = defineProps<{
  singleSelect?: boolean;
  selectMode?:boolean
  // TODO (cleaner code): to move within "listerConfig" with "itemPickDragMode" and others
}>();

const itemsListHelpers = inject('itemsListHelpers');
const listerConfig = inject('listerConfig', {});

const {
  filteredListItems,
  isLoading,
  isValidating,
  sortBy,
  columnsConfig,
  tableViewConfig: { 
    loadingSkeleton: {
      type: loadingSkeletonType = 'withoutImage',
      count: loadingSkeletonCount = 5
    } = {}
  } = {},
  apiPathsConfig
} = itemsListHelpers;

const {
  areaAccess
} = listerConfig;
const {
  mediaListItemSelectHandler,
  isItemSelected
} = useMediaListItemSelectionHelpers(itemsListHelpers);

function selectHandler(event, item ,itemIndex) {
  if (
    (
      !areaAccess.hasWriteAccess ||
      !user.hasWriteAccessToItem(item) ||
      !user.hasWriteAccessToAssetType(item?.assetType)
    ) &&
    !(singleSelect || selectMode)
  ) return;
  mediaListItemSelectHandler(event, item, itemIndex);
}

const {
  handleTableItemMouseEnter,
  handleTableItemMouseLeave
} = useItemTableSelectBoxHelpers();

const renderers = {
  ThumbnailPreview,
  MediaItemPreview,
  TimeFormat,
  TimeFormatDateOnly,
  Size,
  UsedBy,
  AlertsIndicator
};


// TODO: think about making use of the ability to pass a slot and then getting item passed to the slot itself into generalizing the list component for other lists. [DS-N212NC3v]

const ListWrapper = "To contain the component that will wrap the list";
const ItemWrapper = "To contain the component that will wrap the item";



// Drag Item helpers - - - - - - - - - - START
const {
  itemPickDragMode,
  itemPickDragCloneCallback,
  itemDragDropPlaceholderComponent,
  singleSelect,
  selectMode
} = listerConfig;


const currentDraggedItem = ref();
const isShowDraggedItem = ref(false);

function itemDragStartHandler(event: any, item: any) {
  const blankImage = new Image();
  event.dataTransfer.setDragImage(blankImage, 10, 10);
  currentDraggedItem.value = item;
}

function itemDragEndHandler(event: any, item: any) {
  currentDraggedItem.value = null;
  isShowDraggedItem.value = false;
}

const mousePosition = reactive({
  x: null,
  y: null
});

function itemDragHandler(event: any) {
  mousePosition.x = event.clientX+1;
  mousePosition.y = event.clientY+1;
  isShowDraggedItem.value = true;
}

function draggableDragStartHandler(event: any) {
  log(event);
  // event.dataTransfer.setDragImage(new Image(), 0, 0);
}


const styles_forDragPlaceholderFloatingPreview = computed(() => {
  return {
    top: mousePosition.y+"px",
    left: mousePosition.x+"px",
    display: (isShowDraggedItem.value ? 'block' : 'none')
  }
})

// Drag Item helpers - - - - - - - - - - END
</script>

<style lang="scss" scoped>
.items-list {
  overflow-y: scroll;
  /* 45vh old max-height */
  max-height: v-bind('(singleSelect || selectMode ?  "40vh":"67vh" )');
  /* width: 100vh; */
  /* width: 60em; */
    /* 300px old min-height */
  min-height: v-bind('(singleSelect || selectMode ?  "200px":"400px" )');
  box-shadow: 0px 3px 17px #00000029;
  border-radius: 4px;
  margin: 1em 0;
  user-select: none;

  &.browser {
    height: 30vh;
  }
}

.items-list table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.items-list :deep(td) {
  /* padding: 0.5em; */
  padding: 8px 0;
  padding-right: 5px;
  padding-left: 12px;
}

/* TODO: think about a better way to fix the problem that if this class is named "list-item" it will be conflicting with "tailwindcss"’s built-in class "list-item" — N2DEO17T */
tr.list-item-custom {
  cursor: default;
  &.selected {
    background-color: #DFE9F2;
  }
}

.items-list tbody tr {
  border: 1px solid #d6d6d6;
  border-left: none;
  border-right: none;
  
  &:hover{
    background-color: #DFE9F2;
  }
}

.title {
  color: #25294A;
  text-decoration: none;
  cursor: pointer;
  max-width: 200px;
  display: inline-block;
}
.title:hover {
  text-decoration: underline;
}

.name-and-type-cell {
  word-break: break-word;
  min-width: 10rem;
}


// Drag Item helpers - - - - - - - - - - START
.drag-component-cell {
  display: none;
}

.drag-placeholder-floating-preview {
  position: fixed;
  z-index: 1;
  /* z-index: 4; */
}
// Drag Item helpers - - - - - - - - - - END
</style>