<template>
    <Dialog :expanderClass="'d-inline'" ref="orientation">
      <template #expander="{ openModal }">
        <!-- <OldThemeIcon
        name='delete'
        @click.stop="openModal"
        :class="['button']" 
      /> -->
      <a class="orientation-link font-bold" @click="openModal">
        {{orientationValues.filter(item => item.value == props.orientation )[0].name}}
      </a>
      </template>
      <template #dialog.title>Change Orientation</template>
      <template #dialog.content="{ closeModal, closeModalAfter }">
  
          <p> {{ props.title }}</p>
          <!-- TODO (cleaner code): think about removing redundancy between "DeleteItemConfirmationForm" & other forms (e.g. CreateSlideForm) [DSP-N26Dui5A] -->
          <div class="delete-item-form">
            <div class="d-flex">
                <div v-for="value of orientationValues ">
                  <div class="orientation-editor ml-2 px-2 py-4"
                   :class="{'active':newOrientationValue==value.value}" @click="newOrientationValue=value.value">
                    <div class="orientation-lable m-auto" 
                    :class="{'portrait':value.classes=='portrait','rotate-180':value.value>90}">
                     <p> {{ value.label }}</p>
                      

                    </div>
                    <p> {{ value.name }} </p>
                  </div>
                 
                </div>
            </div>
            <div class="form-buttons">
              <ButtonCustomized label="Cancel" @click="closeModal" secondary />
              <ButtonCustomized label="Save" @click="onSave" />
            </div>
          </div>
   
      </template>
    </Dialog>
  </template>
    
  <script lang="ts" setup>
  import Dialog from '../../../components/Dialog/Dialog.vue';
  import ButtonCustomized from "../../../components/form-fields/ButtonCustomized.vue";
  import OldThemeIcon from '@/components/icons/OldThemeIcon.vue';
import { ref } from 'vue';
  const orientation = ref () 
  const emit = defineEmits(['cancel', 'save', 'exit', 'fail'])
  const props = defineProps(['orientation','title'])
  const newOrientationValue = ref ()
  newOrientationValue.value = props.orientation
  const orientationValues = ref ([  {
            value: 0,
            name: "Landscape",
            label: "L",
            classes: "landscape"
        },
        {
            value: 90,
            name: "Portrait",
            label: "P",
            classes: "portrait"
        },
        {
            value: 180,
            name: "Landscape (flipped)",
            label: "L",
            classes: "landscape"
        },
        {
            value: 270,
            name: "Portrait (flipped)",
            label: "P",
            classes: "portrait"
        }])
  async function onSave() {
      emit('save',newOrientationValue.value);
      orientation.value.closeModal();
  }
  
  </script>
    
  <style lang="scss" scoped>
  .orientation-link:hover{
    text-decoration: underline;
    color: #93A5AE;
  }
  .active {
    background-color: #1c69d4;
    color:white
  }
  .active .orientation-lable {
    border-color: white;
    color: white;
  }
  .button {
    cursor: pointer;
  }
  .delete-item-form {
      display: flex;
      flex-direction: column;
      max-width: 30rem;
    }
    .form-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 0.75rem;
      margin-top: 1rem;
    }
    .orientation-editor{
      width: 90px;
      height: 100px;
      text-align: center;
      cursor: pointer;
    }
    .orientation-lable {
      border: 2px solid #262626;
    color: #262626;
    text-align: center;
    }
    .portrait {
      width: fit-content;
    padding: 8px
    }
    .rotate-180{
      transform: rotate(-180deg);
    }
  </style>