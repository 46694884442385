<template>
<input
  type="color"
  class="input"
  :name="name"
  :value="modelValue"
  @input="emitValue"
  @focusin="$emit('focusin')"
  @focusout="$emit('focusout')"
  @change="$emit('change')"
/>
</template>

<script lang="ts" setup>
import parseForModelModifiers from '../helpers/utils/parseForModelModifiers';

const props = defineProps({
  'modelValue': {
  },
  'name': {
    default: ""
  },
  'modelModifiers': { default: () => ({}) },
})

const emit = defineEmits(['update:modelValue', 'focusin', 'focusout', 'change'])


// TODO (cleaner code) [~DONE]: think about resolving redundancy in emitValue function between multiple components that use common modelModifiers [DSP-N26CEt55]
  // TODO (cleaner code): think about a more cleaner way for "parseForModelModifiers" & emitValue function [DSP-N26Cbu5n]
function emitValue(event: Event) {
  const value = parseForModelModifiers(props.modelModifiers, event.target.value);
  emit('update:modelValue', value)
}
</script>

<style lang="scss" scoped>
.input {
  border: 1px solid #abadb3;
  height: 30px;
  padding: 2px 2px 1px 2px;
  width: 70%;
  outline: none;

  &[type="color"] {
    width: 30px;
    padding: 0;
    margin-left: 10px;
  }
  &[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
  }
}
</style>