<template>
  <!-- TODO: think about un-duplicating similarities between some other component     [DSP-N1QAEm1F]
        {repo}\src\components\form-fields\NumberField.vue
        {repo}\src\components\form-fields\SeachBar\SeachBar.vue
  -->
<div class="field">
  <label :for="name" v-if="label">{{ label }}<template v-if="required"> &#9733;</template></label>
  <input
    type="time"
    :name="name"
    :maxlength="maxlength"
    :value="modelValue"
    @input="emitValue"
    :disabled="disabled"
    :step="step"
  />
</div>
</template>

<script lang="ts" setup>
import parseForModelModifiers from './helpers/utils/parseForModelModifiers';

const props = defineProps({
  'modelValue': {
  },
  'label': {
    type: String,
    default: ""
  },
  'disabled':{
    type: Boolean,
    default: false
  },
  'name': {
    default: ""
  },
  'maxlength': {
    default: "60"
  },
  'required': {
    default: false
  },
  'modelModifiers': { default: () => ({}) },
  'width': {
    default: "33ch"
  },
  'step': {
    default: undefined
  }
})

const emit = defineEmits(['update:modelValue'])


function emitValue(event: Event) {
  const value = parseForModelModifiers(props.modelModifiers, event.target.value);
  emit('update:modelValue', value)
}
</script>

<style lang="scss" scoped>
/* TODO:
    [ ] to think about a clean way for how to have cross-components common styles
 */

.field {
  display: flex;
  align-items: center;

  label {
    color: #262626;
    font-weight: bold;
    cursor: default;
    margin-right: 1.2rem;
  }
  /* margin-right: 0.7rem; */

  input[type='time'] {
    color: #666666;
    border: 1px solid #abadb3;
    height: 2.2rem;
    padding: 2px 2px 1px 2px;
    width: v-bind(width);
    @include small-devices-down {
      width: auto;
      flex-grow: 1;
    }
    outline:none;
  }
}


</style>