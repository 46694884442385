import { computed, onMounted, reactive, ref, watch, watchEffect } from "vue";
import { useForm } from "vee-validate";
import * as yup from "yup";
import {
  addPlayerMetadata,
  deleteMetadata,
  getPlayerById,
  previewPlayer,
  previewPlayerByDate,
  updatePlayer,
} from "@/helpers/api/player/player";
export default function usePlayerPreviewForm() {
  const hasChanges = ref(false);
  const isLoading = ref(false);
  const isSuccess = ref(false);
  const isError = ref(false);
  const errorMessage = ref("");
  const playerId = ref("");
  const selectedPlayer = ref();
  const playerPreview = ref();
  const previewList = ref<any[]>([]);
  const datePreview = ref();
  const SharingLevel = ref(["Private", "Organization", "Shared"]);
  const media = ref();
  const videoSource = ref();
  async function loadPlayerPreview(itemId: any) {
    clearInterval(timeInerval.value);
    playerId.value = itemId;
    selectedPlayer.value = await previewPlayer(itemId);
    selectedPlayer.value.Campaigns.forEach((player: any) => {
      player.PlayLists.forEach((playlist: any) => {
        playlist.Items.forEach((item: any) => {
          previewList.value.push({...item,playlist:playlist.Id});
        });
      });
      selectItem(selectedPlayer.value.Campaigns[0].PlayLists[0].Items[0].Guid,selectedPlayer.value.Campaigns[0].PlayLists[0].Id);
    });
    //fillInitialValues(selectedPlayer.value)
  }
  function selectItem(itemGuid: string ,playlistId :any ) {
    clearInterval(timeInerval.value);
    previewList.value.forEach((item, index) => {
      if (item.Guid == itemGuid && playlistId== item.playlist) {
        item.selected = true;
        if (item.AssetType != 1) {
          setImageTimer(index, item.MediaContentDuration, 0);
        } else {
            if (media.value)
            {
                videoSource.value?.setAttribute("src", item.MediaContentUrl);
                media.value.load();
                media.value.currentTime =0
                setTimeout(() => {
                media.value.play();
                }, 0);
            }
       
        
        }
      } else {
        item.selected = false;
      }
    });
    selectedPlayer.value.Campaigns.forEach((player: any) => {
      player.PlayLists.forEach((playlist: any) => {
        if ( playlist.Id==playlistId)
        {
            playlist.selected = true 
        }
        playlist.Items.forEach((item: any) => {
          if (item.Guid == itemGuid && playlist.Id==playlistId) {
            item.selected = true;
           
          } else {
            item.selected = false;
           
          }
        });
      });
    });
  }
  const timeInerval = ref();
  const remainingTime = ref(0);
  const isPaused = ref(false);
  function setImageTimer(
    itemIndex: number,
    duration: number,
    durationDisplay: number
  ) {
    isPaused.value = false;

    timeInerval.value = setInterval(function () {
      durationDisplay = durationDisplay + 1;
      previewList.value[itemIndex].currentDuration = durationDisplay;
      remainingTime.value = duration - durationDisplay;
      if (durationDisplay == Number(duration.toFixed(0))) {
        clearInterval(timeInerval.value);
        if (itemIndex + 1 < previewList.value.length) {
          selectItem(previewList.value[itemIndex + 1].Guid,previewList.value[itemIndex + 1].playlist);
        } else {
          selectItem(previewList.value[0].Guid,previewList.value[0].playlist);
        }
        return;
      }
    }, 1000);
    if (previewList.value[itemIndex].AssetType == 1) {
      media.value.play();
    }
  }
  function pauseTimer() {
    let itemIndex = previewList.value.findIndex(
      (item) => item.selected == true
    );

    clearInterval(timeInerval.value);
    isPaused.value = true;

    if (previewList.value[itemIndex].AssetType == 1) {
      pauseVideo();
    }
  }
  function nextPlaylist() {
    let itemIndex = previewList.value.findIndex(
      (item) => item.selected == true
    );
    if (itemIndex < previewList.value.length - 1) {
      selectItem(previewList.value[itemIndex + 1].Guid,previewList.value[itemIndex + 1].playlist);
    }
  }
  function previousPlaylist() {
    let itemIndex = previewList.value.findIndex(
      (item) => item.selected == true
    );
    if (itemIndex > 0) {
      selectItem(previewList.value[itemIndex - 1].Guid,previewList.value[itemIndex - 1].playlist);
    }
  }
  function resumeTimer() {
    let itemIndex = previewList.value.findIndex(
      (item) => item.selected == true
    );

    setImageTimer(
      itemIndex,
      previewList.value[itemIndex].MediaContentDuration,
      previewList.value[itemIndex].currentDuration
    );
  }
  function changeCurrentVideoTime(value: number) {
    clearInterval(timeInerval.value);
    let itemIndex = previewList.value.findIndex(
      (item) => item.selected == true
    );
    previewList.value[itemIndex].currentDuration = value;
    setImageTimer(
      itemIndex,
      previewList.value[itemIndex].MediaContentDuration,
      previewList.value[itemIndex].currentDuration
    );
  }
  function formateTime(timeInSeconds: any) {
    var seconds = Math.floor(Math.floor(timeInSeconds) % 60);
    var minutes = Math.floor(Math.floor(timeInSeconds) / 60);
    var output = "";
    if (minutes > 0) {
      if (minutes < 10) {
        output += "0" + minutes + ":";
      } else {
        output += minutes + ":";
      }
    } else {
      output += "00:";
    }
    // secs
    if (seconds > 0) {
      if (seconds < 10) {
        output += "0" + seconds;
      } else {
        output += seconds;
      }
    } else {
      output += "00";
    }

    return output;
  }
  async function getDatePerview() {
    let dateFormate = new Date(datePreview.value);
    let date = {
      day: dateFormate.getDate(),
      month: dateFormate.getMonth() + 1,
      year: dateFormate.getFullYear(),
      hour: dateFormate.toISOString().slice(11, 13),
      minute: dateFormate.toISOString().slice(14, 16),
    };
    clearInterval(timeInerval.value);
    selectedPlayer.value = await previewPlayerByDate(playerId.value, date);
    if (selectedPlayer.value.Campaigns.length == 0) {
      previewList.value = [];
    }
    selectedPlayer.value.Campaigns.forEach((player: any) => {
      player.PlayLists.forEach((playlist: any) => {
        playlist.Items.forEach((item: any) => {
          previewList.value.push(item);
        });
      });
      selectItem(selectedPlayer.value.Campaigns[0].PlayLists[0].Items[0].Guid,selectedPlayer.value.Campaigns[0].PlayLists[0].Id);
    });
  }
  function pauseVideo() {
    media?.value.pause();
  }
  function playVideo() {
    const med = document.getElementById("vide");
    let itemIndex = previewList.value.findIndex(
      (item) => item.selected == true
    );
    previewList.value[itemIndex].MediaContentDuration = media.value.duration;
    setImageTimer(itemIndex, media.value.duration, 0);
  }
  return {
    isError,
    isSuccess,
    isLoading,
    errorMessage,
    loadPlayerPreview,
    hasChanges,
    playerId,
    selectedPlayer,
    selectItem,
    previewList,
    pauseTimer,
    resumeTimer,
    isPaused,
    formateTime,
    nextPlaylist,
    previousPlaylist,
    datePreview,
    getDatePerview,
    SharingLevel,
    media,
    playVideo,
    changeCurrentVideoTime,
    videoSource,
  };
}
