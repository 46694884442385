<template>
  <Dialog ref="dialog">
    <template #expander="{ openModal }">
    </template>
    <template #dialog.title>Saving</template>
    <template #dialog.content="{ closeModal, closeModalAfter }">
     <div class="">
          <div class="confirmation-text">
            Please wait...
          </div>
        </div>
    </template>
  </Dialog>
</template>
  
<script lang="ts" setup>
import { onMounted ,ref } from 'vue';
import Dialog from '../../../components/Dialog/Dialog.vue';
import ButtonCustomized from "../../../components/form-fields/ButtonCustomized.vue";
const dialog = ref ()
function close() {
  dialog.value.closeModal()
}
async function open() {
  dialog.value.openModal()
}

defineExpose({
  close ,
  open,
})
</script>
  
<style lang="scss" scoped>
.button {
  cursor: pointer;
}
.delete-item-form {
    display: flex;
    flex-direction: column;
    max-width: 30rem;
  }
  .form-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
    margin-top: 1rem;
  }
</style>