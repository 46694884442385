<template>
<component
  :is="loadingSkeletonComponent"
  v-for="index in count"
  :key="index"
/>
</template>

<script lang="ts" setup>
import ItemWithImageTableLoadingSkeleton from './ItemWithImageTableLoadingSkeleton.vue';
import ItemWithoutImageTableLoadingSkeleton from './ItemWithoutImageTableLoadingSkeleton.vue';
const props = defineProps<{
  count: number;
  type: 'withImage' | 'withoutImage';
}>();

const loadingSkeletonComponent = (()=>{
  if (props.type === 'withImage') {
    return ItemWithImageTableLoadingSkeleton;
  } else if (props.type === 'withoutImage') {
    return ItemWithoutImageTableLoadingSkeleton;
  }
})();
</script>

<style lang="scss" scoped>

</style>