<template>
<div class="field">
  <slot></slot>
</div>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>
.field {
  display: flex;
  align-items: center;

  :deep(label) {
    color: #262626;
    font-weight: bold;
    cursor: default;
    margin-right: 0.375rem;
  }
  /* margin-right: 0.7rem; */

  :deep(input[type='text']) {
    color: #666666;
    border: 1px solid #abadb3;
    height: 2.2rem;
    padding: 2px 2px 1px 2px;
    @include small-devices-down {
      width: auto;
      flex-grow: 1;
    }
    outline:none;
    padding-left: 0.375rem;
  }
}
</style>