import { ref } from "vue";
import { submitDeleteListItem } from "@/helpers/api/list/delete";
import useListSelection from '@/components/ListingPages/ItemsLister/ItemsList/composables/listSelection';

// TODO (cleaner code): think about removing redundancy between "deleteItemConfirmationForm" & other form composables (e.g. createSlideForm) [DSP-N26ECg6O]
export default function useDeleteItemConfirmationForm( itemsListHelpers ) {
  const { mutateList, apiPathsConfig } = itemsListHelpers;
  const { clearItemsSelection } = useListSelection();
  const isLoading = ref(false);
  const isSuccess = ref(false);
  const isError = ref(false);
  const errorMessage = ref("");

  async function submitDeleteAction(itemIdOrItemsIds: string) {
    try {
      errorMessage.value = "";
      isLoading.value = true;
      const submissionResponse = await submitDeleteListItem(itemIdOrItemsIds, { deleteApiPath: apiPathsConfig.delete });
      isLoading.value = false;
      const isSingleItem = itemIdOrItemsIds.toString().match(',') === null;

      if (submissionResponse.isSuccessful === true) {
        // if is successful
        isSuccess.value = true;
        mutateList();        
        clearItemsSelection();
        
        // check if there's a need to call "mutateMediaList" again if updates were not reflected
        // TODO: think about if there's a more cleaner (…1) way to check if there's a need to re-call "mutateMediaList" — N2FBCO00
          // …1: may be by checking if any of the "to be delted" items' ids are still present in the "filteredListItems" array, if so
        if(!isSingleItem) {
          setTimeout(() => {
            mutateList();
          }, 2000)
        }
      } else {
        // if is not successful
        errorMessage.value = `Something went wrong.`;
      }
    } catch (error) {
      isError.value = true;
      isLoading.value = false;
      errorMessage.value = `Something went wrong. (${error})`;
    }
  }

  return {
    submitDeleteAction,
    isError,
    isSuccess,
    isLoading,
    errorMessage,
  };
}