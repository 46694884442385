<template>
{{ sizeHumanReadable }}
</template>

<script lang="ts" setup>
import { formatBytes } from '@/helpers/utils';
import { computed } from 'vue';
const props = defineProps<{
  size: number;
}>()

const sizeHumanReadable = computed(() => formatBytes(props.size));
</script>

<style lang="scss" scoped>

</style>