<!-- THINK ABOUT MOVING THIS COMPONENT AND SIMILAR TO A MORE COMMON LOCATION -->
<template>
<ItemsLister
  v-bind="{...itemsListerConfig, ...config}"
  :itemsListHelpers="!config && mediaListHelpers"
/>
</template>

<script lang="ts" setup>
import ItemsLister from '@/components/ListingPages/ItemsLister/ItemsLister.vue';
import itemsListerConfig from './config/itemsLister.config';
import useMediaListHelpers from './mediaListHelpers';
defineProps<{
  config?: any;
}>()
const mediaListHelpers = useMediaListHelpers();
</script>

<style lang="scss" scoped>

</style>