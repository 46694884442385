<template>
    <div class="create-slide-form">
      <div class="status-bar-container">
        <StatusBar
          :errorMessage="errorMessage"
          :isLoading="/* true ||  */isLoading"
          loadingMessage="Saving..."
          :isSuccess="isSuccess"
          successMessage="Saved!"
        />
      </div>
      <p>To register a player, enter the registration code shown on the device in the field below.
         The code is 6 digits consisting of letters and numbers. It is not case sentistive.</p>
      <div class="form-fields">
        <!-- TODO: think about adding below-field error message & error state indication (e.g. red border) [DSP-N26CxI4P] -->
        <div class="formGroup">
          <label for="name">Registration Code	 &#9733;</label>
          <TextField
            v-model="values.code"
            width="100%"
          />
        </div>
        <div class="formGroup">
          <label for="name">Location	 &#9733;</label>
          <label v-if="values.location?.name"> {{values.location?.name}} </label>
           <SelectLocationDialog @save="addLocation($event)" :showBtn="values.location?.name ?false: true  "> </SelectLocationDialog>
        </div>
      </div>
      <div class="form-buttons">
        <ButtonCustomized label="Cancel" @click="onCancel" :secondary="true" />
        <ButtonCustomized label="Register" @click="onSubmit" />
      </div>
    </div>
  </template>
  
  <script lang="ts" setup>
  import CheckboxField from "@/components/form-fields/CheckboxField.vue";
  import DateField from "@/components/form-fields/DateField.vue";
  import TextField from "@/components/form-fields/TextField.vue";
  import useCreateCampaignForm from "./CreatePlayerFormHelper";
  import ButtonCustomized from "@/components/form-fields/ButtonCustomized.vue";
  import StatusBar from "@/components/form-fields/StatusBar/StatusBar.vue";
  import useNotifications from "@/components/Notifications/composables/notifications";
  import router from '@/router';
import SelectLocationDialog from "./SelectLocationDialog.vue";
  
  const emit = defineEmits(['submit', 'cancel', 'success', 'fail'])
  
  const notification = useNotifications();
  
  const { values, submitForm, isLoading, isSuccess, errorMessage, newPlayerId } = useCreateCampaignForm();
  
  async function onSubmit() {
    await submitForm();
    if(isSuccess.value === true) {
      notification.success('Player created successfully!');
      emit('success');
      router.push({name: 'player-editor', params: {id: newPlayerId.value}});
    } else {    // on failure
      emit('fail');
    }
  }
  function  addLocation(selectedLocation :any) {
    values.location= selectedLocation
  }
  function onCancel() {
    emit('cancel');
  }
  </script>
  
  <style lang="scss" scoped>
  
  .status-bar-container {
    margin-bottom: 1rem;
  }
  
  .create-slide-form {
    display: flex;
    flex-direction: column;
    max-width: 20rem;
  }
  .form-fields {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  .formGroup {
    display: flex;
    align-items: center;
    .select {
      width: 60%;
      margin: 0;
    }
    label {
      display: inline-block;
      width: 110px;
      color: #262626;
      font-weight: bold;
    }
  }
  
  .form-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
    margin-top: 1rem;
  }
  </style>
  