import useApi from '../api';
import { prepareFieldsFilters, prepareSearchQuery, prepareSorts } from './helpers/listing';

interface SubmitExportListInputOptions {
  exportApiPath: string;
  filters?: any;
  sortBy: any;
  searchTerm?: string;
  queryFieldNames?: string[];
}
export async function submitExportList(
  {
    exportApiPath,
    filters,
    sortBy,
    searchTerm,
    queryFieldNames,
  }: SubmitExportListInputOptions
) {
  const { postUrlEncoded } = useApi();
  
  const filtersPrepared = prepareFieldsFilters(filters);
  const searchQueryPrepared = prepareSearchQuery(searchTerm, queryFieldNames);
  const sortsPrepared = prepareSorts(sortBy);
  
  const exportRequestOptions = {
    "query": searchQueryPrepared,
    "filters": filtersPrepared,
    "sorts": sortsPrepared
  };
  
  const { responseBody, response } = await postUrlEncoded(
    exportApiPath,
    { data: JSON.stringify(exportRequestOptions) },
    {
      isLogoutOnInvalidAuth: true,
      config: {
        headers: {
          'Content-Type': 'Content-Type: text/csv',
        }
      }
    }
  );
  const responseData = responseBody;
  const contentDispositionHeader = response.headers.get("content-disposition");
  const fileName = contentDispositionHeader.match(/filename=(.+)/)[1];
  return { responseData, fileName, response };
}