import { reactive, ref } from "vue";

// TODO (code design & architecturing / thinking): think about later why composable may be better here than a pinia store [DSP-N25NDFA4]
interface Notification {
  id: number;
  message: string;
  type: Type;
}
type Type = 'success' | 'failure' | 'normal' | 'deletion';

const notifications = reactive<Notification[]>([]);
const notificationsCount = ref(notifications.length);    // helper for notification id generation
  // No need to generate a robust id as we will have short living notifications

const defaults = {
  timeout: 3000,
  type: "normal" as Type
}


interface NotificationOptions {
  timeout?: number;
  type?: Type;
}

export default function useNotifications() {
  function addNotification(
    message: string,
    {
      timeout = defaults.timeout,
      type = defaults.type
    }: NotificationOptions = {}
  ) {
    notificationsCount.value++;
    const notification = {
      id: notificationsCount.value,
      message,
      type
    };
    notifications.push(notification)
    setTimeout(() => {
      removeNotification(notification.id)
    }, timeout)
  }

  function removeNotification(notificationId: number) {
    const notificationIndex = notifications.findIndex(notification => notification.id === notificationId)
    notifications.splice(notificationIndex, 1);
  }

  const add = addNotification;
  const remove = removeNotification;
  const success = (message: string, options: NotificationOptions = {}) => addNotification(message, {type: 'success',...options});
  const failure = (message: string, options: NotificationOptions = {}) => addNotification(message, {type: 'failure',...options});
  const normal = (message: string, options: NotificationOptions = {}) => addNotification(message, {type: 'normal',...options});
  const deletion = (message: string, options: NotificationOptions = {}) => addNotification(message, {type: 'deletion',...options});
  return {
    items: notifications,
    notifications,
    add,
    remove,
    addNotification,
    removeNotification,
    success,
    failure,
    normal,
    deletion
  }
}