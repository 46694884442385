<template>
<v-tooltip
  v-if="alerts.length"
  :text="alertsAugmented"
  color="white"
  content-class="alerts-overlay-content"
>
  <template v-slot:activator="{ props }">
    <OldThemeIconRed
      :class='["alerts-indicator", iconClassName]'
      name="alert"
      v-bind="props"
    />
  </template>
</v-tooltip>

</template>

<script lang="ts" setup>
import { computed } from 'vue';
import OldThemeIconRed from '@/components/icons/OldThemeIconRed/OldThemeIconRed.vue';

const props = defineProps(['alerts', 'iconClassName']);

const alertsAugmented = computed(() => {
  let messageAugmented = '';
  if (props.alerts.length) {
    props.alerts.forEach((alert, index) => {
      messageAugmented += alert.message + (props.alerts[index+1] ? ', ' : '');
    });
  }
  return messageAugmented;
});
</script>

<style lang="scss" scoped>

</style>