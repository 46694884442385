import filtersDisplayConfig from "./filtersDisplay.config";
import apiPathsConfig from './apiPaths.config';
import UsersTableListItem from "../UsersListItem/UsersTableListItem.vue";
import availableActionsConfig from "./availableActions.config";
import availableListViewsConfig from "./availableListViews.config";

const itemsListerConfig = {
  TableViewItem: UsersTableListItem,
  apiPathsConfig,
  filtersDisplayConfig,
  availableActionsConfig,
  availableListViewsConfig,
}

export default itemsListerConfig;