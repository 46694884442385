<template>
  <!-- Dev stats helper -------------------------------- START -->
  <!-- <div class="flex flex-column fixed bg-slate-400 border-r-2 p-2 top-2">
    slide.sprites: {{ slide.sprites }}
  </div> -->
  <!-- Dev stats helper -------------------------------- END -->
  
  <div
    :class="['scroll-container', {'draw-mode': isDrawMode}]"
    ref="scrollContainer"
    @scroll="repositionStage"
  >
    <div
      class="large-container"
      :style="styles_largeContainer"
    >
      <div
        class="stage-container"
        ref="stageParentContainer"
      >
        <v-stage
          :config="stageConfig"
          ref="stage"
          @mousedown="handleStageMouseDown"
          @touchstart="handleStageMouseDown"
          @mousemove="handleStageMouseMove"
          @touchmove="handleStageMouseMove"
          @mouseup="handleStageMouseUp"
          @touchend="handleStageMouseUp"
          @click="handleStageMouseClick"
          @wheel="handleStageMouseWheel"
        >
          <v-layer name="background">
            <v-rect :config="backgroundLayerRectConfig" />
          </v-layer>
          <v-layer ref="slideMainLayer" name="slide-main">
            <template v-for="sprite in slide.sprites" :key="sprite.id">
              <v-text v-if="sprite.type === 'text'"
                :config="{
                  ...sprite,
                  ...{
                    listening: isDrawMode ? false : true,
                  }
                }"
                draggable="true"
                :visible="!sprite.hidden && !(sprite.isLockedByOriginalOwner && isShowEditableObjects)"
                @dblclick="handleTextSpriteDblClick"
                @dbltap="handleTextSpriteDblClick"
                @transform="handleShapeTransform($event, sprite)"
                @transformend="onMultiStepChangeEndHistoryHandler"
                @dragmove="handleShapeDragMove($event, sprite)"
                @dragstart="selectionShapeHandlers.onDragStart"
                @dragend="onMultiStepChangeEndHistoryHandler"
              />
              <!-- Image resize follower rectangle -->
              <v-rect
                v-if="sprite.type === 'image'"
                draggable="true"
                :config="{
                  x: sprite.x,
                  y: sprite.y,
                  width: sprite.width,
                  height: sprite.height,
                  // fill: 'green',
                  // stroke: 'black',
                  fill: !sprite.src ? 'white' : null,
                  strokeWidth: 0,
                  id: sprite.id,
                  listening: isDrawMode ? false : true,
                }"
                @transform="handleShapeTransform($event, sprite)"
                @transformend="onMultiStepChangeEndHistoryHandler"
                @dragmove="handleShapeDragMove($event, sprite)"
                @dragstart="selectionShapeHandlers.onDragStart"
                @dragend="onMultiStepChangeEndHistoryHandler"
              />
              <v-image v-if="sprite.type === 'image'"
                :config="{
                  ...sprite,
                  listening: false,
                  x: sprite.x+sprite.imageX,
                  y: sprite.y+sprite.imageY,
                  ...(
                    sprite.fitment === 'best-fit' || sprite.fitment === 'fill'
                      ? {
                        width: sprite.imageWidth,
                        height: sprite.imageHeight,
                        crop: null
                      }
                      : null
                  ),
                  ...(
                    sprite.fitment === 'original'
                      ? {
                        crop: {
                          x: 0,
                          y: 0,
                          width: sprite.width,
                          height: sprite.height
                        },
                      }
                      : null
                  ),
                }"
                :visible="!sprite.hidden && !(sprite.isLockedByOriginalOwner && isShowEditableObjects)"
              />
            </template>
            <!-- TODO: think about adding "keepRatio" toggle button — N2LDwm8H -->
            <v-transformer ref="transformer" :config="{rotateEnabled: true, keepRatio: false}" />
            <v-rect
              :config="{
                ...selectionRectangleProps.config,
                ...selectionRectanglePropsConfig_basedOnDrawMode
              }"
              ref="selectionRectangle" />
          </v-layer>
          <DevelopmentStatsLayer />
        </v-stage>
        <TextSpriteEditArea /> <!-- only shown when editing text sprite text -->
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive, computed, onMounted, onBeforeUnmount, onBeforeMount, nextTick } from 'vue';
import useSlide from '../composables/slide';
import { onMultiStepChangeEndHistoryHandler } from '../composables/helpers/history';
import baseElementsRefs, { keepPreservedCopyOfNodes } from './baseElementsRefs';
import { useStageEvents } from './stageEvents';
import { handleTextSpriteDblClick } from './stageEvents/handlers/textSpriteHandlers';
import {
  handleShapeTransform,
  handleShapeDragMove
} from './stageEvents/handlers/shapeHandlers';
import DevelopmentStatsLayer from './DevelopmentStatsLayer.vue';
import { stagePosition, stageScaleConfig, stageHeight, stageWidth } from './baseConfigsRefs';
import { updateStageSizeBasedOnContainer } from '../utils/stage';
import { refreshSpritesStageSelection } from '../utils/spritesSelection';
import { selectionShapeHandlers } from './stageEvents/handlers/selectionShapeHandlers';
import TextSpriteEditArea from './TextSpriteEditArea/TextSpriteEditArea.vue';
import usePanStageHelpers from './stageHelpers/panStageHelpers_tempTest';
import { 
  isDrawMode,
  selectionRectanglePropsConfig_basedOnDrawMode
} from './stageEvents/handlers/selectorRectangleStageHandlers';




export interface Props {
  fullScreenMode?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  fullScreenMode: false
})

const { isShowEditableObjects } = useSlide();

const {
  // base elements refs
  scrollContainer,
  stageParentContainer,
  stage,
  slideMainLayer,
  transformer,
  selectionRectangle
} = baseElementsRefs;

const {
  selectionRectangleProps,
  handleStageMouseDown,
  handleStageMouseMove,
  handleStageMouseUp,
  handleStageMouseClick,
  handleStageMouseWheel,
} = useStageEvents();

const slide = useSlide();
const backgroundLayerRectConfig = computed(() => {
  return {
    x: 0,
    y: 0,
    width: slide.basicInfo.width,
    height: slide.basicInfo.height,
    fill: slide.basicInfo.backgroundColor,
    draggable: false,
    listening: false
  }
});

onBeforeMount(() => {
  if(props.fullScreenMode) {
    // preserve main slide editor stage reference if fullScreen is activated
    keepPreservedCopyOfNodes()
  }
})

onMounted(() => {
  updateStageSizeBasedOnContainer();
  window.addEventListener('resize', updateStageSizeBasedOnContainer);
  if(props.fullScreenMode) {
    refreshSpritesStageSelection();
  }
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateStageSizeBasedOnContainer);
  if(props.fullScreenMode) {
    setTimeout(async () => {
      await nextTick();
      refreshSpritesStageSelection();
    }, 0)
  }
});

const stageConfig = reactive({
  width: stageWidth,
  height: stageHeight,
  scale: stageScaleConfig,
  position: stagePosition
});

const {
  repositionStage,
  styles_largeContainer,
} = usePanStageHelpers();

onMounted(()=>{
  repositionStage();
});
</script>

<style lang="scss" scoped>
.stage-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.draw-mode {
  cursor: crosshair;
}

/* Scroll helpers - - - - - - - - - - - - - - - - - - - - - - - - START */
.large-container {
  overflow: hidden;
}

.scroll-container {
  background-color: rgb(102, 102, 102);
  height: v-bind('(props.fullScreenMode ? "100%" : "600px")');
  width: v-bind('(props.fullScreenMode ? "95%" : "95%")');
  @include x-large-devices-down {
    width: 100%;
  }
  overflow: auto;
  margin: 10px;
  border: 1px solid grey;
}
/* Scroll helpers - - - - - - - - - - - - - - - - - - - - - - - - END */
</style>