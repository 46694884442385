<template>
  <Dialog ref="addTag">
    <template #expander="{ openModal }">
      <a class="cursor-pointer mt-1" @click="onOpenModal()"> {{ 'Add' }}</a>

    </template>
    <template #dialog.title>
      Add Tags
      <h6 class="mb-3">
        {{ player.name }}
      </h6>
      <p class="add-tag-hint">
        All tags will be converted to Title Case
      </p>
    </template>
    <template #dialog.content="{ closeModal, closeModalAfter }">
      <!-- TODO (cleaner code): think about removing redundancy between "DeleteItemConfirmationForm" & other forms (e.g. CreateSlideForm) [DSP-N26Dui5A] -->
      <div>
        <StatusBar
          :errorMessage="errorMessage"
        />
        <div class="d-flex mb-2 ">
          <label for="name" class="basis-1/2 text-sm">Category &#9733;</label>
          <div class="basis-1/2 position-relative" width="100%" id="metadataName"  >
            <TextField v-model="values.name" width="100%" @input="setNameValue() " @focusin=" showNameList = true " @focusout=" showNameList = false " />
            <div class="autocomplete" v-if="filteredMetaDataName?.length>0 && showNameList">
              <ul class="position-absolute">
                <li v-for="name in filteredMetaDataName" @mousedown="selectName(name)"
                  :class="{ 'selected': name == values.name }" v-bind:key="name">
                  <span v-html="getBoldValue(name, nameSearchValue)"> </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="d-flex mb-2" >
          <label for="Description" class="basis-1/2 text-sm">Value &#9733;</label>
          <div class="basis-1/2 position-relative" width="100%"
         >
          <TextField width="100%" v-model="values.value" class="basis-1/2"
            @input="setMetadataValue() "  @focusin=" showMetaDataList = true " @focusout=" showMetaDataList = false "/> 
          <div class="autocomplete" v-if="filteredMetaDataValues?.length>0 && showMetaDataList">
              <ul class="position-absolute">
                <li v-for="name in filteredMetaDataValues" @mousedown="selectValue(name)"
                  :class="{ 'selected': name == values.value }" v-bind:key="name">
                  <span v-html="getBoldValue(name, metadataSearchValue)"> </span>
                </li>
              </ul>
            </div>

          </div>
        </div>
        <div class="d-flex mb-2">
          <label for="Description" class="basis-1/2 text-sm">Data Type &#9733;</label>
          <TextField width="100%" v-model="form.dataType" class="basis-1/2 disabled" />
        </div>

        <div class="form-buttons">
          <ButtonCustomized label="Cancel" @click="closeModal()" secondary/>
          <ButtonCustomized label="Save" @click="submitForm()" />
        </div>
      </div>

    </template>
  </Dialog>
</template>
    
<script lang="ts" setup>
import Dialog from '../../../components/Dialog/Dialog.vue';
import ButtonCustomized from "../../../components/form-fields/ButtonCustomized.vue";
import { computed, onMounted, ref, watch } from 'vue';
import TextField from "@/components/form-fields/TextField.vue";
import { useForm } from "vee-validate";
import * as yup from 'yup';
import StatusBar from "@/components/form-fields/StatusBar/StatusBar.vue";
import {searchMetaData} from '@/helpers/api/player/player';
import { toTitleCase } from '@/helpers/utils';
const emit = defineEmits(['cancel', 'save', 'exit', 'fail'])
const metaData = ref (null as any )
const props = defineProps({
  'player': {
    type: Object,
    default: true
  },
})
const validationSchema = yup.object({
  name: yup.string().label('Category').required(),
  value : yup.string().label('Value').required(),
  });
  
const { errors, handleSubmit, values, setFieldValue , isSubmitting } = useForm({ validationSchema });
const errorMessage = ref()
const addTag = ref()
const form = ref({
  dataType: 'String',
  name:'',
  value:''
})
const submitForm = handleSubmit(onSave, handleSubmitInvalid);
const nameSearchValue=ref('')
const showNameList =ref (true)
const metadataSearchValue=ref('')
const showMetaDataList =ref (true)
async function handleSubmitInvalid() {
  
    let errorsAugmentedInSingleMessage = "";
    // TODO (cleaner code): think if there's a cleaner way to write this [DSP-N26Cti4A]
    if(Object.keys(errors.value).length){
      Object.keys(errors.value).forEach((key) => {
        errorsAugmentedInSingleMessage += errors.value[key]+". ";
      });
    }
    errorMessage.value = errorsAugmentedInSingleMessage;
  }
async function onSave() {
  // update value and name to be a "Title Case" to normalize the tags
  form.value.name = toTitleCase(values.name);
  form.value.value = toTitleCase(values.value);

  const hasExistingTag = props.player.metaDatas.some((meta: any) => meta.name.toLowerCase() === values.name.toLowerCase() && meta.value.toLowerCase() === values.value.toLowerCase());

  if (hasExistingTag) {
    errorMessage.value = " Tag Already Exists";
    return
  }
  emit('save', form.value);
  errorMessage.value = "";
  addTag.value.closeModal()
}
function setNameValue (){
  nameSearchValue.value = values.name
  showNameList.value = true 
}
function setMetadataValue () {
  metadataSearchValue.value = values.value
  showMetaDataList.value = true 
}
function selectName (name:any )
{ 
  values.name = name 
  showNameList.value = false 
}
function selectValue (name:any )
{
  values.value = name 
  showMetaDataList.value = false 
}
onMounted(() => {
  searchMetaData(props.player.id).then(res => {
    metaData.value = res ; 
  })

})
const filteredMetaDataName = computed(()=>{
  if (nameSearchValue.value && nameSearchValue.value.length>0 )
  {
    return  metaData.value.MetaDataName.filter((name : any ) => name.toLowerCase().includes(nameSearchValue.value.toLowerCase()));
  }
  return []
});
const filteredMetaDataValues = computed(()=>{
  if (metadataSearchValue.value && metadataSearchValue.value.length>0 )
  {
    return  metaData.value.MetaDataValue.filter((name : any ) => name.toLowerCase().includes(metadataSearchValue.value.toLowerCase()));
  }
  return []
});
function getBoldValue(name :any ,nameSearchValue : any  ) {
  return name.replaceAll(nameSearchValue.toLowerCase(),`<b>${nameSearchValue.toLowerCase()}</b>`)
  
}
function onOpenModal () {
  resetValues()
  addTag.value.openModal()
}
function resetValues(){
   nameSearchValue.value=''
   metadataSearchValue.value=''
   values.name = '' 
   values.value = '' 
   showNameList.value= true 
   showMetaDataList.value= true 

}
</script>
    
<style lang="scss" scoped>
.button {
  cursor: pointer;
}

.delete-item-form {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1rem;
}
.disabled {
  pointer-events: none;
  background-color: #E6E6E6;
    color: #999999
}
.autocomplete {
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 2;
}
.autocomplete ul {
    background: none repeat scroll 0 0 #ffffff;
    border-top: 1px dotted #ccc;
    border-bottom: 1px solid #ccc;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    position: relative;
    top: -1px;
    list-style: none;
    width:100%;
    // min-width: 24ch
}
.autocomplete ul li {
    background: inherit;
    border-color: #ccc;
    border-image: none;
    border-style: solid;
    border-width: 0 1px;
    display: block;
    font-size: 14px;
    font-weight: normal;
    padding: 0px 12px;
    cursor: pointer;
}
.autocomplete ul li:hover {
  background-color:  #ebebeb ;
 }
 .autocomplete ul li.selected {
  background-color:  #ebebeb ;
}

.add-tag-hint {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: 100%;
  background-color: #e6e6e6;
  padding: 0.5rem;
}
</style>