import type { Sprite } from '@/helpers/types/media/slide/Sprite';
import { getImageBestFitParams } from './imageFitmentHelpers';
import { onMultiStepChangeStartHistoryHandler } from '@/views/MediaEditorView/SlideEditor/composables/helpers/history';

export function handleShapeTransform(e: any, spriteStore: Sprite) {
  onMultiStepChangeStartHistoryHandler();

  // Important for limiting shape's resize values to rounded values and also to have a correct width and height values that can be linked binded with the Node's "data store"
  
  const imageContainerRect = e.target;
  imageContainerRect.setAttrs({   /* event target should be a Konva Shape Node */
    width: Math.round(imageContainerRect.width() * imageContainerRect.scaleX()),
    scaleX: 1,
    height: Math.round(imageContainerRect.height() * imageContainerRect.scaleY()),
    scaleY: 1,
  });  
  spriteStore.width = e.target.width();
  spriteStore.height = e.target.height();
  spriteStore.scaleX = e.target.scaleX();
  spriteStore.scaleY = e.target.scaleY();
  spriteStore.rotation = e.target.rotation();
  e.target.setAttrs({
    x: Math.round(e.target.x()),
    y: Math.round(e.target.y())
  })
  spriteStore.x = e.target.x();
  spriteStore.y = e.target.y();

  if(spriteStore.type === 'image') {    
    if(spriteStore.fitment === "best-fit") {
      const {
        newWidth,
        newHeight,
        newImageX,
        newImageY
      } = getImageBestFitParams(spriteStore);
      
      spriteStore.imageWidth = newWidth;
      spriteStore.imageHeight = newHeight;
      spriteStore.imageX = newImageX;
      spriteStore.imageY = newImageY;
    };
    if(spriteStore.fitment === "fill") {
      spriteStore.imageWidth = spriteStore.width;
      spriteStore.imageHeight = spriteStore.height;
    };
    // if(spriteStore.fitment === "original") {}
  }
}

export function handleShapeDragMove(e: any, spriteStore: Sprite) {
  // Important for limiting shape node's drag positions to rounded values
  const imageContainerRect = e.target;
  spriteStore.x = imageContainerRect.x();
  spriteStore.y = imageContainerRect.y();
}