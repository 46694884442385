<template>
<div class="w-full bg-white h-7 flex items-center px-3">
  <div
    class="
      font-bold neutral-800
      flex items-center
      select-none
    "
  >
    <input
      type="checkbox"
      name="showEditableObjects-checkbox"
      id="showEditableObjects-checkbox"
      v-model="isShowEditableObjects"
      class="mr-2"
    />
    <label for="showEditableObjects-checkbox">
      Show Editable Objects
    </label>
  </div>
</div>
</template>

<script lang="ts" setup>
import useSlide from '@/views/MediaEditorView/SlideEditor/composables/slide';
const { isShowEditableObjects } = useSlide();

</script>

<style lang="scss" scoped>

</style>