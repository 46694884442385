<!-- 
  TODO: think about resolving redundancies if needed between this & @\components\SlideEditor\TopHigherHorizontalBar — N36G3WBi
    More potential redundancies that can be solved: @\views\PlaylistEditorView\TopBar\TopBar.vue
-->
<template>
  <div class="top-bars">
    <div class="top-higher-horizontal-bar justify-between flex-wrap">
      <div class="flex flex-row items-center gap-2">
        <div class="component-title">
          Media Editor
        </div>
        <ReloadButton tooltipText="Reload Media Item" :isLoading="isLoading || isReloading" :reloadHandler="reloadMediaItem" />
      </div>
      <div class="flex items-center mb-2">
        <LabelCustomized label="Sharing" />
        <SelectField
          :options="sharingLevelOptions"
          v-model="data.sharingLevel"
        />
      </div>
    </div>
  </div>
</template>
  
<script lang="ts" setup>
import PageSection from '@/components/layouts/PageSection.vue';
import ReloadButton from '@/components/ReloadButton/ReloadButton.vue';
import SelectField from '@/components/form-fields/SelectField/SelectField.vue';
import SharingLevelsMapping from '@/data/mappings/SharingLevels.map'
import useMediaItem from '../../composables/mediaItem';
import useImageOrVideo from '../composables/imageOrVideo';
import LabelCustomized from '@/components/form-fields/LabelCustomized/LabelCustomized.vue';

const { isLoading, isReloading, reloadMediaItem } = useMediaItem();
const { data } = useImageOrVideo();

const sharingLevelOptions = [
  // TODO: think about a cleaner way to write this.
  {
    label: SharingLevelsMapping[1],
    value: 1
  },
  {
    label: SharingLevelsMapping[2],
    value: 2
  },
];


</script>
  
<style lang="scss" scoped>
.component-title {
  color: #25294A;
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.top-higher-horizontal-bar {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.top-lower-horizontal-bar {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}


.used-by-field,
.size-field {
  display: flex;
  align-items: center;

  .used-by-label,
  .size-label {
    color: #262626;
    font-weight: bold;
    cursor: default;
    margin-right: 1.2rem;
  }
}
</style>