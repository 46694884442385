<template>
<div class="floating-footer-buttons flex items-center justify-end">
  <slot></slot>
</div>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>
.floating-footer-buttons {
  position: fixed;
  bottom: 0;
  max-width: 1200px;
  width: 100vw;
  height: 6rem;
  z-index: 2;
  background-color: white;
  @include x-large-devices-down {
    padding: 0 3.1rem
  }
}
</style>

