<template>
  <Dialog :expanderClass="'d-inline'" ref="playlist">
    <template #expander="{ openModal }">
      <ButtonCustomized label="Set Default Playlist" @click="openModal" v-if="showBtn" /> 
      <a class= "add-link cursor-pointer" @click="openModal"  v-if="!showBtn"> Change </a>

    </template>
    <template #dialog.title>
      Set Default Playlist
        </template>
    <template #dialog.content="{ closeModal, closeModalAfter }">

      <!-- TODO (cleaner code): think about removing redundancy between "DeleteItemConfirmationForm" & other forms (e.g. CreateSlideForm) [DSP-N26Dui5A] -->
      <div>
        <SimpleEntityListingPage :entityItemsNamePlural="''" entityItemName="Playlist"
          areaAccessName="Playlists"
          :ItemsLister="PlaylistsLister" :single-select="true" />
        <div class="form-buttons">
          <ButtonCustomized label="Add" @click="onSave" />
        </div>
      </div>

    </template>
  </Dialog>
</template>
    
<script lang="ts" setup>
import Dialog from '../../../components/Dialog/Dialog.vue';
import ButtonCustomized from "../../../components/form-fields/ButtonCustomized.vue";
import SimpleEntityListingPage from '@/components/ListingPages/SimpleEntityListingPage/SimpleEntityListingPage.vue';
import PlaylistsLister from '../../PlaylistsListView/PlaylistsLister/PlaylistsLister.vue';
import useListSelection from '@/components/ListingPages/ItemsLister/ItemsList/composables/listSelection';
import { ref } from 'vue';
const playlist = ref()
const emit = defineEmits(['cancel', 'save', 'exit', 'fail'])
defineProps({
  'showBtn':{
    type: Boolean,
    default: true
  }

})
const {
  selectedItemsIds,
  selectedItems,
  clearItemsSelection
} = useListSelection({
  clearSelectionTriggerElement: document.body
});

function onExit() {
  emit('exit')
}
async function onSave() {
  console.log('ss');
  console.log(selectedItems)
  emit('save', selectedItems);
  clearItemsSelection () ;
  playlist.value.closeModal()
}

</script>
    
<style lang="scss" scoped>
.button {
  cursor: pointer;
}

.delete-item-form {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1rem;
}
.add-link {
    font-weight: bold;
    color: #252949;
    display: inline-block;
  }
  .add-link:hover{
    text-decoration: underline;
  }
</style>