<template>
<div class="item-skeletons-container">
  <div class="name-and-other-fields-skeletons-container">
    <div class="name-skeleton" ref="nameSkeletonContainerRef">
      <loading-skeleton :containerRef="nameSkeletonContainerRef" />
    </div>
    <div
      class="text-skeleton"
      v-for="textSkeletonContainerRef in textSkeletonsContainersRefs"
      :ref="textSkeletonContainerRef"
    >
      <loading-skeleton :containerRef="textSkeletonContainerRef" />
    </div>
  </div>
</div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
const nameSkeletonContainerRef = ref<HTMLDivElement>();
const textSkeletonsCount = 3;
const textSkeletonsContainersRefs = new Array(textSkeletonsCount).fill(ref<HTMLDivElement>());
</script>

<style lang="scss" scoped>

.item-skeletons-container {
  display: flex;
  flex-direction: row;
}

.name-and-other-fields-skeletons-container {
  display: flex;
  flex-direction: row;
  gap: 1rem
}

.image-skeleton {
  height: 6em;
  width: 6em;
  margin: 0.375rem;
}

.name-skeleton {
  height: 2.5em;
  width: 14em;
  margin: 0.375rem;
}

.text-skeleton {
  height: 2.5em;
  width: 8em;
  margin: 0.375rem;
}
</style>