<!-- 
  Notes
    Styling inspired from https://medium.com/@akumaisaacakuma/how-to-create-custom-context-menu-in-vue-970e67059532
 -->

<template>
  <div
    class="ctx-menu-wrapper"
    ref="ctxMenuWrapperRef"
  >
    <slot name="activator" v-bind="{openCtxMenu}"></slot>
    <slot v-if="!$slots.activator" v-bind="{openCtxMenu}"></slot>
    <div
      class="ctx-menu"
      ref="ctxMenuRef"
      :style="style"
      :hidden="hidden"
    >
        <!-- Use template tag to loop through the options -->
        <template v-if="options" v-for="(item, index) in options">
          <!--
            Then check opton type default is undeifined then if the type is not divider
          -->
          <!-- Make sure to create a unique ref is will be needed later -->
          <div
            v-if="item?.type !== 'divider'"
            :key="index"
            @click="handleOptionClick(item.handler)"
          >
            <span v-text="item.title" class="ctx-menu-option"></span>
          </div>
          <div v-else :key="index+'-divider'" class="ctx-menu-divider"><span></span></div>
        </template>
        <template v-else>
          <slot name="options" v-bind="{closeCtxMenu}"></slot>
        </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive, ref, watch } from "vue";

defineProps<{
  options?: {title: string, handler: ()=>void, type?: string}[]
}>()


const ctxMenuRef = ref();
const ctxMenuWrapperRef = ref();

const hidden = ref(true);

const style = reactive({
  left: "",
  top: ""
})

function handleOptionClick(handler) {
  if(handler) handler();
  closeCtxMenu();
}

function openCtxMenu(event: any) {
  updateCtxMenuPosition(event);
  hidden.value = false;
}


function closeCtxMenu() {
  hidden.value = true;
}

function updateCtxMenuPosition(event: any) {
  let bounds = ctxMenuRef.value.getBoundingClientRect();
  let x = event.clientX - bounds.left;
  let y = event.clientY - bounds.top;
  style.left = x + "px";
  style.top = y + "px";
}
// Watch for click outside
const clickOutsideListener = (event: Event) => {
  const isClickedOnContextMenu = !ctxMenuRef.value || ctxMenuRef.value.contains(event.target)
  if (isClickedOnContextMenu) {
    return;
  }
  closeCtxMenu();
};

watch(hidden, () => {
  if (hidden.value === false) {
    document.addEventListener('mousedown', clickOutsideListener);
  }

  if (hidden.value === true) {
    document.removeEventListener('mousedown', clickOutsideListener);
  }
})
</script>

<style lang="scss" scoped>

.ctx-menu-wrapper {
  position: relative;
}

.ctx-menu {
  min-width: 150px;
  height: fit-content;
  padding: 10px 0;
  position: fixed;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  z-index: 1111111;
}
.ctx-menu-option, :deep(.ctx-menu-option) {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  height: 35px;
  padding: 0 10px;
  cursor: pointer;
  color: #606266;
  text-decoration: none;
  &:hover {
    background-color: #f5f7fa;
  }
}

.ctx-menu-divider, :deep(.ctx-menu-divider) {
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > span {
    width: 50px;
    height: 1px;
    background-color: #dcdfe6;
  }
}
</style>