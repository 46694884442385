<template>
  <div class="top-bar">
    <h1 class="title">
      <slot name="title"></slot>
    </h1>
    <div class="main-buttons">
      <slot name="main-buttons"></slot>
    </div>
  </div>
</template>
<script lang="ts" setup>
</script>
<style lang="scss" scoped>
.top-bar {
  display: flex;
  align-items: center;
  gap: 1em;
  flex-wrap: wrap;
}

h1.title {
  font-size: 3em;
  font-weight: bold;
  text-transform: uppercase;

  @include theme-old {
    color: #252949;
  }

  @include theme-new {
    color: #252949;
  }

  letter-spacing: 2px;
}
.main-buttons {
  display: flex;
  gap: 0.75rem;
}
</style>
